import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  footer: {
    color     : colors.WHITE,
    cursor    : 'default',
    marginLeft: theme.spacing(2),
  },
  version: {
    color : colors.BLACK,
  }
}));