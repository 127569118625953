import React from 'react';
import { AuthProvider } from 'react-oidc-context';
import oidcConfig from './OidcConfig';
import AuthContextProvider from './context/authContext';
import { USER } from './utility/constants';

const ProtectedResources = ({ children }) => {
  let updatedOidcConfig = {
    ...oidcConfig
  };

  if (localStorage.getItem(USER) === null) {
    updatedOidcConfig = {
      ...oidcConfig,
      redirect_uri: window.location.origin,
    };
  }
  
  return (
    <AuthProvider {...updatedOidcConfig} >
      <AuthContextProvider>
        {
          children
        }
      </AuthContextProvider>
    </AuthProvider>
  )
}

export default ProtectedResources;