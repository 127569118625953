import { Box, Dialog, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { USERS } from '../../utility/constants';
import images from "../../utility/images";
import ActionButtons from './action-buttons';
import DeleteModalSkeleton from './delete-modal-skeleton';
import useStyles from './styles';

const DeleteModal = (props) => {
  const { initialValues, onClose, module, forDeleteName, singleDelete, multipleDelete, type, open, isLoading } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const moduleName = t(module.slice(0, -1));

  const removeWithAssociated = {
    item          : forDeleteName,
    module        : moduleName,
  }

  const isUserModule = module === USERS;

  const getItem = () => {
    return isUserModule ? t('user') : t('tenant');
  }

  return (
    <>
      <Dialog
        id="deleteModal"
        data-testid='deleteModal'
        modal="false"
        open={open}
        onClose={onClose}
        aria-labelledby="row-delete-dialog"
        aria-describedby="row-delete-dialog-table"
        className={classes.dialog}
      >
        {
          isLoading ?
            <DeleteModalSkeleton/>
            :
            <>
              <Paper id="deleteModalBodyContainer" className={classes.deleteModal}>
                <Box className={classes.dialogImage}>
                  <img src={images.DELETE_MODAL} alt='delete-dialog-img' className={classes.image}/>
                </Box>
                <Box className={classes.dialogTitle}>
                  <Typography component={'span'} className={classes.title}>
                      <Trans i18nKey={'delete-modal.warningMessage'} values={{ item: getItem() }} />
                  </Typography>
                </Box>
                <Box className={classes.dialogContent}>
                  <Grid container spacing={2} className={`${classes.form} ${classes.space}`}>
                    <Grid item md={12}>
                      <Typography component={'span'}>
                        <Trans 
                          i18nKey={isUserModule ? 'delete-modal.removeItemMessage' : 'tenant-page.deleteMessageInfo'}
                          values={removeWithAssociated}
                        />
                        {
                          !isUserModule &&  
                            <Trans
                              i18nKey={'tenant-page.deleteMessageConfirmation'}
                              values={removeWithAssociated}
                            />  
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
              <ActionButtons
                type={type}
                onClose={onClose}
                singleDelete={singleDelete} 
                multipleDelete={multipleDelete}
                initialValues={initialValues}
              />
            </>
        }
      </Dialog>
    </>
  );
}

export default DeleteModal;