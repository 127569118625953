import { DEPLOYMENT_ERROR, DEPLOYMENT_ERROR_CODE, DEPLOYMENT_STATUS } from "./constants";

export const getDeploymentError = (deploymentError) => {
  switch (deploymentError) {
    case DEPLOYMENT_ERROR_CODE.DATABASE_EXISTS:
    case DEPLOYMENT_ERROR_CODE.DATABASE_ERROR:
      return {
        error : DEPLOYMENT_ERROR.DATABASE_ERROR,
        progressNumber : 2
      }
    case DEPLOYMENT_ERROR_CODE.INFRASTRUCTURE_EXISTS: 
    case DEPLOYMENT_ERROR_CODE.INFRASTRUCTURE_ERROR: 
      return {      
        error : DEPLOYMENT_ERROR.INFRASTRUCTURE_ERROR,
      	progressNumber : 3
  	}
    case DEPLOYMENT_ERROR_CODE.ADMIN_INVITED_ERROR: 
      return {
        error : DEPLOYMENT_ERROR.ADMIN_INVITED_ERROR,
        progressNumber : 4,
      }
    default:
      return {
        error : DEPLOYMENT_ERROR.CREATE_ERROR,
        progressNumber : 1,
      }
  }
}

export const getDeploymentStatusProgress = (deploymentStatus) => {
  const progressNumber = Object.values(DEPLOYMENT_STATUS).findIndex(item => item === deploymentStatus) + 1
  return progressNumber ? progressNumber : 1;
}

export const getDeploymentPayload = (data, tenant) => {
  if (data.error && data.error !== '') {
    return {
      ...tenant,
      deploymentDescription: data.description,
      isError              : true,
      deploymentError      : data.error
    }
  } else {
    return {
      ...tenant,
      deploymentStatus: data.status
    }
  }
}
