import { DELETE, DEPLOYMENT_STATUS, GET, PATCH, POST } from "../utility/constants";
import api from "./api";
import { request } from "./requests";

const billingEndpoint = '/billing';

export const deleteTenantApi = async (tenantId, language) => {
  return request({
    url: `${api.TENANTS}/${tenantId}`,
    method: DELETE,
    headers : {
      "Content-Language" : language,
    }
  });
}

export const assignTenantToUser = async (userIds, tenantId) => {
  await request({
    url   : api.TENANT_USER_ASSIGN,
    method: POST,
    data  : {
      userIds : userIds,
      tenantId: tenantId
    }
  });
}

export const getBillingData = async(tenantId, locale) => {
  return request({
    url   : `${api.TENANTS}/${tenantId}${billingEndpoint}`,
    method: GET,
    headers  : {
      'Content-Language': locale
    }
  });
}

export const createTenant = async (id, deploymentStatus, boolStatus, values) => {
  return await request({
    url    : id ? `${api.TENANTS}/${id}` : api.TENANTS,
    method : id ? PATCH : POST,
    data    : {
      ...values,
      deploymentStatus : id ? deploymentStatus : DEPLOYMENT_STATUS.TENANT_CREATED,
      status           : id ? boolStatus : true
    }
  });
}