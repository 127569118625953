import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  deleteModal: {
    padding   : theme.spacing(2),
    paddingTop: theme.spacing(5),
  },
  dialogImage: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(2),
  },
  image: {
    height      : 120,
    width       : 163,
  },
  dialogTitle: {
    fontWeight: 'bold',
    textAlign : 'center',
  },
  dialogActions: {
    alignItems     : 'center',
    backgroundColor: '#F1F1F1',
    direction      : 'row',
    display        : 'flex',
    height         : theme.spacing(8),
    justifyContent : 'space-around',
    padding        : theme.spacing(2),
    width           : 600
  }
}));