const colors = {
  // THEME
  PRIMARY               : '#006AB3CC',
  PRIMARY_LIGHT         : '#A2DFFF',
  PRIMARY_DARK          : '#05619E',
  SECONDARY             : '#707173',
  SECONDARY_LIGHT       : '#70717319',
  WHITE                 : '#FFFFFF',
  GRAY                  : '#7071734D',
  GRAY_LIGHT            : '#DDE7EF',
  GRAY_DARK             : '#7071737F',
  SECONDARY_GRAY        : '#FAFAFA',
  SECONDARY_GRAY_LIGHT  : '#F5F5F5',
  BLACK                 : '#000',
  TRANSPARENT           : 'transparent',
  ACCENT                : '#A2DFFF',
  PALE_BLUE             : '#006AB319',
  LIGHT_BLUE            : '#C5E7F4',

  // TEXT
  PRIMARY_TEXT         : '#000000',
  SECONDARY_TEXT       : '#707173',
  SECONDARY_GRAY_TEXT  : '#707070',

  DISABLED_TEXT   : 'gray',

  //SCHEDULE TABLE
  TIME_TABLE      : '#2FB56A54',

  // STATUS
  ERROR           : '#DA5F54',
  WARNING         : 'orange',
  SUCCESS         : '#2FB56A',
  SUCCESS_DARK    : '#256625',
  NEUTRAL         : 'gray',
  ACTIVE          : '#2fb56a',

  CHART_LINE_COLOR  : '#f86c6c',
  CHART_BLUE        : '#004775',
  CHART_GREEN       : '#1E4B48',
  CHART_YELLOW      : '#F7B93E',
  CHART_BLUE_LIGHT  : '#4CD3B3',
  CHART_RED_LIGHT   : '#E23759',
  CHART_ORANGE      : '#F5523F',

  CHART_ORANGE_LIGHT: '#F0631C',
  CHART_GREEN_LIGHT : '#23A41D',
  CHART_INDIGO      : '#1D23A4',
  CHART_OLIVE       : '#1DA49E',

  CHART_BORDER_BLUE : '#006AB3',
  CHART_BG_BLUE     : '#006AB31A',

  // CHIPS
  CHIP_BACKGROUND_BLUE: '#E5F0F7',
  CHIP_TEXT_BLUE      : '#006AB3',

}

export default colors