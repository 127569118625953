import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    marginTop      : theme.spacing(-1),
  },
  paper: {
    marginTop      : theme.spacing(1),
  },
  form: {
    padding        : theme.spacing(1),
    paddingLeft    : theme.spacing(2),
    paddingRight   : theme.spacing(2),

    '& .MuiSkeleton-root': {
      transform    : 'none',
      marginBottom : theme.spacing(1)
    },
  },
  action: {
    backgroundColor: theme.palette.grey[100],
    padding        : theme.spacing(2),
    textAlign      : 'right',

    '& .MuiButton-root:first-of-type': {
      marginRight  : theme.spacing(2)
    }
  },
  buttonContainer: {
    backgroundColor: colors.SECONDARY_GRAY_LIGHT,
    display        : 'flex',
    justifyContent : 'flex-end',
    width          : '100%'
  },
  button: {
    height         : '40px',
    marginLeft     : theme.spacing(1),
    width          : '120px'
  },
}));