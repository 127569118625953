import axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/authContext';
import { getAllUserRoles, getAllUsers, getUserCount, getUserRoles } from '../../service/user-api';
import { API_REQUEST_ERROR_MESSAGE, SYSTEM_ROLES, TENANT_FIELD, TOASTER_SEVERITY, USER_FIELD } from '../../utility/constants';
import Select from '../select';

const SelectItems = (props) => {
  const { onChange, name, showToaster, selectedItems, disabled, single, isValid, helperText, value, setHasSelected, required, module, id, isSearchEnabled = true, withCheckbox, chipColor } = props;
  const { t }   = useTranslation();
  
  const { state } = useContext(AuthContext);
  const { user }  = state;
  const { roles } = user;

  const [isLoadingSelect, setIsLoadingSelect] = useState(false);
  const [error, setError]                     = useState(false);
  const [items, setItems]                     = useState([]);
  const [hasMore, setHasMore]                 = useState(false);
  const [query, setQuery]                     = useState('');
  const [pageNumber, setPageNumber]           = useState(0);
  const [totalElements, setTotalElements]     = useState(0);

  const searchHandler = useCallback((query, pageNumber) => {
    setQuery(query);
    setPageNumber(pageNumber);
  }, []);

  const getLabel = () => {
    switch (name) {
      case USER_FIELD.USER_ROLE:
        return 'select-component.userRole';
      case TENANT_FIELD.ACCOUNT_MANAGER:
        return 'select-component.accountManager';
      default:
        break;
    }
  }

  const getFormattedItem = useCallback(async (item) => {
    const { firstName, lastName, email, id, attributes } = item;

    const role = await getUserRoles(id);

    return {
      name        : `${firstName} ${lastName}`,
      id          : id,
      email       : email,
      systemRoles : role,
      company     : attributes?.company ? attributes.company[0] : ''
    }
  }, []);

  const getItems = useCallback(async () => {
    if (name === USER_FIELD.USER_ROLE) {
      let response = await getAllUserRoles();

      if (roles.name !== SYSTEM_ROLES.SYSTEM_ADMIN) {
        response = response.filter(role => role.name !== SYSTEM_ROLES.SYSTEM_ADMIN);
      }

      setItems(response);
    } else {
      const size = 10;
      const params = {
        search: query,
        first: pageNumber * size,
        max: size
      }

      return await getAllUsers(params);
    }
  }, [name, query, pageNumber, roles.name]);

  const arrangeUseSearchItems = useCallback((newItems) => {
    setItems(prevItems => (pageNumber === 0) ? newItems : prevItems.concat(newItems));
  }, [pageNumber]);

  const fetchData = useCallback(async () => {
    setError(false);
    setIsLoadingSelect(true);
    try {
      const response = await getItems();
      if (name === TENANT_FIELD.ACCOUNT_MANAGER) {
        let newItems = await Promise.all(response.data.map(async (item) => await getFormattedItem(item)));

        if (roles.name === SYSTEM_ROLES.ACCOUNT_MANAGER) {
          newItems = newItems.filter(item => item.name !== SYSTEM_ROLES.SYSTEM_ADMIN);
        }

        arrangeUseSearchItems(newItems);
        const usersCount = await getUserCount();
        setTotalElements(newItems.length < 10 ? newItems.length: usersCount.data);
      }
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }
      setError(true);
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), TOASTER_SEVERITY.ERROR);
    } finally {
      setIsLoadingSelect(false);
    }
  }, [t, name, roles, showToaster, getItems, getFormattedItem, arrangeUseSearchItems]);

  const getDefaultValue = () => {
    if (name === USER_FIELD.USER_ROLE) {
      return selectedItems.id ? [selectedItems] : [];
    } else {
      return selectedItems;
    }
  }

  useEffect(() => {
    fetchData();

    if (setHasSelected) {
      setHasSelected(false);
    }
  }, [fetchData, setHasSelected]);

  useEffect(() => {
    setHasMore(totalElements > 0 && totalElements > items.length);
  }, [totalElements, items]);

  return (
    <Select
      id={id}
      name={name}
      chipColor={chipColor}
      single={single}
      disabled={disabled}
      search={searchHandler}
      selectFrom={name}
      label={getLabel()}
      onChange={onChange}
      defaultValue={getDefaultValue()}
      isValid={isValid}
      helperText={helperText}
      value={value}
      required={required}
      module={module}
      isSearchEnabled={isSearchEnabled}
      withCheckbox={withCheckbox}
      isLoadingSelect={isLoadingSelect}
      items={items}
      error={error}
      hasMore={hasMore}
      pageNumber={pageNumber}
      query={query}
    />
  )
}

export default SelectItems;