import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

const useStyles = makeStyles(theme => ({
  selectForm: {
    width: '100%',
  },
  paper: {
    paddingTop: theme.spacing(1),
  },
  popper: {
    width     : 'calc(100% - 70vw)',
    wordBreak : 'break-word',
    zIndex    : 1300,
  },
  singleOption: {
    alignContent  : 'flex-start',
    alignItems    : 'flex-start',
    display       : 'flex',
    flexDirection : 'column',
    flexWrap      : 'wrap',
    justifyContent: 'flex-start',
    minWidth      : '95%',
    '& .MuiTypography-root.MuiTypography-body2': {
      color: "black"
    },
    '& .MuiInputBase-input': {
      cursor : 'pointer'
    }
  },
  inputChips: {
    alignContent  : 'center',
    alignItems    : 'center',
    display       : 'flex',
    flexWrap      : 'wrap',
    justifyContent: 'flex-start',
    marginBottom  : theme.spacing(0.5),
    marginTop     : theme.spacing(1),
    minWidth      : '95%',
  },
  inputTag: {
    alignItems   : 'center',
    border       : `1px solid`,
    borderColor  : theme.palette.primary.dark,
    borderRadius : theme.spacing(0.15),
    display      : 'flex',
    margin       : theme.spacing(0.15),
    paddingBottom: theme.spacing(0.2),
    paddingTop   : theme.spacing(0.5),
    width        : '100%',
  },
  chips: {
    color        : 'white',
    display      : 'flex',
    flexDirection: 'row',
    height       : '100%',
    margin       : theme.spacing(0.4),
    '& .MuiChip-label': {
      whiteSpace : 'normal',
      wordBreak  : 'break-word'
    }
  },
  optionBox: {
    alignItems: 'center',
    display   : 'flex',
    cursor    : 'pointer',
    height       : theme.spacing(5.25),
    paddingLeft  : theme.spacing(2),
    paddingRight : theme.spacing(2),
  },
  optionBoxSingle: {
    alignItems    : 'center',
    display       : 'flex',
    cursor        : 'pointer',
    height        : 'fit-content',
    paddingBottom : theme.spacing(1),
    paddingLeft   : theme.spacing(3),
    paddingRight  : theme.spacing(2),
    paddingTop    : theme.spacing(1),
    '&:hover': {
      backgroundColor: colors.SECONDARY_GRAY_LIGHT
    }
  },
  options: {
    display      : 'flex',
    flexDirection: 'column',
    fontSize     : '16px',
    height       : '25vh',
    marginBottom : theme.spacing(2),
    marginTop    : theme.spacing(1),
    overflowX    : 'hidden',
    overflowY    : 'auto'
  },
  optionTitle: {
    fontWeight: 600,
  },
  optionSub : {
    fontSize: '75%',
  },
  loadField : {
    alignContent  : 'center',
    alignItems    : 'center',
    display       : 'flex',
    flexDirection : 'column',
    justifyContent: 'space-evenly',
    paddingLeft   : theme.spacing(2),
    paddingRight  : theme.spacing(2),
  },
  searchBox: {
    width: '95%',
  },
  loading: {
    marginLeft: theme.spacing(-0.5),
    marginTop : theme.spacing(1.5),
  },
  visuallyHidden: {
    border  : 0,
    clip    : 'rect(0 0 0 0)',
    height  : 1,
    margin  : -1,
    overflow: 'hidden',
    padding : 0,
    position: 'absolute',
    top     : 20,
    width   : 1,
  },
  invisible: {
    display      : 'none',
    marginBottom : theme.spacing(1),
  },
  displayFlex: {
    display : 'flex'
  },
  textFieldHover: {
    '& .MuiInputBase-input': {
      cursor : 'pointer'
    },
    '& .MuiOutlinedInput-root': {
      cursor : 'pointer'
    }
  }
}))

export default useStyles;