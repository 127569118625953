import { AppBar as NavBar, Avatar, Grid, IconButton, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react'
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../context/authContext';
import images from '../../../utility/images';
import useStyles from './styles';

const AppBar = (props) => {
  const { signOut, myAccount } = props;
  const { state }   = useContext(AuthContext);
  const { user }    = state;

  const history = useHistory();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickLogo = () => {
    history.push(`/dashboard`);
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  }

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleIconName = () => {
    return user?.firstName?.slice(0, 1).toUpperCase();
  }
    
  return (
    <NavBar className={classes.appBar}>
      <Toolbar>
        <Grid container className={classes.toolbar} spacing={3}>
          
          <img src={images.SESAMSEC_LOGO} id="sesamsec" className={classes.logo} alt="elatec-logo" onClick={handleClickLogo}></img>
          
          <Grid item className={classes.toolbarRight} lg={4}>
            <Typography id="mainLayoutUserName" className={classes.toolbarRightGreeting} component="h1" variant="body2" noWrap>
              <Trans i18nKey={'main-layout-component.greeting'} values={{ name : user?.firstName }}/>
            </Typography>
            <IconButton id="mainLayoutUserAvatar" className={classes.avatarButton} color="secondary" aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpenMenu}>
              <Avatar className={classes.avatar}>{handleIconName()}</Avatar>
            </IconButton>
            <Menu
              id="mainLayoutMenu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              onClick={handleCloseMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              getContentAnchorEl={null}
              >
              {/* <MenuItem id="mainLayoutMyProfile"><Trans i18nKey={'main-layout.profile'} /></MenuItem> */}
              <MenuItem id="mainLayoutMyAccount" onClick={myAccount}><Trans i18nKey={'main-layout.myAccount'} /></MenuItem>
              <MenuItem id="mainLayoutLogOut" onClick={signOut}><Trans i18nKey={'main-layout.logOut'} /></MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
    </NavBar>
  )
}

export default AppBar;