import { Box, Button, Container, Grid, Paper, Switch, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router';
import IconWithHover from '../../components/icon-with-hover';
import SelectItems from '../../components/select-items';
import Title from '../../components/title';
import UnsavedModal from '../../components/unsaved-modal';
import { AuthContext } from '../../context/authContext';
import { INVITE_USER, assignUserSystemRoles, createUser, getUserById, inviteUser, removeUserSystemRoles, updateUser } from '../../service/user-api';
import { ACTION_CREATE, ACTION_VIEW, API_REQUEST_ERROR_MESSAGE, PAGES, PATCH, PROMPT_ACTION, SYSTEM_ROLES, TOASTER_SEVERITY, USER_ACTION, USER_FIELD } from '../../utility/constants';
import images from '../../utility/images';
import { handleActionButtonPermission, handlePermissions, handleUpdatePagePermission } from '../../utility/permissions';
import { userSchema, withCompanySchema } from '../../validation/schema';
import useStyles from './styles';
import UserSkeleton from './user-skeleton';
import UserStatusModal from './user-status-modal';

const Content = (props) => {
  const { id, handleSubmit, prevValues, initialValues, disabled, showLoading, handleSelectChange, handleCancel, setFirstName, setLastName, setEmail, setCompany, showToaster, isAccountManager, handleSwitchStatus, path } = props;
  const { t }       = useTranslation();
  const classes     = useStyles();
  const isFederated = disabled || initialValues.isUserFederated


  const getButtonLabel = () => {
    return id ? `${t('user-page.update')}` : `${t('user-page.create')}`;
  }
  
  const handleChange = (setter, formik) => {
    return (setter, formik);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={isAccountManager ? withCompanySchema : userSchema}
      onSubmit={handleSubmit}
    >
      {
        formik => (
          <Form>
            {showLoading(formik.isSubmitting)}
            <Paper className={classes.paper} elevation={3}>
              {
                path.includes(ACTION_VIEW) && initialValues.isUserFederated && prevValues.userRole?.id ? 
                  <Box className={classes.switchBox}>
                    <Switch
                      key={'row-switch-' + id}
                      data-testid='userSwitch'
                      checked={initialValues.status}
                      onClick={(event) => handleSwitchStatus(event)}
                      className={classes.switch}
                    />
                    <Typography className={initialValues.status ? classes.activeSwitch : classes.deactivatedSwitch}>
                      {
                        initialValues.status ? 'Enabled' : 'Disabled'
                      }
                    </Typography>
                  </Box>
                : <></>
              }
              <Grid container spacing={2} className={classes.form}>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    inputProps={{
                      readOnly: disabled || isFederated
                    }}
                    disabled={disabled || isFederated}
                    id="firstName"
                    label={`${t('user-page.firstName')}*`}
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    multiline
                    value={formik.values.firstName}
                    onChange={e => handleChange(setFirstName(e.target.value), formik.handleChange(e))}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && t(formik.errors.firstName)}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    inputProps={{
                      readOnly: disabled || isFederated
                    }}
                    disabled={disabled || isFederated}
                    id="lastName"
                    label={`${t('user-page.lastName')}*`}
                    name="lastName"
                    variant="outlined"
                    fullWidth
                    multiline
                    value={formik.values.lastName}
                    onChange={e => handleChange(setLastName(e.target.value), formik.handleChange(e))}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && t(formik.errors.lastName)}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    inputProps={{
                      readOnly: disabled || isFederated
                    }}
                    disabled={disabled || isFederated}
                    id="email"
                    label={`${t('user-page.emailAddress')}*`}
                    name="email"
                    variant="outlined"
                    fullWidth
                    multiline
                    value={formik.values.email}
                    onChange={e => handleChange(setEmail(e.target.value), formik.handleChange(e))}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && t(formik.errors.email)}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} data-testid="userRole">
                  <SelectItems
                    disabled={disabled}
                    single={true}
                    id="usersCreateModalUserSelectRole" 
                    name={USER_FIELD.USER_ROLE}
                    onChange={handleSelectChange}
                    selectedItems={formik.values.userRole}
                    showToaster={showToaster}
                    helperText={formik.touched.userRole && t(formik.errors.userRole)}
                    isValid={formik.touched.userRole && Boolean(formik.errors.userRole)}
                    required={true}
                    isSearchEnabled={false}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} className={clsx(!isAccountManager && 'hidden')}>
                  <TextField
                    inputProps={{
                      readOnly: disabled
                    }}
                    disabled={disabled}
                    id="company"
                    label={`${t('user-page.company')}*`}
                    name="company"
                    variant="outlined"
                    fullWidth
                    multiline
                    value={formik.values.company}
                    onChange={e => handleChange(setCompany(e.target.value), formik.handleChange(e))}
                    error={formik.touched.company && Boolean(formik.errors.company)}
                    helperText={formik.touched.company && t(formik.errors.company)}
                  />
                </Grid> 
              </Grid>
              <Grid className={clsx(disabled ? 'hidden' : classes.action)}>
                <Grid item xs={12}>
                  <Button
                    className={classes.button}
                    onClick={handleCancel}
                    variant="outlined"
                    color="primary"
                    aria-label="user-page.cancel"
                  >
                    {t('user-page.cancel')}
                  </Button>
                  <Button
                    className={classes.button}
                    type="submit"
                    variant="contained"
                    color="primary"
                    aria-label="submit-button"
                  >
                    {getButtonLabel()}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        )
      }
    </Formik>
  )
}

const User = (props) => {
  const { showToaster, match, showLoading } = props;
  const { t }                               = useTranslation();
  const { id }                              = match.params;
  const path                                = match.path;
  const classes                             = useStyles();
  const history                             = useHistory();

  const { state } = useContext(AuthContext);
  const { user } = state;

  const [firstName, setFirstName]       = useState('');
  const [lastName, setLastName]         = useState('');
  const [email, setEmail]               = useState('');
  const [userRole, setUserRole]         = useState([]);
  const [previousRole, setPreviousRole] = useState('');
  const [company, setCompany]           = useState('');
  const [showModal, setShowModal]       = useState(false);
  const [statusShowModal, setStatusShowModal] = useState(false);
  const [withChanges, setWithChanges]   = useState(false);
  const [toRedirect, setToRedirect]     = useState('');
  const [isLoading, setIsLoading]       = useState(false);
  const [prevValues, setPrevValues]     = useState([]);
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [isUserFederated, setIsUserFederated] = useState(false);
  const [status, setStatus] = useState(false);
  const isAccountManager = [SYSTEM_ROLES.ACCOUNT_MANAGER, SYSTEM_ROLES.EXTERNAL_ACCOUNT_MANAGER].includes(userRole?.name);
  
  const initialValues = useMemo(() => {
    return {
      firstName       : firstName,
      lastName        : lastName,
      email           : email,
      userRole        : userRole,
      company         : company,
      isUserFederated : isUserFederated,
      status          : status,
    }
  }, [firstName, lastName, email, userRole, company, isUserFederated, status]);

  const hasUpdatePermission = handlePermissions(PAGES.USERS, PATCH, user.roles);

  const getUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getUserById(id);
      
      const { email, firstName, lastName, role, company, status, isUserFederated } = response;
      
      const prevValueData = {
        firstName       : firstName,
        lastName        : lastName,
        email           : email,
        company         : company,
        userRole        : role,
        isUserFederated : isUserFederated,
        status          : status
      }

      const actionButtonPermission = handleActionButtonPermission(PAGES.USERS, user.roles, role, hasUpdatePermission);
      
      setShowUpdateButton(actionButtonPermission);

      handleUpdatePagePermission(actionButtonPermission, user.roles, path);
      
      setPreviousRole(role)
      setUserRole(role);
      setPrevValues(prevValueData);
      setFirstName(firstName);
      setLastName(lastName);
      setEmail(email);
      setCompany(company);
      setIsUserFederated(isUserFederated);
      setStatus(status);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), TOASTER_SEVERITY.ERROR);
    } finally {
      setIsLoading(false);
    }
  }, [hasUpdatePermission, id, path, t, user.roles, showToaster]);

  useEffect(() => {
    setPrevValues({
      firstName       : '',
      lastName        : '',
      email           : '',
      company         : '',
      isUserFederated : false,
      status          : false,
    });
  }, []);

  useEffect(() => {
    if (id) {
      getUser();
    }
  }, [getUser, id])

  const getToasterMessage = (name) => {
    return id ? t('user-page.hasBeenUpdated', { name : name }) : t('user-page.hasBeenCreated', { name : name });
  }

  const getSchema = () => {
    return isAccountManager ? withCompanySchema : userSchema;
  }

  //Use schema transform to trim white spaces
  const castValues = (values) => {
    return getSchema().cast(values);
  }

  const handleSubmit = async (values, formik) => {
    const { setSubmitting, setErrors } = formik;

    if (!withChanges) {
      setSubmitting(false);
      setWithChanges(false);
      history.push('/users');
      return;
    }

    const newValues = castValues(values);

    try {
      handleUpdatePagePermission(showUpdateButton, user.roles, path);
 
      if (!id) {
        await createUser(newValues);
      } else {
        const userObject = {
          email     : values.email,
          firstName : values.firstName,
          lastName  : values.lastName,
          enabled   : prevValues.userRole?.id ? values.status : true,
          username  : values.email,
          attributes: {
            company: values.userRole.name === SYSTEM_ROLES.SYSTEM_ADMIN ? '' : values.company
          }
        }

        await updateUser(id, userObject);

        const hasRoleChanges = hasChanges(initialValues.userRole, prevValues.userRole);
        if (hasRoleChanges) {
          await assignUserSystemRoles(id, initialValues.userRole);
          // if user has a previous role, remove role
          // if none, invite user
          if (prevValues.userRole?.id) {
            await removeUserSystemRoles(id, prevValues.userRole);
          } else {
            await inviteUser(id, [INVITE_USER]);
          }
        }
      }
      
      setWithChanges(false);
      setSubmitting(false);
      history.push('/users');
      showToaster(t('success'), getToasterMessage(`${lastName}, ${firstName}`), TOASTER_SEVERITY.SUCCESS);
    } catch (error) {
      if (error.response.status === 409) {
        setErrors({
          email: t('user-page.emailAlreadyExists')
        });
      } else {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), TOASTER_SEVERITY.ERROR);
        setSubmitting(false);
      }
    }
  }

  const handleCancel = () => {
    history.push('/users');
  }

  const handleOpenStatusModal = () => {
    setStatusShowModal(true);
  }

  const handleCloseStatusModal = () => {
    setStatusShowModal(false);
  }

  const getStatusMessage = (newStatus, name) => {
    const toasterMessage = newStatus ? 'user-status-modal.enabledMessage' : 'user-status-modal.disabledMessage';
    return t(toasterMessage, { item : name });
  }

  const handleSubmitResponse = async () => {
    const newStatus = !status;
    const action = status ? USER_ACTION.ENABLE : USER_ACTION.DISABLE;
    
    let title       = t('tenant-page.error');
    let description = t(API_REQUEST_ERROR_MESSAGE);
    let severity    = TOASTER_SEVERITY.ERROR;

    setIsLoading(true);
    setStatusShowModal(false);
    
    try {
      const userObject = {
        email     : email,
        firstName : firstName,
        lastName  : lastName,
        enabled   : newStatus,
        username  : email,
        attributes: {
          company: userRole.name === SYSTEM_ROLES.SYSTEM_ADMIN ? '' : company
        }
      }

      await updateUser(id, userObject);
      
      setStatus(newStatus);
      setPrevValues({
        ...prevValues,
        status : newStatus
      });

      title       = t('user-page.success');
      description = getStatusMessage(newStatus, email);
      severity    = TOASTER_SEVERITY.SUCCESS;
      
      showToaster(title, description, severity);
    } catch (error) {
      if (error.response.status === 404) {
        description = t('user-page.actionError', { action });
        setWithChanges(false);
      }

      showToaster(title, description, severity);
    } finally { 
      setIsLoading(false);
    }
  }

  const handleSelectChange = (values) => {
    setUserRole(values.length ? values[0] : values);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleModalSubmit = () => {
    setWithChanges(false);
    history.push(toRedirect);
  }

  const handleModalCancel = () => {
    handleChanges();
    setShowModal(false);
  }

  const hasChanges = (current, previous) => {
    return current?.id !== previous?.id;
  }

  const handleChanges = useCallback(() => {
    if (prevValues?.firstName === initialValues.firstName
      && prevValues?.lastName === initialValues.lastName
      && prevValues?.email === initialValues.email
      && prevValues.company === initialValues.company
      && prevValues.status === initialValues.status
      && !hasChanges(userRole, previousRole)) {
      setWithChanges(false);
    } else {
      setWithChanges(true);
    }
  }, [initialValues, prevValues, previousRole, userRole]);

  useEffect(() => {
    handleChanges();
  }, [firstName, lastName, email, userRole, company, status, prevValues, handleChanges]);

  const getTitle = () => {
    if (path.includes(ACTION_VIEW)) {
      return <Trans i18nKey={'user-page.userView'} />;
    } else if (path.includes(ACTION_CREATE)) {
      return <Trans i18nKey={'user-page.userCreate'} />;
    }
    return <Trans i18nKey={'user-page.userUpdate'} />;
  }

  const handleUpdate = () => {
    history.push(`../update/${id}`)
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <UnsavedModal
        open={showModal}
        onClose={handleCloseModal}
        handleModalSubmit={handleModalSubmit}
        handleModalCancel={handleModalCancel}
      />
      <Prompt
        when={withChanges}
        message={(location, action) => {
          if (action === PROMPT_ACTION.PUSH) {
            setShowModal(true)
          }
          setWithChanges(false);
          setToRedirect(location.pathname);
          return location.pathname === '/' || location.pathname.startsWith('/users/update')
        }}
      />
      <UserStatusModal
        onClose={handleCloseStatusModal}
        open={statusShowModal}
        name={email}
        handleResponse={handleSubmitResponse}
        status={status}
      />
      <Title title={getTitle()} subtitle={`${lastName}, ${firstName}`} />
      <Box className={classes.details}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={'bold'} color="secondary">{t('user-page.details')}</Typography>
          </Grid>
            <Grid item xs={12} align="right" className={clsx(!(path.includes(ACTION_VIEW)) ? 'hidden' : classes.usersEditIcon)}>
              {
                showUpdateButton &&
                  <IconWithHover
                    id="userUpdateIcon"
                    handleOnClick={handleUpdate}
                    icon={images.EDIT_ICON}
                    hoverIcon={images.EDIT_ICON_HOVER}
                    altText="user-page.update"
                  />
              }
            </Grid>
        </Grid>
        {
          isLoading ?
            <UserSkeleton disabled={path.includes(ACTION_VIEW)} />
            :
            <Content
              id={id}
              path={path}
              disabled={path.includes(ACTION_VIEW)}
              initialValues={initialValues}
              prevValues={prevValues}
              handleCancel={handleCancel}
              handleSubmit={handleSubmit}
              handleSelectChange={handleSelectChange}
              showToaster={showToaster}
              setFirstName={setFirstName}
              setLastName={setLastName}
              setEmail={setEmail}
              setCompany={setCompany}
              showLoading={showLoading}
              isAccountManager={isAccountManager}
              handleSwitchStatus={handleOpenStatusModal}
            />
        }
      </Box>
    </Container>
  )
}

export default User;