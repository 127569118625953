import DateFnsUtils from '@date-io/date-fns';
import { createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';
import colors from './theme/colors';
import { ENVIRONMENT } from './utility/constants';
import moment from 'moment-timezone';

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.PRIMARY,
      dark: colors.PRIMARY_DARK,
      light: colors.PRIMARY_LIGHT
    },
    secondary: {
      main: colors.SECONDARY,
      light: colors.SECONDARY_LIGHT,
    },
    error: {
      main: colors.ERROR
    },
    // warning: {
    //   main: ''
    // },
    // info: {
    //   main: ''
    // },
    success: {
      main: colors.SUCCESS,
      dark: colors.SUCCESS_DARK,
    },
    text: {
      primary : colors.PRIMARY_TEXT,
      secondary: colors.SECONDARY_TEXT,
    }
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: '16px',
        padding : '8px',
        width   : 120
      },
      label: {
        fontWeight     : 'bold',
        textTransform  : 'none',
      },
      containedPrimary: {
        backgroundColor: colors.PRIMARY_LIGHT,
        color: colors.PRIMARY_DARK,
        '&:hover': {
          color: colors.WHITE
        }
      },
      outlinedPrimary: {
        borderColor : colors.PRIMARY_DARK,
        color       : colors.PRIMARY_DARK,
        padding     : '8px',
        '&:hover': {
          backgroundColor: colors.GRAY_LIGHT,
          borderColor : colors.PRIMARY_DARK,
        }
      }
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        width : 'fit-content',
      }
    },
    MuiTypography: {
      body1: {
        fontSize: '16px'
      },
      body2: {
        fontSize: '16px'
      },
      h6: {
        fontSize: '30px'
      }
    },
    MuiTableCell: {
      root: {
        fontSize: '16px'
      }
    },
    MuiTextField: {
      root: {
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderWidth: 1,
          },
        },
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: colors.SECONDARY,
        borderRadius   : 0,
        color          : colors.WHITE,
        fontSize       : '16px',
        textAlign      : 'center',
        '& .MuiTooltip-arrow': {
          color: colors.WHITE
        }
      }
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0
      }
    }
  },
  props: {
    MuiButton: {
      size : 'medium',
    },
    MuiDialog: {
      disableBackdropClick : true,
      disableEscapeKeyDown : true,
    },
    MuiTextField: {
      margin  : 'normal',
      size    : 'medium',
      variant : 'outlined',
    }
  }
});

if (window.ENVIRONMENT === ENVIRONMENT.PRODUCTION) {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

moment.tz.setDefault(window.TIMEZONE);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <App/>
        </MuiPickersUtilsProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
