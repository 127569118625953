import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

const drawerWidth            = 310;

export default makeStyles((theme) => ({
  appBar: {
    background  : theme.palette.common.white,
    marginLeft  : drawerWidth,
    width       : '100%',
    zIndex      : '20'
  },
  avatarButton: {
    justifyContent  : 'flex-end'
  },
  avatar: {
    backgroundColor : theme.palette.secondary.main
  },
  logo: {
    paddingLeft : theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    width       : drawerWidth/2,
  },
  toolbar: {
    alignItems      : 'center',
    justifyContent  : 'space-between',
    marginLeft      : theme.spacing(-1.5),
  },   
  toolbarRight: {
    alignItems      : 'center',
    display         : 'flex',
    justifyContent  : 'flex-end'
  },
  toolbarRightGreeting: {
    color : colors.PRIMARY
  }
}));