import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';
import images from '../../utility/images';

export default makeStyles((theme) => ({
  container: {
    alignItems    : 'center',
    display       : 'flex',
    height        : '90vh',
    justifyContent: 'flex-start',
    overflow      : 'hidden',
    position      : 'relative',
    padding       : theme.spacing(5),
  },
  imageContainer: {
    background: `transparent url(${images.ERROR503}) no-repeat center`,
    width     : '54%',
    height    : theme.spacing(81.125),
    marginTop : theme.spacing(-6.25),
  },
  content: {
    alignItems    : 'center',
    color         : colors.SECONDARY_TEXT,
    display       : 'flex',
    flexDirection : 'column',
    justifyContent: 'center',
    textAlign     : 'center',
    width         : '46%',
    '& .MuiTypography-root': {
      fontWeight: 900,
      width     : '100%',
      lineHeight: '1',
    },
    '& .MuiTypography-h5': {
      color    : colors.PRIMARY_TEXT,
      fontSize : '1.562vw',
      marginTop: theme.spacing(0.5)
    }
  },
  errorCode: {
    display      : 'inline-block',
    fontSize     : '18.25vw',
    height       : 'fit-content',
    letterSpacing: theme.spacing(3.125),
    margin       : '0',
    marginTop    : theme.spacing(-8.75)
  },
  error: {
    fontSize     : '3.20vw',
    letterSpacing: '0.00735em',
    margin       : '0',
    marginBottom : theme.spacing(3.125),
    marginTop    : theme.spacing(0.625),
  },
  text: {
    marginBottom: theme.spacing(0.5)
  },
}));