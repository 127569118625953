import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    '& .MuiSkeleton-root': {
      marginBottom  : theme.spacing(1),
      transform     : 'none',
    },
  },
  viewPaper: {
    marginTop: theme.spacing(1),

    '& .MuiTextField-root': {
      marginTop: theme.spacing(0),
    },
    '& .Mui-disabled': {
      pointerEvents: 'none',
    },
    '& .MuiInputBase-input': {
      color: colors.BLACK,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor : 'transparent !important'
    },
    '& .MuiOutlinedInput-notchedOutline:hover': {
      borderColor : 'transparent !important'
    },
    '& .MuiInputLabel-root': {
      fontWeight : 'bold'
    },

  },
  form: {
    paddingBottom : theme.spacing(2),
    paddingLeft   : theme.spacing(4),
    paddingRight  : theme.spacing(6),
    paddingTop    : theme.spacing(1),

    '& .MuiSkeleton-root': {
      transform    : 'none',
      marginBottom : theme.spacing(1)
    },
  },
  mainContainer: {
    display       : 'flex',
    flexDirection : 'column',
    maxWidth      : '100%',
    borderLeft    : '1px solid #707070',
    borderBottom  : '1px solid #707070',
    borderRight   : '1px solid #707070',
    borderRadius  : '4px',
    marginBottom  : theme.spacing(1),
    marginLeft    : theme.spacing(4),
    marginRight   : theme.spacing(6),
    marginTop     : 0,

    '& .MuiSkeleton-root': {
      transform    : 'none',
      marginBottom : theme.spacing(1)
    },
  },
  childrenContainer: {
    padding : theme.spacing(2),
  },
  header: {
    display       : 'flex',
    flexDirection : 'row',
    width         : '100% !important'
  },
  headerBorderBefore: {
    borderTop           : '1px solid #707070',
    width               : theme.spacing(2),
    borderTopLeftRadius : '4px'
  },
  headerTitle: {
    display       : 'flex',
    flexDirection : 'row',
    flexWrap      : 'nowrap',
    alignItems    : 'center',
    gap           : theme.spacing(0.5),
    width         : 'fit-content',
    height        : theme.spacing(4.5),
    margin        : '-1em 0.5em 0em 0.5em',
    overflow      : 'hidden',
    textOverflow  : 'ellipsis',
    fontSize      : theme.spacing(1.8),
    fontWeight    : 600,
    color         : colors.SECONDARY
  },
  headerBorderAfter: {
    borderTop            : '1px solid #707070',
    width                : theme.spacing(2),
    flexGrow             : 2,
    borderTopRightRadius : '4px',
  },
  skeletonPadding : {
    marginTop    : theme.spacing(2),
    paddingRight : theme.spacing(1),
  },
}));