import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { TableBody, TableCell, TableRow, Grid, } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';
import { TENANTS } from '../../../utility/constants';

const SkeletonCol = (props) => {
  const classes = useStyles();
  const { x, y, columns } = props;
  
  return (
    <TableCell 
      key={'empty-' + x + '-' + y}
      className={clsx({
        [classes.visuallyHidden] : columns[y].hidden || ''
      })}
    >
      <Skeleton variant='text' />
    </TableCell>
  )
}

const SkeletonRow = (props) => {
  const { columnKeys, columns, ROWS_PER_PAGE, module } = props;
  
  const classes = useStyles();
  return (
    <TableBody>
      {
        [...Array(ROWS_PER_PAGE)].map((row, x) =>
          < TableRow key={'empty-tr-' + row + x} >
            <TableCell key={'empty-cb-' + row + x} className={classes.visuallyHidden} >
              <Skeleton key={'skeleton-rect1' + row + x} variant='rect' />
            </TableCell>
            {
              columnKeys.map((col, y) =>
                <SkeletonCol key={y} x={x} y={y} columns={columns}/>
              )
            }
            {
              module !== TENANTS ? 
                <TableCell key={'empty-action-' + row + x}>
                  <Grid key={'grid-container-' + row + x} container>
                    <Grid item xs={6}>
                      <Skeleton variant='rect' width='50%' />
                    </Grid>
                    <Grid item xs={6}>
                      <Skeleton
                        variant='rect'
                        width='50%'
                      />
                    </Grid>
                  </Grid>
                </TableCell>
              : <></>
            }
          </TableRow >
        )
      }
    </TableBody>
  )
}

export default SkeletonRow;