import React from 'react';
import { Box, Paper, Dialog, Typography, Grid, Button } from '@material-ui/core';
import images from "../../../utility/images";
import { Trans } from 'react-i18next';
import useStyles from './styles';
import { STATUS } from '../../../utility/constants';

const StatusModal = (props) => {
  const { onClose, open, tenantName, handleResponse, status } = props;
  const classes     = useStyles();
  const isActivated = status === STATUS.ACTIVE;

  return (
    <>
      <Dialog
        id="statusModal"
        modal="false"
        open={open}
        onClose={onClose}
        aria-labelledby="row-status-dialog"
        aria-describedby="row-status-dialog-table"
        className={classes.dialog}
      >
        {
          <>
            <Paper id="statusModalBodyContainer" className={classes.statusModal}>
              <Box className={classes.dialogImage}>
                <img src={isActivated ? images.DEACTIVATE_TENANT : images.ACTIVATE_TENANT} alt='status-dialog-img' className={classes.image}/>
              </Box>
              <Box className={classes.dialogTitle}>
                <Typography component={'span'} className={classes.title}>
                  <b>
                    <Trans i18nKey={isActivated ? 'status-modal.keyDeactivateModalStatus' : 'status-modal.keyActivateModalStatus'}/>
                  </b>
                </Typography>
              </Box>
              <Box className={classes.dialogContent}>
                <Grid container spacing={2} className={`${classes.form} ${classes.space}`}>
                  <Grid item md={12}>
                    <Typography component={'span'}>
                      <Trans i18nKey={isActivated ? 'status-modal.keyDeactivateDescription' : 'status-modal.keyActivateDescription'} />
                    </Typography>
                    <Typography component={'span'}>
                      <Trans i18nKey={isActivated ? 'status-modal.keyDeactivateDescriptionWithTenantName' : 'status-modal.keyActivateDescriptionWithTenantName'} values={{item : tenantName}} />
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Box id="statusModalFooterContainer" className={classes.dialogActions}>
              <Button id="statusModalConfirmButton" color="primary" variant="outlined" className={classes.dialogSubmitButton} onClick={handleResponse}>
                  <Trans i18nKey={isActivated ? 'status-modal.keyConfirmDeactivate' : 'status-modal.keyConfirmActivate'} />
              </Button>
              <Button id="statusModalCancelButton" color="primary" variant="contained" className={classes.dialogCancelButton} onClick={onClose}>
                  <Trans i18nKey={isActivated ? 'status-modal.keyCancelDeactivate' : 'status-modal.keyCancelActivate'}/>
              </Button>
            </Box>
          </>
        }
      </Dialog>
    </>
  );
}

export default StatusModal;