import React, { useEffect } from "react";
import { IconButton, Snackbar, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useStyles from "./styles";
import CloseIcon from '@material-ui/icons/Close';
import { TOASTER_SEVERITY } from "../../utility/constants";
import { Trans } from "react-i18next";

const Toaster = (props) => {
  const classes = useStyles();
  const { isOpen, title, description, severity, onClose } = props;

  useEffect(() => {
    if (!severity) {
      return;
    }

    if (severity === TOASTER_SEVERITY.SUCCESS) {
      setTimeout(() => {
        onClose();
      }, 6000);
    }
  }, [onClose, severity]);

  return (
    <Snackbar
      anchorOrigin={{ 
        horizontal: 'right',
        vertical  : 'top'
      }}
      className={classes.snackbar}
      open={isOpen}
    >
      <Alert severity={severity} variant={'filled'} className={classes.alert}>
        <Typography className={classes.toasterTitle} variant='body2' display='block'>
          <Trans i18nKey={title}/>
        </Typography>
        <Typography variant='caption' className={classes.toasterDesc}>
          <Trans i18nKey={description}/>
        </Typography>
        <IconButton
          onClick={onClose} 
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </Alert>
    </Snackbar>
  );
}

export default Toaster;