import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationDE from './locales/de/translationDE.json';
import translationEN from './locales/en/translationEN.json';
import translationFil from './locales/fil/translationFil.json';
import translationI18n from './locales/i18n/translationI18N.json';
import { SUPPORTED_LANGUAGES } from './utility/constants';
import { getLanguageResources, getSupportedLanguages } from './utility/helper';

const resources = {
  en: {
    translation: translationEN
  },
  de: {
    translation: translationDE
  },
  //Available only for development process
  i18n: {
    translation: translationI18n
  },
  fil: {
    translation: translationFil
  }
}

const supportedLanguages = Object.values(SUPPORTED_LANGUAGES);

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .use(LanguageDetector)
  .init({
    react: {
      wait: true,
    },
    resources :  getLanguageResources(resources),
    supportedLngs: getSupportedLanguages(supportedLanguages),
    // lng: document.querySelector('html').lang,
    fallbackLng: "fil",
    keySeparator: '.',
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['localStorage', 'cookie', 'navigator', 'htmlTag', 'localStorage', 'sessionStorage', 'navigator', 'querystring', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie']
    }

  });

export default i18n;