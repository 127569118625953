import { Tab, Tabs } from "@material-ui/core";
import React from "react";
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const TabComponent = (props) => {
  const { handleChangeTab, tabValue } = props;
  const { t }   = useTranslation();
  const classes = useStyles();
  
  return (
    <Tabs 
      className={classes.tab}
      value={tabValue}
      onChange={handleChangeTab}
      indicatorColor="primary">
      <Tab id="deploymentProgressTab" label={<span className={classes.tabLabel}>{t('tenant-page.deploymentProgress')}</span>}/>
      <Tab data-testid="auditLogTab" id="auditLogTab" label={<span className={classes.tabLabel}>{t('tenant-page.auditLog')}</span>}/>
      <Tab id="billingPlanTab" label={<span className={classes.tabLabel}>{t('billing-page.billing')}</span>}/>
    </Tabs>
  )
}

export default TabComponent;