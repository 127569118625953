import React, { useCallback, useState } from 'react';
import { Route } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../components/loading';
import Toaster from '../components/toaster';
import { TOASTER_SEVERITY } from '../utility/constants';

const PrivateRoute = (props) => {
  const { exact, component: Component, layout: Layout, path } = props;

  const [isToasterOpen, setIsToasterOpen] = useState(false);
  const [title, setTitle]                 = useState('');
  const [description, setDescription]     = useState('');
  const [severity, setSeverity]           = useState('');
  const [isSaving, setIsSaving]           = useState(false);
  
  const history = useHistory();

  history.listen(() => {
    if (severity === TOASTER_SEVERITY.ERROR) {
      setIsToasterOpen(false);
    }
  });

	const showToaster = useCallback((toasterTitle, toasterDescription, toasterSeverity) => {
		setIsToasterOpen(true);
		setTitle(toasterTitle);
		setDescription(toasterDescription);
		setSeverity(toasterSeverity);
	}, []);

	const handleCloseToaster = () => {
		setIsToasterOpen(false);
	}

	const showLoading = (status) => {
		setIsSaving(status);
	}

  return (
    <>
      <Toaster isOpen={isToasterOpen} title={title} description={description} severity={severity} onClose={handleCloseToaster}/>
      <Route
        exact={exact}
        path={path}
        render={(routeProps) =>
          <Layout {...props} showLoading={showLoading} showToaster={showToaster}>
            <Loading isOpen={isSaving}/>
            <Component {...routeProps} showToaster={showToaster} showLoading={showLoading} />
          </Layout>
        }
      />
    </>
  )
};

export default PrivateRoute;