import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';
import images from '../../utility/images';

export default makeStyles((theme) => ({
  container: {
    alignItems    : 'center',
    background    : `transparent url(${images.ERROR404}) no-repeat center`,
    backgroundSize: 'cover',
    display       : 'display',
    height        : '80vh',
    justifyContent: 'flex-end',
    marginLeft    : '16px',
    overflow      : 'hidden',
  },
  content: {
    color    : colors.SECONDARY_TEXT,
    right    : theme.spacing(1.5),
    textAlign: 'center',
    width    : '50%',
    '& .MuiTypography-root': {
      fontWeight: 900,
      width     : '100%',
      lineHeight: '1',
    },
    '& .MuiTypography-h5': {
      color    : colors.PRIMARY_TEXT,
      fontSize : '1.562vw',
    }
  },
  errorCode: {
    fontSize  : '18.25vw',
    height    : '16vw',
    display   : 'inline-block',
    letterSpacing: theme.spacing(4.375),
  },
  error: {
    fontSize  : '3.64vw',
    marginTop : theme.spacing(8.2),
  },
  subText: {
    marginTop   : theme.spacing(5),
    marginBottom: theme.spacing(2)
  },
}));