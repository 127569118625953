import React from 'react';
import { Typography, Container, AppBar, Toolbar, Link } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import useStyles from './styles';
import Version from '../version';
import { FOOTER } from '../../utility/constants';

const Footer = (props) => {
	const classes                   = useStyles();
  const { position = 'absolute' }   = (props);
  const { t }                     = useTranslation();
  const history                   = useHistory();

  const dataPrivacy = () => {
    history.push('/data-privacy');
  }

  const imprint = () => {
    history.push('/imprint');
  }
	
	return (
    <Container maxWidth="md">
      <AppBar className={clsx(position === 'fixed' ? classes.appBarFixed : classes.appBarAbsolute)}>
        <Toolbar>
          <Typography variant="body2" >
            <Link id="dataPrivacyButton" className={classes.link} onClick={dataPrivacy}>
              {t('footer.dataPrivacy')}
            </Link>
            {' | '}
            <Link id="imprintButton" className={classes.link} onClick={imprint} >
              {t('footer.imprint')}
            </Link>
            {' | '}
          </Typography>
          <Version type={FOOTER}/>
          <div className={classes.grow} />
          <Typography variant="body2" color="inherit" className={classes.copyRight}>
            <Trans i18nKey={'login-page.copyRightInformation'} values={{item : new Date().getFullYear()}}/>
          </Typography>
        </Toolbar>
      </AppBar>
    </Container>
	)
}

export default Footer;