import React from 'react'
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';

const Error404 = (props) => {
  const classes = useStyles();
  const { t }   = useTranslation();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={9} lg={9} className={classes.content}>
        <Typography variant="h1" className={classes.errorCode}>{t('error-404-page.errorCode')}</Typography>
        <Typography variant="h4" className={classes.error}>{t('error-404-page.error')}</Typography>
        <Typography variant="h5" className={classes.subText}>{t('error-404-page.subText')}</Typography>
      </Grid>
    </Grid>
  )
}

export default Error404