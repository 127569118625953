import { GET } from "../utility/constants";
import api from "./api";
import { request } from "./requests";

export const getAuditLogsByTenantId = (id, order, page) => {
  return request({
    url    : `${api.AUDIT_LOGS_BY_TENANT_ID}/${id}`,
    method : GET,
    params : {
      sort       : `timeStamp,${order}`,
      page       : page,
    }
  });
}