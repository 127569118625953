import React from 'react'
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@material-ui/core';

const Error503 = (props) => {
  const classes = useStyles();
  const { t }   = useTranslation();

  return (
    <Grid container className={classes.container}>
      <Box className={classes.imageContainer}>
      </Box>
      <Box className={classes.content}>
        <Typography variant="h1" className={classes.errorCode}>{t('error-503-page.errorCode')}</Typography>
        <Typography variant="h4" className={classes.error}>{t('error-503-page.error')}</Typography>
        <Typography variant="h5" className={classes.text}>{t('error-503-page.text')}</Typography>
        <Typography variant="h5">{t('error-503-page.subText')}</Typography>
      </Box>
    </Grid>
  )
}

export default Error503