import { Box, CircularProgress, Link, Paper, Step, StepConnector, StepLabel, Stepper, Tooltip, Typography } from "@material-ui/core";
import { PanoramaFishEye } from "@material-ui/icons";
import { TimelineDot } from "@material-ui/lab";
import clsx from 'clsx';
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from 'react-router';
import { DEPLOYMENT_ERROR_CODE, DEPLOYMENT_PROGRESS } from "../../../utility/constants";
import images from "../../../utility/images";
import { DeploymentSkeleton } from "../tenant-skeleton";
import useStyles from './styles';

const StepIcon = (props) => {
  const { index, progressNumber, error, isHover } = props;
  const classes = useStyles();

  if (index === progressNumber && !error) {
    return (
      <TimelineDot className={isHover ? classes.iconInProgressHoverStyle : classes.iconInProgressStyle}>
        <CircularProgress className={classes.inProgressIcon} size="18px"/>
      </TimelineDot>
    )
  } else if (index < progressNumber) {
    return (
      <TimelineDot className={classes.iconSuccessStyle}>
        <img src={images.CHECK_MARK_WHITE} className={classes.iconSuccessSVG} alt="success"/>
      </TimelineDot>
    )
  } else if (index === progressNumber && error) {
    return (
      <TimelineDot className={classes.iconErrorStyle}>
        <img src={images.RETRY} className={classes.iconErrorSVG} alt="retry"/>
      </TimelineDot>
    )
  } else {
    return (
      <TimelineDot className={classes.iconPendingStyle}>
        <PanoramaFishEye className={classes.visuallyHidden} size="18px"/>
      </TimelineDot>
    )
  }
}

const StepperComponent = (props) => {
  const { steps, progressNumber, error, onMouseEnter, onMouseLeave, handleDeploymentRetry } = props;
  const classes = useStyles();
  const { t }   = useTranslation();

  const hoverMessage = (label) => {
    switch (label) {
      case DEPLOYMENT_PROGRESS.TENANT_CREATED:
        return t('tenant-page.creatingTenantHover');
      case DEPLOYMENT_PROGRESS.DATABASE_CREATED:
        return t('tenant-page.creatingDatabaseHover');
      case DEPLOYMENT_PROGRESS.INFRA_DEPLOYED:
        return t('tenant-page.deployingInfrastructureHover');
      case DEPLOYMENT_PROGRESS.DEPLOYMENT_FINISHED:
        return t('tenant-page.invitingAdministratorHover');
      default:
        break;
    }
  }
  
  const stepIconComponent = (index, label) => {
    if (index === progressNumber && !error) {
      return (
        <Tooltip key={index} title={t('tenant-page.inProgressMessage', { step: hoverMessage(label)} )}>
          <Step>
            <StepLabel 
              onMouseEnter={onMouseEnter} 
              onMouseLeave={onMouseLeave} 
              className={classes.textInProgressStyle} 
              icon={ <StepIcon index={index} progressNumber={progressNumber} error={error}/> }
            >
              {t(label)}
            </StepLabel>
          </Step>
        </Tooltip>
      )
    } else if (index < progressNumber) {
      return (
        <Tooltip key={index} title={t('tenant-page.completedMessage', { step: hoverMessage(label) })}>
          <Step>
            <StepLabel className={classes.textSuccessStyle} icon={ <StepIcon index={index} progressNumber={progressNumber} error={error}/> }>
              {t(label)}
            </StepLabel>
          </Step>
        </Tooltip>
      )
    } else if (index === progressNumber && error) {
      return (
        <Tooltip key={index} title={t('tenant-page.retry', { step: hoverMessage(label) })}>
          <Step onClick={() => handleDeploymentRetry()}>
            <StepLabel className={classes.textErrorStyle} icon={ <StepIcon index={index} progressNumber={progressNumber} error={error}/> }>
              {t(label)}
            </StepLabel>
          </Step>
        </Tooltip>
      )
    } else {
      return (
        <Tooltip key={index} title={t('tenant-page.pendingMessage', { step: hoverMessage(label) })}>
          <Step>
            <StepLabel className={classes.textPendingStyle} icon={ <StepIcon index={index} progressNumber={progressNumber} error={error}/> }>
              {t(label)}
            </StepLabel>
          </Step>
        </Tooltip>
      )
    }
  }

  return (
    <Box className={classes.stepperContainer}>
      <Stepper className={classes.stepper} connector={<StepConnector className={classes.connector}/>} alternativeLabel>
        {
          steps.map((label, index) => (
            stepIconComponent(index + 1, label)
          ))
        }
      </Stepper>
    </Box>
  )
}

const DeploymentProgressComponent = (props) => {
  const { id, progressNumber, deploymentDescription, errorIn, error, handleDeploymentRetry, isLoading, deploymentError } = props;
  const history   = useHistory();
  const { location } = history;
  const { state } = location;
  const triggerDatabaseCreation = state?.triggerDatabaseCreation;

  const classes = useStyles();

  const [isHover, setIsHover] = useState(false);
  
  const isDuplicateDatabaseError = deploymentError === DEPLOYMENT_ERROR_CODE.DATABASE_EXISTS;

  // Automatically proceed with database creation when the tenant name has been updated and the
  // deployment error is DATABASE_EXIST
  useEffect(() => {
    if (triggerDatabaseCreation) {
      handleDeploymentRetry();
      delete state?.triggerDatabaseCreation;
      history.replace({ ...location, state });
    }
  }, [handleDeploymentRetry, triggerDatabaseCreation, history, state, location]);

  const progress = {
    progressName  : [
      DEPLOYMENT_PROGRESS.TENANT_CREATED, 
      DEPLOYMENT_PROGRESS.DATABASE_CREATED, 
      DEPLOYMENT_PROGRESS.INFRA_DEPLOYED, 
      DEPLOYMENT_PROGRESS.DEPLOYMENT_FINISHED
    ]
  }

  const onMouseEnter = () => {
    setIsHover(true);
  }

  const onMouseLeave = () => {
    setIsHover(false);
  }

  const handleUpdate = () => {
    history.push(`../update/${id}`);
  }

  return(
    <>
      {
        isLoading ? 
          <DeploymentSkeleton />
        :
          <Paper className={classes.deploymentPaper} elevation={4}>
            <Box className={classes.boxBorder}>
              <Box className={classes.timeline}>
                <StepperComponent
                  steps={progress.progressName}
                  progressNumber={progressNumber}
                  error={error}
                  isHover={isHover}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  handleDeploymentRetry={handleDeploymentRetry}
                  />
              </Box>
              <Box className={error ? clsx(classes.error, classes.textErrorStyle) : 'hidden'}>
                <Typography component={'span'}>
                  <Trans i18nKey={`tenant-page.${errorIn}`} values={{item : deploymentDescription }}/>
                </Typography>
                <Typography component={'span'}>
                  <Trans 
                    i18nKey={isDuplicateDatabaseError ? 'tenant-page.updateTenantMessage': 'tenant-page.retryMessage'}
                    components={<Link onClick={handleUpdate} />}
                  />
                </Typography>
              </Box>
            </Box>
          </Paper>
      }
    </>

  )
}

export default DeploymentProgressComponent;