import React from 'react'
import { Box, Button, Dialog, Paper, Typography } from '@material-ui/core'
import { Trans } from 'react-i18next';
import images from '../../utility/images'
import useStyles from './styles';

const SessionTimeoutModal = (props) => {
  const { isOpen, handleCloseModal, secondsRemaining, signOut } = props;
  const classes = useStyles();

  const secondsToDisplay = secondsRemaining % 60;

  return (
    <Dialog
      modal="false"
      open={isOpen}
      onClose={handleCloseModal}
      aria-labelledby="row-success-dialog"
      aria-describedby="row-success-dialog-table"
      className={classes.dialog}
    >
      <Paper elevation={0} className={classes.diaglogContainer}>
        <Box className={classes.dialogImage}>
          <img src={images.SESSION_TIMEOUT} alt='session-timeout-img' />
        </Box>
        <Box className={classes.dialogImage}>
        </Box>
        <Box className={classes.dialogTitle}>
          <Typography>
            <b>
              <Trans i18nKey='session-timeout-modal.dialogTitle' />
            </b>
          </Typography>
        </Box>
        <Box className={classes.dialogContent}>
          <Typography className={classes.dialogTimer}>
            <Trans i18nKey='session-timeout-modal.dialogTimer' values={{ timer: String(secondsToDisplay).padStart(2, '0') }}/>
          </Typography>
          <Typography className={classes.dialogDescription}>
            <Trans i18nKey='session-timeout-modal.dialogDescription' />
          </Typography>
        </Box>
      </Paper>
      <Box className={classes.dialogActions}>
        <Button color="primary" variant="outlined" className={classes.dialogLogoutButton} onClick={signOut}>
          <Trans i18nKey='session-timeout-modal.logOutButton' />
        </Button>
        <Button color="primary" variant="contained" onClick={handleCloseModal}>
          <Trans i18nKey='session-timeout-modal.stayLoginButton' />
        </Button>
      </Box>
    </Dialog>
  )
}

export default SessionTimeoutModal