import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  deleteModal: {
    alignItems    : 'center',
    display       : 'flex',
    flexDirection : 'column',
    width         : '100%'
  },
  dialogImage: {
    paddingBottom: theme.spacing(2),
    paddingTop   : theme.spacing(4)
  },
  image: {
    width: '50px'
  },
  dialogTitle: {
    textAlign: 'center'
  },
  title: {
    color     : colors.SECONDARY,
    fontSize  : '20px',
    fontWeight: 'bold'
  },
  dialogContent: {
    paddingBottom: theme.spacing(5),
    paddingTop   : theme.spacing(3),
    textAlign    : 'center',
    width        : '450px',
    wordBreak    : 'break-word'
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      width   : '500px',
      maxWidth: '100%'
    }
  }
}));