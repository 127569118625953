import { ACCOUNT_MANAGER, DELETE, EXTERNAL_ACCOUNT_MANAGER, GET, POST, PUT, SYSTEM_ADMINISTRATOR, SYSTEM_ROLES } from "../utility/constants";
import api from "./api";
import { request } from "./requests";

const AUTH_BASE_PATH = window.KEYCLOAK_URL;
const USERS_API = AUTH_BASE_PATH + "/admin/realms/" + window.KEYCLOAK_REALM + "/users";
const AM_CLIENT_API = AUTH_BASE_PATH + "/admin/realms/" + window.KEYCLOAK_REALM + "/clients";
const CLIENTS_ROLES_MAPPING_PATH = "role-mappings/clients";

const AM_CLIENT_NAME = "account-management-client";
const CREATE_PASSWORD = "CREATE_PASSWORD";
const EXECUTION_ACTION_EMAIL = "execute-actions-email";

export const INVITE_USER = "INVITE_USER";

export const getAllUsers = async (params) => {
  return await request({
    url   : USERS_API,
    method: GET,
    params: params
  });
}

export const getUserById = async (id) => {
  try {
    const response = await request({
      url   : `${USERS_API}/${id}`,
      method: GET
    });

    const { firstName, lastName, email, attributes, federationLink, enabled } = response.data;

    const role = await getUserRoles(id);

    return {
      firstName       : firstName,
      lastName        : lastName,
      email           : email,
      role            : role,
      company         : attributes?.company ? attributes.company[0] : '',
      isUserFederated : !!federationLink,
      status          : enabled
    }
  } catch (error) {
    if (error?.response?.status === 404) {
      return null;
    }
  }
}

export const getUserCount = async () => {
  return await request({
    url   : `${USERS_API}/count`,
    method: GET
  });
}

export const getUserRoles = async (id) => {
  const response = await request({
    url   : `${USERS_API}/${id}/role-mappings`,
    method: GET
  });

  const { clientMappings } = response.data;

  // Handle Imported LDAP User without assigned role
  if (!clientMappings) {
    return {}
  }  

  const userRole = clientMappings[AM_CLIENT_NAME].mappings[0];

  return {
    id             : userRole.id,
    name           : userRole.name,
    translationKey : getRoleTranslationKey(userRole.name)
  };
}

export const getAllUserRoles = async () => {
  const clientId = await getAccountManagementClientId();

  const response = await request({
    url   : `${AM_CLIENT_API}/${clientId}/roles`,
    method: GET
  });

  const { data } = response;

  const roles = data.map(roleData => {
    const { id, name } = roleData;

    return {
      name           : name,
      id             : id,
      translationKey : getRoleTranslationKey(name)
    };
  });

  return roles;
};

export const getAccountManagementClientId = async () => {
  const clientsResponse = await request({
    url   : AM_CLIENT_API,
    method: GET,
    params: {
      clientId : AM_CLIENT_NAME
    },
  });

  if (clientsResponse.status !== 200) {
    throw new Error(clientsResponse.status);
  }

  const clientsData = clientsResponse.data;
  if (!clientsData || clientsData.length === 0){
    throw new Error("API Client not found");
  }

  return clientsData[0].id;
}

export const createUser = async (user) => {
  const response = await request({
    url    : USERS_API,
    method : POST,
    data   : {
      email     : user.email,
      firstName : user.firstName,
      lastName  : user.lastName,
      enabled   : true,
      username  : user.email,
      attributes: {
        company: user.userRole.name === SYSTEM_ROLES.SYSTEM_ADMIN ? '' : user.company
      }
    },
  });

  if (response.status !== 201) {
    throw new Error(response.status);
  }

  const userURL = response.headers.location;
  const newUserId = userURL.replace(`${USERS_API}/`, "");

  await assignUserSystemRoles(newUserId, user.userRole);
  await inviteUser(newUserId, [CREATE_PASSWORD]);

  return newUserId;
};

export const updateUser = async (id, user) => {
  await request({
    url    : `${USERS_API}/${id}`,
    method : PUT,
    data   : user
  });
};

export const deleteUser = async (id) => {
  await request({
    url   : `${USERS_API}/${id}`,
    method: DELETE
  });

  await request({
    url    : `${api.USER_TENANTS_DELETE}/${id}`,
    method : DELETE
  });
};

export const inviteUser = async (id, data) => {
  return await request({
    url     : `${USERS_API}/${id}/${EXECUTION_ACTION_EMAIL}`,
    method  : PUT,
    headers : {
      'Content-Type': 'application/json'
    },
    data    : data
  });
}

export const assignUserSystemRoles = async (id, roles) => {
  const clientId = await getAccountManagementClientId();
  return await request({
    url    : `${USERS_API}/${id}/${CLIENTS_ROLES_MAPPING_PATH}/${clientId}`,
    method : POST,
    data   : [{
      id  : roles.id,
      name: roles.name
    }]
  });
};

export const removeUserSystemRoles = async (id, roles) => {
  const clientId = await getAccountManagementClientId();
  return await request({
    url    : `${USERS_API}/${id}/${CLIENTS_ROLES_MAPPING_PATH}/${clientId}`,
    method : DELETE,
    data   : [{
      id  : roles.id,
      name: roles.name
    }]
  });
};

const getRoleTranslationKey = (roleName) => {
  switch (roleName) {
    case SYSTEM_ROLES.SYSTEM_ADMIN:
      return SYSTEM_ADMINISTRATOR;
    case SYSTEM_ROLES.ACCOUNT_MANAGER:
      return ACCOUNT_MANAGER;
    case SYSTEM_ROLES.EXTERNAL_ACCOUNT_MANAGER:
      return EXTERNAL_ACCOUNT_MANAGER;
    default:
      return roleName;
  }
}