import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  appBarFixed: {
    backgroundColor  : theme.palette.text.secondary,
    bottom           : '0',
    color            : colors.WHITE,
    position         : 'fixed',
    top              : 'auto'
  }, 
  appBarAbsolute: {
    backgroundColor  : theme.palette.text.secondary,
    bottom           : 'auto',
    color            : colors.WHITE,
    position         : 'absolute',
    top              : 'auto'
  }, 
  link: {
    color          : 'white',
    paddingLeft    : theme.spacing(1),
    paddingRight   : theme.spacing(1),
    textDecoration : 'none',
    '&:hover': {
      textDecoration  : 'underline',
      cursor          : 'pointer',
    },
  },
  grow: {
    flexGrow: 1,
  },
  copyRight: {
    paddingRight: theme.spacing(1)
  }
}));