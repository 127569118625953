import cookies from 'js-cookie';
import { TOKENS } from '../utility/constants';
import { getLocalStorageItem } from '../utility/helper';

export const LOGIN         = 'LOGIN';
export const LOGOUT        = 'LOGOUT';
export const UPDATE_USER   = 'UPDATE_USER';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const initialState = {
  user        : getLocalStorageItem('user'),
  accessToken : cookies.get(TOKENS.ACCESS_TOKEN),
  refreshToken: cookies.get(TOKENS.REFRESH_TOKEN)
};

const AuthReducer = (state, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        accessToken : action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        user        : action.payload.user,
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        accessToken : action.payload.accessToken,
      }
    case UPDATE_USER:
      return {
        ...state,
        user        : action.payload.user,
      }
    case LOGOUT:
      return {
        ...state,
        accessToken : undefined,
        refreshToken: undefined,
        user        : undefined,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export default AuthReducer;