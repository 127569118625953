import React from 'react';
import { Box, Button, Paper, Dialog, Typography } from '@material-ui/core';
import images from "../../utility/images";
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const UpdateTenantHostModal = (props) => {
  const { handleModalSubmit, handleModalCancel } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      id="UpdateTenantHostModal"
      modal="false"
      {...props}
      aria-labelledby="row-tenant-hostname-dialog"
      aria-describedby="row-tenant-hostname-dialog-table"
      className={classes.dialog}
    >
      <Paper id="tenantHostname" className={classes.dialogModal}>
        <Box className={classes.dialogImage}>
          <img src={images.PERSON_WITH_QUESTION_MARK} alt='tenant-hostname-img' className={classes.image}/>
        </Box>
        <Box className={classes.dialogTitle}>
          <Typography className={classes.title}>{t(`tenant-hostname-modal.title`)}</Typography>
        </Box>
        <Box className={classes.dialogContent}>
          <Typography>{t(`tenant-hostname-modal.message`)}</Typography> 
        </Box>
      </Paper>
      <Box id="tenantHostnameButtonsContainer" className={classes.dialogActions}>
        <Button id="tenantHostnameModalSubmitButton" color="primary" variant="outlined" className={classes.dialogSubmitButton} onClick={handleModalSubmit} >
          {t(`tenant-hostname-modal.continueButton`)}
        </Button>
        <Button id="tenantHostnameModalCancelButton" color="primary" variant="contained" className={classes.dialogCancelButton} onClick={handleModalCancel} >
          {t(`tenant-hostname-modal.cancelButton`)}
        </Button>
      </Box>
    </Dialog>
  );
}

export default UpdateTenantHostModal;