import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    paddingTop   : theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(1),

    '& .MuiTextField-root': {
      marginTop: theme.spacing(0),
    },
    '& .Mui-disabled': {
      pointerEvents: 'none',
    },
    '& .MuiInputBase-input': {
      color: colors.BLACK,
    }
  },
  usersEditIcon: {
    paddingRight  : theme.spacing(0.625),
    paddingBottom : theme.spacing(0.625)
  },
  form: {
    padding     : theme.spacing(1),
    paddingLeft : theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  dropdown: {
    zIndex: 10,
  },
  status: {
    '& .MuiInputBase-root:hover':{
      cursor: 'pointer',
    },
    '& .MuiInputBase-input:hover':{
      cursor: 'pointer',
    }
  },
  action: {
    backgroundColor: theme.palette.grey[100],
    padding        : theme.spacing(2),
    textAlign      : 'right',

    '& .MuiButton-root:first-of-type': {
      marginRight: theme.spacing(2)
    }
  },
  alignCheckbox: {
      paddingTop : theme.spacing(1),
  },
  switch: {
    width  : 52,
    height : 22,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding        : 0,
      margin         : 1.29,
      '&.Mui-checked': {
        color     : theme.palette.common.white,
        marginLeft: theme.spacing(-0.34),
        transform : 'translateX(34px)',
        '& + .MuiSwitch-track': {
          backgroundColor: colors.ACTIVE,
          border         : 0,
          opacity        : 1,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width    : 19,
      height   : 19,
    },
    '& .MuiSwitch-track': {
      borderRadius   : 26 / 2,
      backgroundColor: theme.palette.grey[500],
      opacity        : 1,
      transition     : theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  },
  switchBox: {
    display       : 'flex',
    flexDirection : 'row',
    justifyContent: 'flex-end',
    padding       : theme.spacing(2),
    paddingLeft   : theme.spacing(3),
    paddingRight  : theme.spacing(3),
  },
  activeSwitch: {
    marginLeft : theme.spacing(2),
    color      : colors.ACTIVE,
  },
  deactivatedSwitch: {
    marginLeft : theme.spacing(2),
    color      : colors.SECONDARY
  },
  disabledSwitch : {
    width  : 52,
    height : 22,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding        : 0,
      margin         : 1.29,
      '&.Mui-checked': {
        color     : theme.palette.grey,
        marginLeft: theme.spacing(-0.34),
        transform : 'translateX(34px)',
        '& + .MuiSwitch-track': {
          backgroundColor: '#B7B7B8',
          border         : 1,
          opacity        : 1,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width    : 19,
      height   : 19,
    },
    '& .MuiSwitch-track': {
      borderRadius   : 26 / 2,
      backgroundColor: theme.palette.grey[500],
      opacity        : 1,
      transition     : theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
    '& .MuiIconButton-label': {
      color: '#D4D4D5'
    }
  },
  disabledSwitchText : {
    marginLeft   : theme.spacing(2),
    color        : colors.SECONDARY
  },
}));