import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    paddingBottom : theme.spacing(4),
    paddingLeft   : theme.spacing(10),
    paddingRight  : theme.spacing(10),
    paddingTop    : theme.spacing(15)
  },
  root: {
    width: '100%',
  },
  paragraph: {
    paddingLeft   : theme.spacing(2)
  },
  form: {
    padding       : theme.spacing(1),
    paddingLeft   : theme.spacing(2),
    paddingRight  : theme.spacing(2)
  },
  paper: {
    marginBottom: theme.spacing(2),
    marginTop   : theme.spacing(4),
    padding     : theme.spacing(9),
    width       : '100%',
  },
  visuallyHidden: {
    border   : 0,
    clip     : 'rect(0 0 0 0)',
    height   : 1,
    margin   : -1,
    overflow : 'hidden',
    padding  : 0,
    position : 'absolute',
    top      : 20,
    width    : 1,
  },
  title: {
    color        : colors.SECONDARY_TEXT,
    fontWeight   : 'bold',
    fontSize     : '60px',
    paddingBottom: theme.spacing(8),
    textAlign    : 'center',
    '& .MuiTypography-root': {
      lineHeight: -1
    }
  },
  paragTitle: {
    color        : colors.SECONDARY_TEXT,
    fontSize     : '30px',
    fontWeight   : 'bold',
    paddingBottom: theme.spacing(1),
    '& .MuiGrid-root.MuiGrid-item': {
      marginTop : theme.spacing(2),
      padding   : '0'
    }
  },
  flexForm: {
    display: 'flex'
  },
  space: {
    paddingBottom : theme.spacing(3),
    paddingTop    : theme.spacing(5)
  },
  appBar: {
    background  : theme.palette.common.white,
    transition  : theme.transitions.create(['width', 'margin'], {
      duration  : theme.transitions.duration.leavingScreen,
      easing    : theme.transitions.easing.sharp
    }),
  },
  logo: {
    padding : theme.spacing(0),
    width   : theme.spacing(26),
  },
  link: {
    color: colors.PRIMARY
  },
}));