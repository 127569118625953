import React, { useContext } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { AuthContext } from '../context/authContext';

// Routing
import PrivateRoute from './PrivateRoute';

// Layouts
import MainLayout from '../components/main-layout';

// Pages
import Imprint from '../pages/imprint';
import PrivacyPolicy from '../pages/privacy-policy';
// import AccountManagement from '../pages/account-management';
import Tenant from '../pages/tenant';
import Tenants from '../pages/tenants';
import User from '../pages/user';
import Users from '../pages/users';
// import Settings from '../pages/settings';
import ProtectedResources from '../ProtectedResources';
import Error403 from '../pages/error-403';
import Error404 from '../pages/error-404';
import Error500 from '../pages/error-500';
import Error503 from '../pages/error-503';
import MyAccount from '../pages/my-account';
import Health from '../pages/health';
import { handleRoutes } from '../utility/permissions';

const ErrorRouteHandler = (props) => {
  const { validPaths } = props;

  const history = useHistory();

  const currentPath = history.location.pathname;
  
  const paths = validPaths.map(route => route.props.path);

  const isValidPath = paths.includes(currentPath);

  return <PrivateRoute layout={MainLayout} component={isValidPath ? Error403 : Error404} />
}

const EnhancedSwitch = (props) => {
  const { children } = props;
  
  const { state } = useContext(AuthContext);
  const { user } = state;

  const routes = handleRoutes(children, user);

  return (
    <Switch>
      {
        routes
      }
      <ErrorRouteHandler validPaths={children}/>
    </Switch>
  )
}

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/health" component={Health}/>
      <Route exact path="/imprint" component={Imprint} />
      <Route exact path="/data-privacy" component={PrivacyPolicy} />
      {/* <VerificationRoute layout={PublicLayout} exact path="/create-password/:code" component={CreatePassword} /> */}
      {/* <VerificationRoute layout={PublicLayout} exact path="/new-password/:code" component={NewPassword} /> */}
      <ProtectedResources>
        <EnhancedSwitch> 
            <Route exact path="/"><Redirect to="/tenants" /></Route>
            {/* <PrivateRoute layout={MainLayout} path="/dashboard" component={AccountManagement} /> */}
            <PrivateRoute exact layout={MainLayout} path="/my-account" component={MyAccount} />
            <PrivateRoute exact layout={MainLayout} path="/users" component={Users} />
            <PrivateRoute exact layout={MainLayout} path="/users/view/:id" component={User} />
            <PrivateRoute exact layout={MainLayout} path="/users/update/:id" component={User} />
            <PrivateRoute exact layout={MainLayout} path="/users/create" component={User} />
            <PrivateRoute exact layout={MainLayout} path="/tenants" component={Tenants} />
            <PrivateRoute exact layout={MainLayout} path="/tenants/create" component={Tenant} />
            <PrivateRoute exact layout={MainLayout} path="/tenants/view/:id" component={Tenant} />
            <PrivateRoute exact layout={MainLayout} path="/tenants/update/:id" component={Tenant} />
            {/* <PrivateRoute exact layout={MainLayout} path="/settings" component={Settings} /> */}
            <PrivateRoute exact layout={MainLayout} path="/internal-server-error" component={Error500} />
            <PrivateRoute exact layout={MainLayout} path="/under-maintenance" component={Error503} />
        </EnhancedSwitch>
      </ProtectedResources>
    </Switch>
  )
};

export default Routes;
