import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  paperCreate: {
    border: "1px solid #707070",
    borderRadius: "8px",
    margin: "32px",
    paddingTop: "50px",
    paddingBottom: "50px",
    paddingLeft: "73px",
    paddingRight: "73px",
  },
  paperView: {
    border        : '1px solid #707070',
    borderRadius  : '8px',
    height        : '100%',
    paddingLeft   : theme.spacing(6),
    paddingRight  : theme.spacing(6),
    paddingBottom : theme.spacing(4),
    paddingTop    : theme.spacing(4),
  },
  paperRequired: {
    border: "1px solid #DA5F54",
    borderRadius: "8px",
    margin: "32px",
    paddingTop: "50px",
    paddingBottom: "50px",
    paddingLeft: "73px",
    paddingRight: "73px",
  },
  planContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  plan: {
    border: "1px solid #ddd",
    borderTop: '6px solid #A2DFFF',
    borderRadius: "8px 8px 8px 8px",
    width: "30%",
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)",
    opacity: "1"
  },
  button: {
    display: "block",
    width: "100%",
    padding: "10px",
    backgroundColor: colors.PRIMARY_LIGHT,
    color: colors.PRIMARY,
    border: "none",
    cursor: "pointer",
    textAlign: "center",
    '&:hover, &:focus' : {
      backgroundColor : colors.PRIMARY_DARK,
    },
  },
  planTitle: {
    fontSize: "25px",
    color: colors.SECONDARY,
    paddingBottom: theme.spacing(2),
    textTransform: "uppercase",
    fontWeight: "bold"
  },
  text: {
    display: "flex",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    '& .MuiTypography-body1': {
      marginLeft : theme.spacing(1),
      fontSize: "16px",
    },
    '& .MuiSvgIcon-root': {
      color: "green"
    },
  },
  boxFlex: {
    display: "flex",
    flexWrap: "wrap"
  },
  icon: {
    margin: '6px',
    marginLeft: '10px',
    color: colors.SECONDARY,
  },
  iconView: {
    margin: '6px',
    marginLeft: '10px',
    color: 'white',
  },
  currentPlan: {
    border: '1px solid #006AB3CC',
    borderRadius: '14px',
    fontWeight: 'bold',
    paddingLeft: '12.5px',
    paddingRight: '12.5px',
    paddingTop: '7px',
    paddingBottom: '7px',
    backgroundColor: colors.PRIMARY_LIGHT,
    color: colors.PRIMARY,
    fontSize: '12px',
    height: '36px',
    width: '101px',
    whiteSpace: 'nowrap',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '4fr 0.1fr 0.1fr',
    gap: '10px',
  },
  gridItem1: {
    paddingTop: '36px',
    paddingLeft: '31px',
    '& a.MuiLink-root.MuiLink-underlineHover.MuiTypography-colorPrimary': {
      cursor: "pointer",
    }
  },
  gridItem2: {
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingLeft: '30px',
    paddingRight: '30px',
    backgroundColor: colors.SECONDARY_GRAY_LIGHT,
    height: "78px"
  },
  selectedPlanTitle: {
    fontSize: "25px",
    color: "white",
    paddingBottom: theme.spacing(2),
    textTransform: "uppercase",
    fontWeight: "bold"
  },
  selectedGridItem1: {
    paddingTop: '36px',
    paddingLeft: '31px',
    backgroundColor: colors.PRIMARY_DARK,
    '& a.MuiLink-root.MuiLink-underlineHover.MuiTypography-colorPrimary': {
      color: "#57b9ff",
      cursor: "pointer",
    }
  },
  selectedGridItem2: {
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingLeft: '30px',
    paddingRight: '30px',
    backgroundColor: colors.SECONDARY_GRAY_LIGHT,
    height: "78px"
  },
  selectedButton: {
    display: "block",
    width: "100%",
    padding: "10px",
    backgroundColor: "white",
    border: "1px solid #05619E",
    color: colors.PRIMARY,
    cursor: "pointer",
    textAlign: "center",
    '&:hover, &:focus' : {
      backgroundColor : "#DCF7FF",
      color: colors.PRIMARY,
    },
  },
  selectedText: {
    display: "flex",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    '& .MuiTypography-body1': {
      marginLeft : theme.spacing(1),
      fontSize: "16px",
      color: "white"
    },
    '& .MuiSvgIcon-root': {
      color: "white"
    },
  },
  selectedPlan: {
    border: "1px solid #ddd",
    borderTop: '6px solid #05619E',
    borderRadius: "8px 8px 8px 8px",
    width: "30%",
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)",
    opacity: "1",
    backgroundColor: colors.PRIMARY_DARK
  },
  downloadButton: {
    padding: "10px",
    backgroundColor: "white",
    border: "1px solid #05619E",
    color: colors.PRIMARY,
    cursor: "pointer",
    textAlign: "center",
    '&:hover, &:focus' : {
      backgroundColor : "#DCF7FF",
      color: colors.PRIMARY,
    },
  },
  download: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '30px',
    paddingLeft: '32px',
    paddingRight: '32px'
  },
}));