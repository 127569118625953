import { ACTION_UPDATE, DEFAULT_PATHS, DELETE, GET, MENU_ITEMS, PAGES, PATCH, POST, PUT, SCOPES, SYSTEM_ROLES } from "./constants";

const NO_ROLES = 'No User Roles';
const NO_PERMISISONS = 'No User Permissions';

/**
 * It takes an array of system roles, and returns an object with the name of the role and an array of
 * permissions
 * @param systemRoles - This is the array of system roles that you get from the API.
 * @returns An object with a name and permissions property.
 */
export const formatSystemRoles = (systemRoles) => {
  if (!systemRoles.length) {
    console.error(NO_ROLES);
    return null;
  }

  const permissions = formatPermissions(systemRoles[0].permissions);

  return {
    name: systemRoles[0].name,
    permissions: permissions,
  }
}

/**
 * It takes an array of permission objects, and returns an array of objects with 
 * entity name and its permissions
 * @param permissions - This is the array of permissions that you get from the API.
 */
export const formatPermissions = (permissions) => {
  if (!permissions.length) {
    console.error(NO_PERMISISONS);
    return null;
  }

  const mappedEntities = mapEntities(permissions);

  const entities = [...new Set(mappedEntities)];

  return entities.map(entity => {
    const filteredPermissions = filterPermissions(permissions, entity);
    const scopes = mapPermissions(filteredPermissions);

    return {
      entity : entity,
      scopes: scopes
    }
  });
}

/**
 * It takes an array of permissions and returns an array of entities
 * @param permissions - The permissions array that you want to map.
 * @returns An array of entities.
 */
const mapEntities = (permissions) => {
  return permissions.map(permission => permission.entity.toLowerCase());
}

/**
 * Filter the permissions array to only include the permissions for the given entity.
 * @param permissions - The permissions array that you get from the API.
 * @param entity - The entity you want to filter the permissions by.
 * @returns An array of permissions that match the entity.
 */
const filterPermissions = (permissions, entity) => {
  return permissions.filter(permission => permission.entity.toLowerCase() === entity);
}

/**
 * It takes an array of filtered permissions objects and returns an array of the permission of a specific entity of those
 * objects
 * @param filteredPermissions - The permissions that are filtered by the entity.
 * @returns An array of permissions
 */
const mapPermissions = (filteredPermissions) => {
  return filteredPermissions.map(entityPermission => entityPermission.function)
}

/**
 * It checks if the entity is in the list of entities that the user has access to, and if it is, it
 * checks if the user has access to the action that they're trying to perform
 * @param entity - The entity you want to check permissions for.
 * @param scope - The scope of the permission.
 * @param roles - The permissions object that is returned from the API.
 * @returns A boolean value
 */
export const handlePermissions = (entity, scope, roles) => {
  if (!roles) {
    return false;
  }

  const { permissions } = roles;

  if (!entity) {
    return true;
  }

  const entities = mapEntities(permissions);

  const hasPermission = entities.includes(entity);

  if (hasPermission) {
    const hasActionPermission = handleActionPermission(entity, scope, permissions);
    return hasActionPermission;
  }

  return hasPermission;
}

/**
 * It takes in an entity, scope, and permissions array and returns a boolean value based on whether the
 * scope is included in the entity's scopes.
 * @param entity - The entity you want to check the permission for.
 * @param scope - The scope of the action you want to perform.
 * @param permissions - The permissions object that is returned from the API.
 * @returns A boolean value
 */
const handleActionPermission = (entity, scope, permissions) => {
  const findEntityScopes = permissions.find(permission => permission.entity === entity);
  return findEntityScopes.scopes.includes(scope);
}

/**
 * If the user is an account manager, and the entity is users, then check if the user has account
 * management permission
 * @param entity - The entity that the user is currently viewing.
 * @param roles - The current user's roles
 * @param row - The row object that is being rendered
 * @param hasPermission - boolean value that determines if the user has permission to perform the
 * action
 */
export const handleActionButtonPermission = (entity, roles, userRole, hasPermission) => {
  if (!hasPermission) {
    return hasPermission;
  }
  const { name } = roles;
  const isAccountManagement = name === SYSTEM_ROLES.ACCOUNT_MANAGER;
  
  if (!isAccountManagement) {
    return hasPermission;
  }
  
  if (entity !== PAGES.USERS) {
    return hasPermission;
  }
    
  return isAccountManagementPermission(userRole);
}

/**
 * If the current path is not the update page, or the current user is not an account manager, then
 * return. Otherwise, if the action button permission is true, then return. Otherwise, throw an
 * exception
 * @param actionButtonPermission - This is the permission that is passed from the backend.
 * @returns nothing.
 */
export const handleUpdatePagePermission = (actionButtonPermission, roles, path) => {
  if (!path.includes(ACTION_UPDATE) || roles.name !== SYSTEM_ROLES.ACCOUNT_MANAGER) {
    return;
  }

  if (actionButtonPermission) {
    return;
  }

  throw new Error("404 Not Found");
}

/**
 * It returns true if the system role is either an account manager, external account manager, or no role
 * @param userRole - The system role of the user.
 * @returns A boolean value.
 */
const isAccountManagementPermission = (userRole) => {
  const isNoRole = !userRole?.name;

  return userRole.name === SYSTEM_ROLES.ACCOUNT_MANAGER || userRole.name === SYSTEM_ROLES.EXTERNAL_ACCOUNT_MANAGER || isNoRole;
} 

//ROUTING

/* Creating an array of routes that the user will always have access to. */
const defaultRoutes = Object.values(DEFAULT_PATHS);

/**
 * It takes in an array of routes and an object of roles, and returns an array of routes that the user
 * is allowed to access
 * @param routes - This is the routes array that you pass to the <Router> component.
 * @param roles - This is the object that contains the user's roles and permissions.
 * @returns An array of routes that are allowed to be accessed by the user.
 */
export const handleRoutes = (routes, user) => {
  if (!user) {
    return null;
  }

  const { roles } = user;

  const allowedRoutes = roles ? createRoutes(roles) : defaultRoutes;

  const newRoutes = allowedRoutes.map(entity => {
    return routes.filter(route => route.props.path === entity);
  }).flat();

  return newRoutes;
}

/**
 * It takes an array of permissions and returns an array of routes
 * @param permissions - This is the array of permissions that you get from the API.
 * @returns An array of routes that the user has access to.
 */
const createRoutes = (roles) => {
  const { permissions } = roles;

  const mainRoutes = handleMainRoutes(permissions);

  const mappedRoutes = mapRoutes(permissions).concat(mainRoutes);

  return mappedRoutes.filter(route => route);
}

/**
 * It takes an array of permissions and returns an array of routes
 * @param permissions - An array of permissions.
 * @returns An array of arrays of strings.
 */
const mapRoutes = (permissions) => {
  return permissions.map(permission => {
    const page = permission.entity.toLowerCase();

    return permission.scopes.map(scope => {
      let endpoint = '';
      switch (scope) {
        case GET:
          endpoint = SCOPES.VIEW;
          break;
        case POST:
          endpoint = SCOPES.CREATE;
          break;
        case PATCH:
        case PUT:
        case DELETE:
          endpoint = SCOPES.UPDATE;
          break;
        default:
          break;
      }

      if (scope === POST) {
        return `/${page}/${endpoint}`;
      } 

      return `/${page}/${endpoint}/:id`;
    });
  }).flat();
}

/**
 * It takes an array of permissions and returns an array of routes
 * @param permissions - An array of objects that contain the permissions for the user.
 * @returns An array of strings.
 */
const handleMainRoutes = (permissions) => {
  if (!permissions) {
    return null;
  }

  const mainRoutes = permissions.map(permission => `/${permission.entity}`.toLowerCase());

  return mainRoutes.concat(defaultRoutes);
}

// Menu List

/**
 * It filters the menuList to only include the main routes that the user has access to
 * @param roles - the user's roles
 * @param menuList - the menu list that you want to filter
 */
export const handleMenuList = (roles, menuList) => {
  if (!roles) {
    return menuList.filter(menu => menu.name === MENU_ITEMS.DASHBOARD);
  }

  const { permissions } = roles;
  const mainRoutes = handleMainRoutes(permissions);

  return menuList.filter(menu => mainRoutes.includes(menu.path));
} 