import { Box, Chip as MuiChip, Typography } from '@material-ui/core';
import { Cancel as CancelIcon } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';
import useStyles from './styles';

const Chip = (props) => {
  const classes = useStyles();
  const { icon, title, description, onDelete, endComponent, color, fullWidth = false, isForUnsync = false, id, recordId, highlight = true } = props;
  
  const handleDelete = () => {
    onDelete();
  }
  
  return (
    <MuiChip
      id={`${id}${recordId}Chip`}
      icon={icon}
      className={
        clsx(
          {
            [classes.highlightChip]   : highlight,
            [classes.noHighlightChip] : !highlight,
            [classes.fullWidth]       : fullWidth,
            [classes.light]           : color === 'light',
            [classes.neutral]         : color === 'neutral',
            [classes.decline]         : color === 'decline',
            [classes.online]          : color === 'online',
            [classes.white]           : color === 'white',
            [classes.active]          : color === 'active',
            [classes.inactive]        : color === 'inactive',
            [classes.primary]         : color === 'primary'
          }
        )
      }
      size="small"
      color={"primary"}
      label={
        <Box className={clsx({
          [classes.label]: !isForUnsync,
        })}>
          <Box>
            <Typography className={'bold'} variant="caption" display="block">
              <span id={`${id}${recordId}ChipTitle`}>{title}</span>
            </Typography>
            <Typography variant="caption" display="block">
              <span id={`${id}${recordId}ChipDescription`}>{description}</span>
            </Typography>
          </Box>
          <Box>
            { endComponent }
          </Box>
        </Box>
      }
      deleteIcon={
        (onDelete) ?
            <CancelIcon className={classes.deleteIcon}/>
          :
            <></>
      }
      onDelete={handleDelete}
    />
  )
}

export default Chip;