import React from 'react';
import { Box, Paper, Dialog, Typography, Grid, Button } from '@material-ui/core';
import images from "../../../utility/images";
import { Trans } from 'react-i18next';
import useStyles from './styles';

const UserStatusModal = (props) => {
  const { onClose, open, name, handleResponse, status } = props;
  const classes     = useStyles();
  const isEnabled = status

  return (
    <Dialog
      id="statusModal"
      modal="false"
      open={open}
      onClose={onClose}
      aria-labelledby="row-status-dialog"
      aria-describedby="row-status-dialog-table"
      className={classes.dialog}
    >
      {
        <>
          <Paper id="statusModalBodyContainer" className={classes.statusModal}>
            <Box className={classes.dialogImage}>
              <img src={isEnabled ? images.DEACTIVATE_TENANT : images.ACTIVATE_TENANT} alt='status-dialog-img' className={classes.image}/>
            </Box>
            <Box className={classes.dialogTitle}>
              <Typography component={'span'} className={classes.title}>
                <b>
                  <Trans i18nKey={isEnabled ? 'user-status-modal.keyDisableModalStatus' : 'user-status-modal.keyEnableModalStatus'}/>
                </b>
              </Typography>
            </Box>
            <Box className={classes.dialogContent}>
              <Grid container spacing={2} className={`${classes.form} ${classes.space}`}>
                <Grid item md={12}>
                  <Typography component={'span'}>
                    <Trans i18nKey={isEnabled ? 'user-status-modal.keyDisableDescription' : 'user-status-modal.keyEnableDescription'} />
                  </Typography>
                  <Typography component={'span'}>
                    <Trans i18nKey={isEnabled ? 'user-status-modal.keyDisableDescriptionWithName' : 'user-status-modal.keyEnableDescriptionWithName'} values={{item : name}} />
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <Box id="statusModalFooterContainer" className={classes.dialogActions}>
            <Button id="statusModalConfirmButton" color="primary" variant="outlined" className={classes.dialogSubmitButton} onClick={handleResponse}>
                <Trans i18nKey={isEnabled ? 'user-status-modal.keyConfirmDisable' : 'user-status-modal.keyConfirmEnable'} />
            </Button>
            <Button id="statusModalCancelButton" color="primary" variant="contained" className={classes.dialogCancelButton} onClick={onClose}>
                <Trans i18nKey={isEnabled ? 'user-status-modal.keyCancelDisable' : 'user-status-modal.keyCancelEnable'}/>
            </Button>
          </Box>
        </>
      }
    </Dialog>
  );
}

export default UserStatusModal;