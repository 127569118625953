import { Grid, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react'
import { TEXT_FIELD_SKELETON } from '../../../utility/constants';
import useStyles from './styles';
import clsx from 'clsx';

const UserSkeleton = (props) => {
  const { disabled } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.container} elevation={3}>
      <Grid container spacing={2} className={`${classes.paper} ${classes.form}`} elevation={3}>
        <Grid item xs={12} md={6} lg={4}>
          <Skeleton height={TEXT_FIELD_SKELETON} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Skeleton height={TEXT_FIELD_SKELETON} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Skeleton height={TEXT_FIELD_SKELETON} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Skeleton height={TEXT_FIELD_SKELETON} />
        </Grid>
      </Grid>
      <Grid container className={clsx(disabled ? 'hidden' : classes.action)}>
        <Grid item xs={12} className={`${classes.buttonContainer}`}>
          <Skeleton variant="rect" className={classes.button} />
          <Skeleton variant="rect" className={classes.button} />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default UserSkeleton;