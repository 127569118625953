import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  highlightChip: {
    borderRadius  : theme.spacing(0.4),
    height        : 'inherit',
    justifyContent: 'start',
    padding       : theme.spacing(1),

    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
    
    '& .MuiTypography-root': {
      lineHeight: 1.25,
      fontSize  : '15px'
    },
    '& .MuiChip-label': {
      paddingRight: 0,
      width       : '100%',
    },
    '& .MuiChip-deleteIcon': {
      marginLeft  : theme.spacing(0.5),
      marginRight : 0
    },
    '& .MuiChip-icon': {
      marginLeft  : 0,
      marginRight : theme.spacing(0.5),
    },
    '& .MuiChip-labelSmall': {
      paddingLeft: 0,
    },
  },
  noHighlightChip: {
    borderRadius   : theme.spacing(0.4),
    height         : 'inherit',
    justifyContent : 'start',
    padding        : theme.spacing(1),
    
    '& .MuiTypography-root': {
      lineHeight: 1.25,
      fontSize  : '15px'
    },
    '& .MuiChip-label': {
      paddingRight: 0,
      width       : '100%',
    },
    '& .MuiChip-deleteIcon': {
      marginLeft  : theme.spacing(0.5),
      marginRight : 0
    },
    '& .MuiChip-icon': {
      marginLeft  : 0,
      marginRight : theme.spacing(0.5),
    },
    '& .MuiChip-labelSmall': {
      paddingLeft: 0,
    },
  },
  UnsyncChip: {
    paddingBottom : theme.spacing(-0.8),
    paddingLeft   : theme.spacing(-2),
    paddingRight  : theme.spacing(-2),
    paddingTop    : theme.spacing(-0.8),
  },
  fullWidth: {
    width: '100%',
  },
  light: {
    backgroundColor: theme.palette.primary.light,
    color          : theme.palette.primary.main,

    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.light,
    }
  },
  online: {
    backgroundColor: theme.palette.success.main,

    '&:hover, &:focus': {
      backgroundColor: theme.palette.success.main,
    }
  },
  offline: {
    backgroundColor: theme.palette.text.secondary,

    '&:hover, &:focus': {
      backgroundColor: theme.palette.text.secondary,
    }
  },
  neutral: {
    backgroundColor: theme.palette.secondary.light,
    color          : theme.palette.text.primary,

    '&:hover, &:focus': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  decline: {
    backgroundColor : theme.palette.error.light,
    '&:hover, &:focus' : {
      backgroundColor : theme.palette.error.light,
    },
  },
  white: {
    color          : colors.WHITE,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.light,
    }
  },
  active: {
    color           : colors.SUCCESS,
    backgroundColor : colors.TRANSPARENT,
    '&:hover, &:focus': {
      backgroundColor: colors.TRANSPARENT,
    }
  },
  inactive: {
    color           : colors.DISABLED_TEXT,
    backgroundColor : colors.TRANSPARENT,
    '&:hover, &:focus': {
      backgroundColor: colors.TRANSPARENT,
    }
  },
  label: {
    alignContent  : 'center',
    alignItems    : 'center',
    display       : 'flex',
    justifyContent: 'space-between',
    minHeight     : theme.spacing(3),
    fontWeight    : 'normal'
  },
  primary: {
    backgroundColor : theme.palette.primary.light,
    color           : theme.palette.primary.dark,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.light
    }
  },
  deleteIcon: {
    color: colors.SECONDARY,
    '&:hover, &:focus': {
      color: colors.GRAY_DARK
    },
  },
}))