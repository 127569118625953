import { default as axiosInstance } from 'axios';
import cookies from 'js-cookie';
import { BEARER, DEFAULT_PATHS, GET, TOKENS } from '../utility/constants';
import { isTokenExpired } from '../utility/helper';

const axios = axiosInstance.create({
  baseURL: window.API_BASE_PATH
});

axios.interceptors.request.use(
  async (config) => {
    const refreshToken = cookies.get(TOKENS.REFRESH_TOKEN);
    const accessToken  = cookies.get(TOKENS.ACCESS_TOKEN);

    if (refreshToken && isTokenExpired(refreshToken)) {
      localStorage.clear();
      
      cookies.remove(TOKENS.ACCESS_TOKEN);
      cookies.remove(TOKENS.REFRESH_TOKEN);

      window.location.href = DEFAULT_PATHS.EXPIRED;
    }

    if (accessToken && !config.headers.Authorization) {
      config.headers.Authorization = BEARER + accessToken;
    }

    console.log(`Request from ${config.method.toUpperCase()} ${config.url}`, config);

    return config;
  }, (error) => {
    console.log(`Request error from ${error.config.method.toUpperCase()} ${error.config.url}`, error.response);

    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    console.log(`Response from ${response.config.method.toUpperCase()} ${response.config.url}`, response);

    return response;
  }, async (error) => {
    const { config, response } = error;
    
    const method = config.method.toUpperCase();

    const refreshToken = cookies.get(TOKENS.REFRESH_TOKEN);
    const accessToken  = cookies.get(TOKENS.ACCESS_TOKEN);

    if (!refreshToken && !accessToken) {
      window.location.href = '/';
    }
    
    // Any status codes that falls outside the range of 2xx cause this function to trigger

    if (response) {
      // Request new access token if expired
      const { status } = response;

      if (status === 502 && method === GET) {
        window.location.href = DEFAULT_PATHS.UNDER_MAINTENANCE;
      }

      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(`Response error from ${method} ${config.url}`, response);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      if (method === GET) {
        window.location.href = DEFAULT_PATHS.UNDER_MAINTENANCE;
      }

      console.log(`Response error from ${method} ${config.url}`, error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(`Response error from ${method} ${config.url}`, error.message);
    }

    return Promise.reject(error);
  }
);

export default axios;