import { Box, Checkbox, Grid, IconButton, InputAdornment, Link, Table as MUITable, Paper, Switch, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { Add as AddIcon, ArrowDownward as ArrowDownwardIcon, ArrowUpward as ArrowUpwardIcon, CloseOutlined as CloseOutlinedIcon, SearchOutlined as SearchOutlinedIcon } from '@material-ui/icons';
import clsx from 'clsx';
import cookies from 'js-cookie';
import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/authContext';
import { DELETE, DEPLOYMENT_STATUS, PATCH, POST, STATUS, TABLE_HEAD, TENANT, TENANTS, USER, USERS } from '../../utility/constants';
import { handleActionButtonPermission, handlePermissions } from '../../utility/permissions';
import Chip from '../chip';
import Empty from '../empty';
import useStyles from './styles';
import SkeletonRow from './table-skeleton';

export const createColumn = (id, label, isSortable, type, hidden = false, active = false) => {

  return ({
    id,
    label,
    isSortable,
    type,   //Data type: "string", "boolean", "numeric", "date", "datetime", "time", "currency", "component"
    hidden,
    active,
  });
}

const SortHeader = (props) => {
  const classes = useStyles();
  const {active, columnId, getSortOrderDirection} = props;

  switch(getSortOrderDirection(columnId)){
    case 'asc':
      return (
        <ArrowDownwardIcon
          className={clsx({
            [classes.activeIcon]: active,
            [classes.inactiveIcon]: !active,
          })}
        />
      )
    case 'desc':
      return (
        <ArrowUpwardIcon
          className={clsx({
            [classes.activeIcon]: active,
            [classes.inactiveIcon]: !active,
          })}
        />
      )
    default: 
      return (
        <></>
      )
  }
}

const EnhancedTableHead = (props) => {
  const { t } = useTranslation();
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, createSortHandler, tableHeads, label, isEmpty, elementIdModule, hasUpdatePermission, hasDeletePermission } = props;
  
  const getSortOrder = () => {
    return (order === 'desc') ? 'sorted descending' : 'sorted ascending';
  }

  const getSortDirection = (id) => {
    return (orderBy === id) ? order : false;
  }

  const getSortOrderDirection = (id) => {
    return (orderBy === id) ? order : 'asc'
  }

  return (
    <TableHead>
      <TableRow id={`${elementIdModule}TableHead`}>
        <TableCell id={`${elementIdModule}MultiSelectCheckboxCell`} padding="checkbox" className={label === USER || TENANT ? classes.visuallyHidden : ''}>
          <Checkbox
            id={`${elementIdModule}MultiSelectCheckbox`}
            checked={rowCount > 0 && numSelected === rowCount}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            inputProps={{ "aria-label": `select all ${label}` }}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {
          tableHeads.map((column, index) => {
            let elementIdColumnLabel = column.label.charAt(0).toUpperCase() + column.label.slice(1);
            elementIdColumnLabel = elementIdColumnLabel.replace(/\s+/g, '');

            const hasTenantUpdatePermission = hasUpdatePermission ? classes.columnItem : 'hidden';
            
            return (
              <TableCell
                id={`${elementIdModule}${elementIdColumnLabel}Column`}
                key={'col-' + index}
                padding={'normal'}
                sortDirection={getSortDirection(column.id)}
                className={clsx({
                  [classes.cellStyle]: true,
                  [classes.visuallyHidden]: column.hidden,
                })}
                aria-label={column.label}
              >
                {
                  column.isSortable ?
                    <TableSortLabel
                      id={`${elementIdModule}${elementIdColumnLabel}Label`}
                      direction={getSortOrderDirection(column.id)}
                      onClick={(event) => createSortHandler(column.id)(event)}
                      hideSortIcon={true}
                    >
                      <Box className={classes.columnHeader}>
                        <Box className={classes.columnItem}>
                          <Trans i18nKey={`table-component.${column.label}`}/>
                        </Box>
                        <Box>
                          <SortHeader
                            columnId={column.id}
                            active={column.active}
                            getSortOrderDirection={getSortOrderDirection}
                          />
                        </Box>
                      </Box>
                      {
                        orderBy === column.id &&
                        <span className={classes.visuallyHidden}>
                          {getSortOrder()}
                        </span>
                      }
                    </TableSortLabel>
                  :
                    <Box id={`${elementIdModule}${elementIdColumnLabel}Label`} className={clsx((column.label !== TABLE_HEAD.STATUS) ? classes.columnItem : hasTenantUpdatePermission)}>
                      <Trans i18nKey={`table-component.${column.label}`}/>
                    </Box>
                }

              </TableCell>
            )
          })
        }
        {
          (hasDeletePermission || hasUpdatePermission) &&
            <TableCell aria-label="actions" id={`${elementIdModule}ActionsColumn`} className={clsx(classes.columnItem, {[classes.moveCellHead]: isEmpty}, {[classes.visuallyHidden] : label === TENANT}, classes.actionHeader)}>
              {t('table-component.actions')}
            </TableCell>
        }
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { numSelected, handleCreate, confirmDelete, handleSearch, handleClearSearch, keyword, label, module, handleViewAll, elementIdModule, hasCreatePermission, hasDeletePermission } = props;

  const currentLanguageCode   = cookies.get('i18next') || 'en';

  const generateMessage = (text) => {
    return (currentLanguageCode === 'de') ? `${t(label)} ${t(text).toLowerCase()}` : `${t(text)} ${t(label)}`
  }

  const createHoverMessage = generateMessage('table-component.createButton');

  return (
    numSelected > 0 ?
      <Toolbar className={clsx(classes.root, classes.toolView, {[classes.highlight] : numSelected > 0})}>
        <Typography className={`${classes.title} ${classes.selectionToolbar}`} color="inherit" variant="subtitle1" component="div">
          {numSelected} {t('table-component.selected')}
        </Typography>
        <Tooltip 
          title={t('table-component.removeButton')}
          className={clsx(!hasDeletePermission && 'hidden')}
        >
          <IconButton id={`${elementIdModule}MultiDeleteButton`} aria-label={t('table-component.removeButton')} onClick={(event) => confirmDelete(event, {}, 'multiple')}>
            <div className={classes.deleteIcon}></div>
          </IconButton>
        </Tooltip>
      </Toolbar>
    :
      <Toolbar className={clsx(classes.root, classes.toolView, {[classes.highlight] : numSelected > 0})}>
        <Box className={classes.searchFieldContainer}>
          <TextField
            className={classes.searchField}
            id={`${elementIdModule}SearchBar`}
            onChange={event => handleSearch(event)}
            placeholder={t('table-component.search')}
            value={keyword}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon color="secondary" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    id={`${elementIdModule}ClearSearchBar`}
                    edge="end"
                    onClick={handleClearSearch}
                    size="small"
                    className={
                      clsx({
                        [classes.visuallyHidden]: (keyword === null || keyword === "")
                      })
                    }
                  >
                    <CloseOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Link href="#" onClick={handleViewAll} className={'hidden'}>{`View All ${module}`}</Link>
        </Box>
        <Grid container className={clsx(hasCreatePermission ? classes.iconButtonContainer : 'hidden')}>
          <Tooltip title={`${createHoverMessage}`} className={clsx({
              [classes.actionTools]   : true,
            })}
          >
            <IconButton disableRipple id={`${elementIdModule}CreateButton`} aria-label={`${createHoverMessage}`} onClick={handleCreate}>
              <AddIcon className={classes.iconButton} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Toolbar>
  );
};

const RowComponent = (props) => {
  const classes = useStyles();
  const { t }   = useTranslation();
  const { module, row, keys, ctr, elementIdModule, elementIdColumn, elementIdRecordId, handleSwitchStatus, hasUpdatePermission } = props;

  switch (module) {
    case USERS :   
      const userChips = [row[keys[ctr]]];

      return (
        <TableCell
          id={`${elementIdModule}${elementIdRecordId}${elementIdColumn}Cell`}
          key={'table-cell-' + ctr}
        >
          <Box className={classes.chipset}>
            {
              userChips.map((value, index) =>
                value.name ? 
                  <Box key= {'table-chip-' + index} className={classes.chipsLayout}>
                    <Chip
                      id={`${elementIdModule}${elementIdRecordId}${elementIdColumn}`}
                      recordId={index}
                      title={value?.translationKey ? t(value.translationKey) : value.name}
                      color='primary'
                    />
                  </Box>
                  : <div key= {'table-chip-' + index}>-</div>
              )
            }
          </Box>
        </TableCell>
      )
    case TENANTS :
      const tenantChips = [row[keys[ctr]]];
      
      return (
        <TableCell
          id={`${elementIdModule}${elementIdRecordId}${elementIdColumn}Cell`}
          key={'table-cell-' + ctr}
          className={clsx(!hasUpdatePermission && 'hidden')}
        >
          {
            tenantChips[0] === STATUS.ACTIVE || tenantChips[0] === STATUS.DEACTIVATED ?
              <Box className={classes.chipset}>
                <Switch
                  id={`${elementIdModule}${elementIdRecordId}ActiveSwitch`}
                  key={'row-switch-' + ctr}
                  checked={row[keys[ctr]] === STATUS.ACTIVE ? true : false}
                  onClick={(event) => handleSwitchStatus(event, row)}
                  className={row[keys[5]] !== DEPLOYMENT_STATUS.DEPLOYMENT_FINISHED ? classes.disabledSwitch : classes.switch}
                  disabled={Boolean(row[keys[5]] !== DEPLOYMENT_STATUS.DEPLOYMENT_FINISHED)}
                />
                <Typography className={row[keys[5]] !== DEPLOYMENT_STATUS.DEPLOYMENT_FINISHED ? classes.disabledSwitchText : (row[keys[ctr]] === STATUS.ACTIVE ? classes.activeSwitch : classes.deactivatedSwitch)}>
                  {
                    row[keys[ctr]] === STATUS.ACTIVE ? t('tenant-page.active') : t('tenant-page.deactivated')
                  }
                </Typography>
              </Box>
            :
              tenantChips.map((value, index) =>
                value.length ? 
                  <Box key= {'table-chip-'+ index} className={classes.chipsLayout}>
                    <Typography className={classes.withoutChipLabel}>
                      {
                        value.map(item => item.name).join(', ')
                      }
                    </Typography>
                  </Box>
                : 
                  <div key= {'table-chip-'+ index}>-</div>
              )
          }
        </TableCell>
      )        
    default: 
      return (
        <></>
      )
  }
}

const EnhancedCell = (props) => {
  const { row, keys, ctr, columns, elementIdModule, elementIdColumn, elementIdRecordId } = props;
  const classes = useStyles();

  return (
    columns[ctr].hidden ?
      null
    :
      <span className={clsx(row.isUserFederated && !row.status ? classes.cellContentDisabled : classes.cellContent)} id={`${elementIdModule}${elementIdRecordId}${elementIdColumn}`}>{row[keys[ctr]]}</span>
  )
}

const EnhancedCellData = (props) => {
  const classes = useStyles();
  const {columns, ctr, keys, row, viewKey, handleView, elementIdModule, elementIdColumn, elementIdRecordId, hasCreatePermission } = props;
  const enableSingleAction = (columns[ctr].label === 'actions')

  return (
    <TableCell
      id={`${elementIdModule}${elementIdRecordId}${elementIdColumn}Cell`}
      className={clsx({
        [classes.actionsRow]    : enableSingleAction,
        [classes.newActions]    : enableSingleAction,
        [classes.nowrapbox]     : enableSingleAction,
        [classes.visuallyHidden]: columns[ctr].hidden,
      })}
      key={'table-cell-' + ctr}
    >
      {
        keys[ctr] === viewKey ?
          <Link className={clsx(row.isUserFederated && !row.status ? classes.cellContentLinkDisabled : classes.cellContentLink)} id={`${elementIdModule}${elementIdRecordId}${elementIdColumn}`} href="#" onClick={(event) => handleView(event, row)} disabled={true}>
            {row[keys[ctr]]}
          </Link>
        :
          <EnhancedCell
            columns={columns}
            ctr={ctr}
            keys={keys}
            row={row}
            elementIdModule={elementIdModule}
            elementIdColumn={elementIdColumn}
            elementIdRecordId={elementIdRecordId}
            hasCreatePermission={hasCreatePermission}
          />
      }
    </TableCell>
  )
}

const EnhancedTableRow = (props) => {
  const { t }   = useTranslation();
  const classes = useStyles();
  const { data, isSelected, columns, label, handleClick, handleDoorMode, handleChangeStatus, handleSwitchStatus, handleUpdate, module, confirmDelete, viewKey, handleView, handleSwitchAction, handleApproveController, handleDeclineController, disabled, elementIdModule, hasUpdatePermission, hasDeletePermission, hasCreatePermission, userId, roles } = props;
  
  const currentLanguageCode   = cookies.get('i18next') || 'en';

  const generateMessage = (text) => {
    return (currentLanguageCode === 'de') ? `${t(label)} ${t(text).toLowerCase()}` : `${t(text)} ${t(label)}`
  }

  const updateHoverMessage    = generateMessage('table-component.update');
  const removeHoverMessage    = generateMessage('table-component.removeButton');
  
  return (
    data.map((row, index) => {
      const keys           = Object.keys(row);
      const isItemSelected = isSelected(row);
      const labelId        = `enhanced-table-checkbox-${index}`;
      
      const elementIdRecordId = row[keys[1]];
      const showUpdateButton = handleActionButtonPermission(module, roles, row?.systemRoles, hasUpdatePermission);
      const showDeleteButton = handleActionButtonPermission(module, roles, row?.systemRoles, hasDeletePermission) && Boolean(row.id !== userId) && !row?.isUserFederated;

      return (
        <TableRow
          id={`${elementIdModule}${elementIdRecordId}`}
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={index + '-tr-' + row[keys[0]]}
          selected={isItemSelected}
          onClick={(event) => {
            handleClick(event, row)
          }}
        >
          <TableCell id={`${elementIdModule}${elementIdRecordId}CheckboxCell`} padding="checkbox" key={index + '-tc-' + row[keys[0]]} className={label === USER || TENANT ? classes.visuallyHidden : ''}>
            <Checkbox
              id={`${elementIdModule}${elementIdRecordId}Checkbox`}
              checked={isItemSelected}
              color="primary"
              inputProps={{ "aria-labelledby": labelId }}
            />
          </TableCell>
          {
            keys.map((_, ctr) => {
              const elementIdColumn = keys[ctr].charAt(0).toUpperCase() + keys[ctr].slice(1);
              
              return (
                columns[ctr].type === 'component' ?
                    <RowComponent
                      ctr={ctr}
                      handleSwitchStatus={handleSwitchStatus}
                      handleView={handleView}
                      key={'tr-component-data-' + ctr}
                      keys={keys}
                      module={module}
                      row={row}
                      columns={columns}
                      elementIdModule={elementIdModule}
                      elementIdColumn={elementIdColumn}
                      elementIdRecordId={elementIdRecordId}
                      hasUpdatePermission={hasUpdatePermission}
                    />
                  :
                    <EnhancedCellData
                      columns={columns}
                      ctr={ctr}
                      handleChangeStatus={handleChangeStatus}
                      handleSwitchAction={handleSwitchAction}
                      handleDoorMode={handleDoorMode}
                      handleView={handleView}
                      handleApproveController={handleApproveController}
                      handleDeclineController={handleDeclineController}
                      key={'tr-enhanced-cell-data-' + ctr}
                      keys={keys}
                      module={module}
                      row={row}
                      viewKey={viewKey}
                      elementIdModule={elementIdModule}
                      elementIdColumn={elementIdColumn}
                      elementIdRecordId={elementIdRecordId}
                      hasUpdatePermission={hasUpdatePermission}
                      hasCreatePermission={hasCreatePermission}
                      hasDeletePermission={hasDeletePermission}
                    />
              )
            })
          }
          {
            label !== TENANT ? 
              <TableCell id={`${elementIdModule}${elementIdRecordId}ActionsCell`} key={index + '-row-action-default-' + row[keys[0]]}
                className={clsx(classes.actionsRow, classes.nowrapbox)}
                >
                {
                  showUpdateButton &&
                    <Tooltip title={updateHoverMessage}>
                      <IconButton id={`${elementIdModule}${elementIdRecordId}UpdateButton`} disabled={disabled} aria-label={`Update ` + label} onClick={event => handleUpdate(event, row)}>
                        <div className={classes.editIcon}></div>
                      </IconButton>
                    </Tooltip>
                }
                {
                  showDeleteButton && 
                    <Tooltip title={removeHoverMessage}>
                      <IconButton id={`${elementIdModule}${elementIdRecordId}RemoveButton`} disabled={disabled} aria-label={`Remove ` + label} onClick={(event) => confirmDelete(event, row, 'single')}>
                        <div className={classes.deleteIcon}></div>
                      </IconButton>
                    </Tooltip>
                }
              </TableCell> 
              : <></>
          }
        </TableRow>
      )
    })
  )
}

const EnhancedTableData = (props) => {
  const { data, columns, label, isSelected, handleDoorMode, handleClick, handleChangeStatus, handleUpdateDevice, handleSwitchStatus, handleUpdate, confirmDelete, handleView, viewKey, module, handleSwitchAction, handleApproveController, handleDeclineController, disabled, elementIdModule, hasCreatePermission, hasUpdatePermission, hasDeletePermission, userId, roles } = props;

  return (
    <TableBody>
      <EnhancedTableRow
        disabled={disabled}
        columns={columns}
        confirmDelete={confirmDelete}
        data={data}
        handleChangeStatus={handleChangeStatus}
        handleClick={handleClick}
        handleDoorMode={handleDoorMode}
        handleSwitchStatus={handleSwitchStatus}
        handleUpdate={handleUpdate}
        handleUpdateDevice={handleUpdateDevice}
        handleSwitchAction={handleSwitchAction}
        handleView={handleView}
        handleApproveController={handleApproveController}
        handleDeclineController={handleDeclineController}
        isSelected={isSelected}
        label={label}
        module={module}
        viewKey={viewKey}
        elementIdModule={elementIdModule}
        hasUpdatePermission={hasUpdatePermission}
        hasCreatePermission={hasCreatePermission}
        hasDeletePermission={hasDeletePermission}
        userId={userId}
        roles={roles}
      />
    </TableBody>
  )
}

const EnhancedTable = (props) => {
  const { columns, confirmDelete, data, handleDoorMode, handleClick, handleChangeStatus, handleUpdateDevice, handleRequestSort, createSortHandler, tableHeads, handleSelectAllClick, handleSwitchStatus, handleUpdate, handleView, isSelected, label, module, order, orderBy, selected, viewKey, keyword, handleSwitchAction, handleApproveController, handleDeclineController, disabled, elementIdModule, hasDeletePermission, hasCreatePermission, hasUpdatePermission, userId, roles } = props;
  const classes = useStyles();

  return (
    data.length > 0 ?
      <MUITable
        className={classes.table}
        aria-labelledby="tableTitle"
        aria-label="enhanced table"
      >
        <EnhancedTableHead
          classes={classes}
          label={label}
          module={module}
          numSelected={selected.length}
          tableHeads={tableHeads}
          createSortHandler={createSortHandler}
          onSelectAllClick={handleSelectAllClick}
          order={order}
          orderBy={orderBy}
          rowCount={data.length}
          skeletonModule={module}
          elementIdModule={elementIdModule}
          hasUpdatePermission={hasUpdatePermission}
          hasDeletePermission={hasDeletePermission}
        />

          <EnhancedTableData
            disabled={disabled}
            columns={columns}
            confirmDelete={confirmDelete}
            data={data}
            handleChangeStatus={handleChangeStatus}
            handleClick={handleClick}
            handleDoorMode={handleDoorMode}
            handleRequestSort={handleRequestSort}
            handleSelectAllClick={handleSelectAllClick}
            handleSwitchStatus={handleSwitchStatus}
            handleUpdate={handleUpdate}
            handleUpdateDevice={handleUpdateDevice}
            handleSwitchAction={handleSwitchAction}
            handleView={handleView}
            handleApproveController={handleApproveController}
            handleDeclineController={handleDeclineController}
            isSelected={isSelected}
            label={label}
            module={module}
            order={order}
            orderBy={orderBy}
            selected={selected}
            viewKey={viewKey}
            elementIdModule={elementIdModule}
            hasUpdatePermission={hasUpdatePermission}
            hasCreatePermission={hasCreatePermission}
            hasDeletePermission={hasDeletePermission}
            userId={userId}
            roles={roles}
          />
      </MUITable>
    :
      <TableContainer>
        <MUITable
          aria-label="enhanced table"
          aria-labelledby="tableTitle"
          className={classes.table}
        >
          <EnhancedTableHead
            classes={classes}
            label={label}
            numSelected={selected.length}
            createSortHandler={createSortHandler}
            tableHeads={tableHeads}
            onSelectAllClick={handleSelectAllClick}
            order={order}
            orderBy={orderBy}
            rowCount={data.length}
            skeletonModule={module}
            isEmpty={Boolean(!data.length)}
            elementIdModule={elementIdModule}
            hasUpdatePermission={hasUpdatePermission}
            hasDeletePermission={hasDeletePermission}
          />
        </MUITable>
        <Box id="emptyTable" className={classes.nodata}>
          <Empty
            keyword={keyword}
            module={module}
          />
        </Box>
      </TableContainer>
  )
}

const Table = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { columns, page, rowsPerPage, data, isLoading, label, module, onChangeDoorMode, onChangePage, onChangeStatus, onRowsPerPageChange, onCreate, onDelete, onMultipleDelete, onClearSearch, onSearch, onSort, onUpdate, onUpdateDevice, onView, onSwitch, keyword, totalItems, order, orderBy, viewKey, onFilter, onMarkAsRead, onMarkAllAsRead, allChecked, handleUpload, csv, onOpenModal, onApproveController, onDeclineController, onOpenImportModal, onOpenCreateModal, disabled, onViewAll, handleExport } = props;
  const [selected, setSelected]       = useState([]);
  const [tableHeads, setTableHeads]   = useState(columns);
  const columnKeys = Object.keys(columns);

  const { state } = useContext(AuthContext);
  const { user }  = state;

  const { roles, userId } = user;

  const hasUpdatePermission = handlePermissions(module, PATCH, roles);
  const hasDeletePermission = handlePermissions(module, DELETE, roles);
  const hasCreatePermission = handlePermissions(module, POST, roles);

  let elementIdModule = module.charAt(0).toLowerCase() + module.slice(1);
  elementIdModule = elementIdModule.replace(/\s+/g, '');

  const handleCreate = () => {
    onCreate();
  }

  const handleUpdate = (event, item) => {
    event.stopPropagation();
    onUpdate(item);
  }

  const confirmDelete = (event, item, type) => {
    event.stopPropagation();
    event.preventDefault();
    if (type === 'single') onDelete(item);
    else onMultipleDelete(selected);
    setSelected([]);
  }

  const handleFilter = () => {
    onFilter();
    setSelected([]);
  }

  const handleSearch = (event) => {
    event.stopPropagation();
    onSearch(event.target.value);
  }

  const handleClearSearch = () => {
    onClearSearch();
  }

  const handleRequestSort = (event, newOrderBy) => {
    event.stopPropagation();
    const newOrder = order === 'asc' ? 'desc' : 'asc';
    onSort(newOrderBy, newOrder);
    setSelected([]);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
    const index = columns.findIndex(obj => obj.id === property)
    columns.map((column) => {
      column.active = false
      return null
    })
    columns[index].active = true

    setTableHeads(columns);
  };

  const handleSelectAllClick = (event) => {
    // commented out for now for multiple delete
    // event.stopPropagation();
    // if (event.target.checked) {
    //   const newSelecteds = data.map((n) => n);
    //   setSelected(newSelecteds);
    //   return;
    // }
    // setSelected([]);
  };

  const handleClick = (event, id) => {
    // commented out for now for multiple delete
    // const selectedIndex = selected.indexOf(id);
    // let newSelected     = [];

    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, id);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1),
    //   );
    // }
    // setSelected(newSelected);
  };

  const handleChangeStatus = (event, newValue) => {
    event.stopPropagation();
    onChangeStatus(newValue);
    setSelected([]);
  }

  const handleChangePage = (event, newPage) => {
    onChangePage(newPage);
    setSelected([]);
  };

  const handleRowsPerPageChange = (event) => {
    onRowsPerPageChange(event.target.value);
  }

  const handleSwitchStatus = (event, row) => {
    event.stopPropagation();
    onSwitch(row.name, row.status, row.id);
  }

  const handleView = (event, row) => {
    event.preventDefault();
    event.stopPropagation();

    onView(row);
  }

  const handleApproveController = (event, row) => {
    event.preventDefault();
    event.stopPropagation();

    onApproveController(row);
  }

  const handleDeclineController = (event, row) => {
    event.preventDefault();
    event.stopPropagation();

    onDeclineController(row);
  }

  const handleUpdateDevice = (event, row) => {
    event.stopPropagation();
    onUpdateDevice(row);
    setSelected([]);
  }

  const handleDoorMode = (event, row) => {
    event.stopPropagation();
    onChangeDoorMode(event.target.value, row);
    setSelected([]);
  }

  const handleSwitchAction = (event, row) => {
    event.stopPropagation();
    onMarkAsRead(row, event.target);
    setSelected([]);
  }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleChangeFile  = () => {
    handleUpload();
  }

  const openCsvModal = () => {
    onOpenModal();
  }

  const handleViewAll = (event) => {
    event.preventDefault();
    onViewAll();
  }
  
  const openImportModal = () => {
    onOpenImportModal();
  }

  const openCreateModal = () => {
    onOpenCreateModal();
  }

  const countElement = (count, to) => {
    count = to === 0 ? 0 : count;
    return count !== -1 ? count : `more than ${to}`;
  }

  const countTableItems = (from, to) => {
    from = to === 0 ? 0 : from;
    return `${from}–${to} ${t('table-component.range')}`;
  }

  return (
    <Paper className={clsx(classes.paper, classes.cutBar)} elevation={3}>
      <EnhancedTableToolbar
        confirmDelete={confirmDelete}
        handleClearSearch={handleClearSearch}
        handleCreate={handleCreate}
        handleFilter={handleFilter}
        handleSearch={handleSearch}
        handleMarkAllAsRead={onMarkAllAsRead}
        allChecked={allChecked}
        keyword={keyword}
        label={label}
        module={module}
        numSelected={selected.length}
        handleChangeFile={handleChangeFile}
        csv={csv}
        openCsvModal={openCsvModal}
        handleViewAll={handleViewAll}
        openImportModal={openImportModal}
        openCreateModal={openCreateModal}
        disabled={disabled}
        handleExport={handleExport}
        elementIdModule={elementIdModule}
        hasCreatePermission={hasCreatePermission}
        hasDeletePermission={hasDeletePermission}
      />
      {
        isLoading ?
          <TableContainer className={classes.toolView}>
            <MUITable
              aria-label="enhanced table"
              aria-labelledby="tableTitle"
              className={classes.table}
            >
              <EnhancedTableHead
                classes={classes}
                label={label}
                numSelected={selected.length}
                tableHeads={tableHeads}
                createSortHandler={createSortHandler}
                onSelectAllClick={handleSelectAllClick}
                order={order}
                orderBy={orderBy}
                rowCount={data.length}
                skeletonModule={module}
                hasUpdatePermission={hasUpdatePermission}
                hasDeletePermission={hasDeletePermission}
              />
            <SkeletonRow columnKeys={columnKeys} columns={columns} ROWS_PER_PAGE={rowsPerPage} module={module} />
            </MUITable>
          </TableContainer>
        :
          <TableContainer className={classes.toolView}>
            <EnhancedTable
              disabled={disabled}
              columns={columns}
              confirmDelete={confirmDelete}
              data={data}
              handleChangeStatus={handleChangeStatus}
              handleClick={handleClick}
              handleDoorMode={handleDoorMode}
              handleRequestSort={handleRequestSort}
              tableHeads={tableHeads}
              createSortHandler={createSortHandler}
              handleSelectAllClick={handleSelectAllClick}
              handleSwitchStatus={handleSwitchStatus}
              handleUpdate={handleUpdate}
              handleUpdateDevice={handleUpdateDevice}
              handleSwitchAction={handleSwitchAction}
              handleView={handleView}
              handleApproveController={handleApproveController}
              handleDeclineController={handleDeclineController}
              isSelected={isSelected}
              keyword={keyword}
              label={label}
              module={module}
              order={order}
              orderBy={orderBy}
              selected={selected}
              viewKey={viewKey}
              elementIdModule={elementIdModule}
              hasUpdatePermission={hasUpdatePermission}
              hasCreatePermission={hasCreatePermission}
              hasDeletePermission={hasDeletePermission}
              userId={userId}
              roles={roles}
            />
          </TableContainer>
      }
      <TablePagination
        id={`${elementIdModule}Pagination`}
        className={classes.pagination}
        component="div"
        labelRowsPerPage={t('table-component.rowPerPage')}
        labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) { return `${countTableItems(from, to)} ${countElement(count, to)}`; }}
        count={totalItems}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleRowsPerPageChange}
        page={page - 1}
        rowsPerPage={rowsPerPage > 100 ? 100 : rowsPerPage}
        rowsPerPageOptions={[10, 15, 25, 50, 75, 100]}
      />
    </Paper>
  );
}

export default Table;
