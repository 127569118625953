import colors from '../../../theme/colors'
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  form: {
    height        : '35vh',
    overflowX     : 'hidden',
    overflowY     : 'auto',
    paddingLeft   : theme.spacing(3.5),
    paddingRight  : theme.spacing(3.5)
  },
  paper: {
    height        : '100%',
    paddingBottom : theme.spacing(3.5),
    paddingTop    : theme.spacing(3.5)
  },
  filter: {
    border      : '1px solid grey',
    borderRadius: '4px',
    height      : '55px',
    width       : '12vw',
    '& .MuiSelect-select.MuiSelect-select': {
      paddingLeft: '15px'
    }
  },
  logText: {
    display       : 'inline-block',
    paddingBottom : theme.spacing(4),
    paddingRight  : theme.spacing(2),
  },
  logValuesText: {
    display       : 'inline-block',
    color         : colors.SECONDARY_TEXT,
    fontSize      : theme.spacing(2),
    paddingBottom : theme.spacing(4),
  },
  logUpdateText: {
    fontSize      : theme.spacing(2),
    paddingBottom : theme.spacing(1),
    paddingRight  : theme.spacing(2)
  },
  selectedItem: {
    backgroundColor: `${colors.PALE_BLUE} !important`,
    width          : '12vw'
  },
  auditLogs: {
    display : 'flex',
    position: 'relative'
  },
  selected: {
    '& .MuiFormControl-root': {
      position    : 'sticky',
      right       : 0,
      top         : 0
    }
  },
  emptyContainer: {
    alignContent   : 'center',
    alignItems     : 'center',
    display        : 'flex',
    flexDirection  : 'column',
    height         : '100%',
    justifyContent : 'center'
  },
}));