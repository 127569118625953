import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  createUpdatePaper: {
    marginTop: theme.spacing(1),

    '& .MuiTextField-root': {
      marginTop: theme.spacing(0),
    },
    '& .Mui-disabled': {
      pointerEvents: 'none',
    },
    '& .MuiFormHelperText-contained': {
      marginLeft : theme.spacing(0)
    },
  },
  viewPaper: {
    marginTop: theme.spacing(1),

    '& .MuiTextField-root': {
      marginTop: theme.spacing(0),
    },
    '& .Mui-disabled': {
      pointerEvents: 'none',
    },
    '& .MuiInputBase-input': {
      color: colors.BLACK,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor : 'transparent !important'
    },
    '& .MuiOutlinedInput-notchedOutline:hover': {
      borderColor : 'transparent !important'
    },
    '& .MuiInputLabel-root': {
      fontWeight : 'bold'
    },
  },
  mainForm: {
    paddingBottom: 0,
    padding      : theme.spacing(2),
  },
  createUpdateForm: {
    paddingBottom: theme.spacing(1),
    paddingLeft  : theme.spacing(4),
    paddingRight : theme.spacing(4),
    paddingTop   : theme.spacing(3),
  },
  action: {
    backgroundColor: theme.palette.grey[100],
    padding        : theme.spacing(2),
    textAlign      : 'right',

    '& .MuiButton-root:first-of-type': {
      marginRight: theme.spacing(2)
    }
  },
  mainContainer: {
    display       : 'flex',
    flexDirection : 'column',
    maxWidth      : '100%',
    borderLeft    : '1px solid #707070',
    borderBottom  : '1px solid #707070',
    borderRight   : '1px solid #707070',
    borderRadius  : theme.spacing(0.5),
    margin        : theme.spacing(4),
    marginBottom  : theme.spacing(2),
    marginTop     : theme.spacing(1),
    backgroundClip: 'padding-box',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor : 'transparent !important'
    },
    '& .MuiOutlinedInput-notchedOutline:hover': {
      borderColor : 'transparent !important'
    },
  },
  createMainContainer: {
    display       : 'flex',
    flexDirection : 'column',
    maxWidth      : '100%',
    borderLeft    : '1px solid #707070',
    borderBottom  : '1px solid #707070',
    borderRight   : '1px solid #707070',
    borderRadius  : theme.spacing(0.5),
    margin        : theme.spacing(4),
    marginBottom  : theme.spacing(2),
    marginTop     : theme.spacing(1),
  },
  viewChildrenContainer: {
    padding : theme.spacing(2),
  },
  createUpdateChildrenContainer: {
    padding     : theme.spacing(1),
    paddingLeft : theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  header: {
    display       : 'flex',
    flexDirection : 'row',
    width         : '100% !important'
  },
  headerBorderBefore: {
    borderTop           : '1px solid #707070',
    width               : theme.spacing(2),
    borderTopLeftRadius : theme.spacing(0.5)
  },
  headerTitle: {
    display       : 'flex',
    flexDirection : 'row',
    flexWrap      : 'nowrap',
    alignItems    : 'center',
    gap           : theme.spacing(0.5),
    width         : 'fit-content',
    height        : theme.spacing(4.5),
    margin        : '-1em 0.5em 0em 0.5em',
    overflow      : 'hidden',
    textOverflow  : 'ellipsis',
    fontSize      : theme.spacing(1.8),
    fontWeight    : 600,
    color         : colors.SECONDARY
  },
  headerBorderAfter: {
    borderTop            : '1px solid #707070',
    width                : theme.spacing(2),
    flexGrow             : 2,
    borderTopRightRadius : theme.spacing(0.5),
  },
  visuallyHidden: {
    display: 'none'
  },
  switch: {
    width  : 52,
    height : 22,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding        : 0,
      margin         : 1.29,
      '&.Mui-checked': {
        color     : theme.palette.common.white,
        marginLeft: theme.spacing(-0.34),
        transform : 'translateX(34px)',
        '& + .MuiSwitch-track': {
          backgroundColor: colors.ACTIVE,
          border         : 0,
          opacity        : 1,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width    : 19,
      height   : 19,
    },
    '& .MuiSwitch-track': {
      borderRadius   : 26 / 2,
      backgroundColor: theme.palette.grey[500],
      opacity        : 1,
      transition     : theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  },
  switchBox: {
    display       : 'flex',
    flexDirection : 'row',
    justifyContent: 'flex-end',
    padding       : theme.spacing(2),
    paddingLeft   : theme.spacing(3),
    paddingRight  : theme.spacing(3),
  },
  activeSwitch: {
    marginLeft : theme.spacing(2),
    color      : colors.ACTIVE,
  },
  deactivatedSwitch: {
    marginLeft : theme.spacing(2),
    color      : colors.SECONDARY
  },
  chipsLayout : {
    padding     : theme.spacing(1),
    paddingLeft : theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  disabledSwitch : {
    width  : 52,
    height : 22,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding        : 0,
      margin         : 1.29,
      '&.Mui-checked': {
        color     : theme.palette.grey,
        marginLeft: theme.spacing(-0.34),
        transform : 'translateX(34px)',
        '& + .MuiSwitch-track': {
          backgroundColor: '#B7B7B8',
          border         : 1,
          opacity        : 1,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width    : 19,
      height   : 19,
    },
    '& .MuiSwitch-track': {
      borderRadius   : 26 / 2,
      backgroundColor: theme.palette.grey[500],
      opacity        : 1,
      transition     : theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
    '& .MuiIconButton-label': {
      color: '#D4D4D5'
    }
  },
  disabledSwitchText : {
    marginLeft   : theme.spacing(2),
    color        : colors.SECONDARY
  },
  textArea: {
    lineHeight: 1.25
  },
  title: {
    fontSize: "20px",
    marginLeft: "32px",
    color: colors.SECONDARY,
    fontWeight: "bold"
  },
  divider: {
    margin: '10px',
    borderBottom: '1px dashed #707070',
  },
  tabView : {
    marginTop: '50px',
  },
  gridIndex : {
    zIndex : '100',
  },
  billingPaper : {
    margin: "32px",
    backgroundColor: 'white',
    marginBottom: '50px',
  },
  warningText: {
    fontSize: "16px",
    marginLeft: "32px",
    color: "#DA5F54",
  }
}));