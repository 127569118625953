import i18n from "i18next";
import cookies from "js-cookie";
import { createContext, useCallback, useEffect, useReducer, useState } from "react";
import { useAuth } from "react-oidc-context";
import Loading from "../components/loading";
import AuthReducer, { LOGIN, initialState } from '../reducer/authReducer';
import api from "../service/api";
import { request } from "../service/requests";
import { BEARER, KEYCLOAK_HEADERS, KEYCLOAK_LOCALE, OIDC_CONSTANT, POST, SESSION_ITEM, SUPPORTED_LANGUAGES, TOKENS } from "../utility/constants";
import { setLocalStorageItem } from "../utility/helper";

export const AuthContext = createContext();

const capitalizeFirstLetter = (name) => {
  const splitNames = name.toLowerCase().split('_');

  const newName = splitNames.map(splitName => {
    return splitName.charAt(0).toUpperCase() + splitName.slice(1);
  });

  return newName.join(' ');
}

const convertToPermissionsData = (data) => {
  return data.map(item => {
    const { rsname, scopes }  = item;
    const name                = rsname.replace('res:', '');
    const capitalize          = capitalizeFirstLetter(name);

    return {
      entity  : `${capitalize}s`.toLowerCase(),
      scopes: scopes ?? []
    }
  });
}

const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);
  const { isAuthenticated, isLoading, user, activeNavigator, error, 
    signinRedirect, signoutSilent, removeUser } = useAuth();

  const [loading, setLoading] = useState(false);

  const signOut = useCallback(() => {
    cookies.remove(TOKENS.KEYCLOAK_IDENTITY_LEGACY);
    cookies.remove(TOKENS.ACCESS_TOKEN);
    cookies.remove(TOKENS.REFRESH_TOKEN);
    
    sessionStorage.removeItem(SESSION_ITEM.SESSION);
    signoutSilent();
    removeUser();
  }, [removeUser, signoutSilent]);

  const loadUser = useCallback(async () => {
    const { profile, access_token: accessToken, refresh_token: refreshToken } = user;
    const { email, given_name: firstName, family_name: lastName, sub: userId } = profile;
    
    const locale = (profile.locale) ? profile.locale : SUPPORTED_LANGUAGES.ENGLISH;
    i18n.changeLanguage(locale);
    cookies.set(KEYCLOAK_LOCALE, locale);
    try {
      const permissions = await getUserPermissions(accessToken);

      const userData = {
        accessToken : accessToken,
        refreshToken: refreshToken,
        user: {
          email    : email,
          firstName: firstName,
          lastName : lastName,
          roles    : { name: '', permissions: permissions },
          userId   : userId
        }
      }
      
      dispatch({ type: LOGIN, payload: userData });
      cookies.set(TOKENS.ACCESS_TOKEN, accessToken);
      cookies.set(TOKENS.REFRESH_TOKEN, refreshToken);
      setLocalStorageItem('user', userData);
      sessionStorage.removeItem(SESSION_ITEM.SESSION);
    } catch {
      signOut();
    } finally {
      setLoading(false)
    }
  }, [user, signOut]);

  useEffect(() => {
    if (isAuthenticated && user) {
      setLoading(true);
      loadUser();
    }
  }, [user, isAuthenticated, loadUser]);

  const getUserPermissions = async (token) => {
    const params = {
      grant_type   : KEYCLOAK_HEADERS.GRANT_TYPE,
      audience     : KEYCLOAK_HEADERS.ACCOUNT_MANAGEMENT_SERVER_AUDIDENCE,
      response_mode: KEYCLOAK_HEADERS.RESPONSE_MODE,
    }

    const response = await request({
      url   : api.KEYCLOAK_TOKEN,
      method: POST,
      data  : new URLSearchParams(params),
      headers : {
        'Content-Type' : 'application/x-www-form-urlencoded',
        'Authorization': BEARER + token
      },
    });

    const { data } = response;
    
    return convertToPermissionsData(data);
  }

  if (loading || isLoading || 
    activeNavigator === OIDC_CONSTANT.SIGNIN_REDIRECT|| 
    activeNavigator === OIDC_CONSTANT.SIGNOUT_SILENT) {
    return <Loading isOpen/>;
  }

  if (error || !isAuthenticated) {
    localStorage.clear(); 
    cookies.remove(TOKENS.ACCESS_TOKEN);
    cookies.remove(TOKENS.REFRESH_TOKEN);
    cookies.remove(TOKENS.KEYCLOAK_IDENTITY_LEGACY);
    signinRedirect();
    signoutSilent();
  }

  return (
    <AuthContext.Provider value={{ state, dispatch, signOut }}>
      {
        children
      }
    </AuthContext.Provider>
  )
}

export default AuthContextProvider;