import { Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import DeleteModal from '../../components/delete-modal';
import Table, { createColumn } from '../../components/table';
import Title from '../../components/title';
import { deleteUser, getAllUsers, getUserCount, getUserRoles } from '../../service/user-api';
import { ALL, API_REQUEST_ERROR_MESSAGE, ASCENDING, SINGLE, TOASTER_SEVERITY, USERS } from '../../utility/constants';
import { parseIntegerParam } from '../../utility/helper';
import useStyles from './styles';

const columns = [
  createColumn("name", "name", false, "string", false, true),
  createColumn("id", "ID", false, "numeric", true),
  createColumn("emailAddress", "emailAddress", false, "string", false, true),
  createColumn("systemRoles", "role", false, "component"),
  createColumn("company", "company", false, "string", false, true),
  createColumn("isUserFederated", "isUserFederated", true, "boolean", true),
  createColumn("status", "status", true, "boolean", true),
];

const Users = (props) => {
  const { t }                = useTranslation();
  const classes              = useStyles();
  const history              = useHistory();
  const { search, pathname } = history.location;

  const params      = new URLSearchParams(search);
  const query       = params.get('keyword') ? params.get('keyword') : '';
  const pageSize    = params.get('size') ? parseIntegerParam(params.get('size'), 10) : 10;
  const pageNumber  = params.get('page') ? parseIntegerParam(params.get('page'), 1) : 1;
  const pageType    = params.get('users') ? params.get('users') : ALL.toLowerCase();
  const totalSize   = pageSize > 100 ? 100 : pageSize;

  const { showToaster }                       = props;
  const [users, setUsers]                     = useState([]);
  const [isLoading, setIsLoading]             = useState(false);
  const [keyword, setKeyword]                 = useState(query);
  const [order, setOrder]                     = useState(ASCENDING);
  const [orderBy, setOrderBy]                 = useState(columns[0].name);
  const [page, setPage]                       = useState(pageNumber);
  const [remove, setRemove]                   = useState(false);
  const [title, setTitle]                     = useState('');
  const [listType, setListType]               = useState(pageType);
  const [rowsPerPage, setRowsPerPage]         = useState(totalSize);
  const [totalUsers, setTotalUsers]           = useState(0);
  const [forDelete, setForDelete]             = useState([]);
  const [forDeleteName, setForDeleteName]     = useState([]);
  const [showModal, setShowModal]             = useState(false);
  const [type , setType]                      = useState(SINGLE);

  useEffect(() => {
    let delayDebounce;

    params.set('users', listType);
    params.set('page', page);
    params.set('size', rowsPerPage);

    if (keyword) {
      params.set('keyword', keyword);
      remove ? setIsLoading(false) : setIsLoading(true);
      delayDebounce = setTimeout(() => {
        getData();
      }, 1000);
    } else {
      params.delete('keyword');
      getData();
    }

    const location = {
      pathname: pathname,
      search  : params.toString()
    };

    history.replace(location);

    return () => {
      delayDebounce && clearTimeout(delayDebounce);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, order, orderBy, keyword, listType]);
  
  useEffect(() => {
    if (remove) {
      getUsers();
    }
    setRemove(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remove])

  const getData = () => {
    if (listType === ALL.toLowerCase()){
      setTitle(USERS);
      getUsers();
    } else {
      setListType(ALL.toLowerCase());
    }
  }

  const getUsers = async () => {
    setTitle(USERS);
    setIsLoading(true);

    const params = {
      search: keyword,
      first: (page - 1) * rowsPerPage,
      max: rowsPerPage
    }

    try {
      const usersResponse = await getAllUsers(params);
      const usersCount = await getUserCount();

      const users = await Promise.all(usersResponse.data.map(async item => {
        const { firstName, lastName, email, id, attributes, enabled, federationLink } = item;
    
        const role = await getUserRoles(id);
    
        return {
          name            : `${lastName}, ${firstName}`,
          id              : id,
          email           : email,
          systemRoles     : role,
          company         : attributes?.company ? attributes.company[0] : '',
          status          : enabled,
          isUserFederated : !!federationLink
        }
      }));

      setUsers(users);
      setTotalUsers(usersCount.data);
    } catch (userError) {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), TOASTER_SEVERITY.ERROR);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSearch = (value) => {
    setPage(1);
    setKeyword(value);
  }

  const handleClearSearch = () => {
    setPage(1);
    setKeyword('');
  }

  const handleSort = (newOrderBy, newOrder) => {
    setPage(1);
    setOrderBy(newOrderBy);
    setOrder(newOrder);
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setPage(1);
    setRowsPerPage(newRowsPerPage);
  }
  
  const handleChangePage = (newPage) => {
    if (isLoading) {
      return;
    }
    setPage(newPage + 1);
  }

  const handleCreate = () => {
    history.push("/users/create");
  }

  const handleUpdate = (value) => {
    history.push(`/users/update/${value.id}`);
  }

  const handleView = (row) => {
    history.push(`/users/view/${row.id}`);
  }

  
  const handleDelete = async (value) => {
    handleOpenDeleteModal(value, value.name, SINGLE);
  }

  const singleDelete = async (value) => {
    try { 
      await deleteUser(value.id);
      setIsLoading(false);
      showToaster(t('success'), t('users-page.hasBeenDeleted', { name : value.name }), TOASTER_SEVERITY.SUCCESS);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), TOASTER_SEVERITY.ERROR);
    } finally {
      setRemove(true);
    }
  } 
  
  const handleOpenDeleteModal = (value, name, paramType) => {
    setForDelete(value);
    setForDeleteName(name);
    setType(paramType)
    setShowModal(true);
  }

  const handleCloseDeleteModal = () => {
    setShowModal(false);
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <DeleteModal
        forDeleteName={forDeleteName}
        initialValues={forDelete}
        module={USERS}
        onClose={handleCloseDeleteModal}
        open={showModal}
        showToaster={showToaster}
        singleDelete={singleDelete}
        type={type}
      />
      <Title title={t(title)} listType={listType}/>
      <Table
        columns={columns}
        data={users}
        isLoading={isLoading}
        keyword={keyword}
        label={"user"}
        module={USERS}
        onChangePage={handleChangePage}
        onClearSearch={handleClearSearch}
        onDelete={handleDelete}
        onRowsPerPageChange={handleRowsPerPageChange}
        onCreate={handleCreate}
        onSearch={handleSearch}
        onSort={handleSort}
        onUpdate={handleUpdate}
        onView={handleView}
        orderBy={orderBy}
        order={order}
        page={page}
        rowsPerPage={rowsPerPage}
        totalItems={totalUsers}
        viewKey={"name"}
        listType={listType}
      />
    </Container>
  )
}

export default Users;