import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dialogActions: {
    alignItems     : 'center',
    backgroundColor: '#F1F1F1',
    display        : 'flex',
    width          : '100%',
    justifyContent : 'space-around'
  },
  dialogSubmitButton: {
    display    : 'flex',
    float      : 'left',
    margin     : theme.spacing(2),
    marginRight: 0,
    padding    : theme.spacing(2),
    width      : '50%'
  },
  dialogCancelButton: {
    display    : 'flex',
    float      : 'left',
    margin     : theme.spacing(2),
    padding    : theme.spacing(2),
    width      : '50%'
  }
}));