const images = {
  ACTIVATE_TENANT      : `${window.STORAGE_ACCOUNT}/activate-tenant-modal.svg`,
  CHAMELEON            : `${window.STORAGE_ACCOUNT}/loading.gif`,
  CLOCK                : `${window.STORAGE_ACCOUNT}/clock.svg`,
  COMING_SOON          : `${window.STORAGE_ACCOUNT}/coming-soon.svg`,
  DEACTIVATE_TENANT    : `${window.STORAGE_ACCOUNT}/deactivate-tenant-modal.svg`,
  DELETE_ICON          : `${window.STORAGE_ACCOUNT}/delete-icon.svg`,
  DELETE_ICON_HOVER    : `${window.STORAGE_ACCOUNT}/delete-icon-hover.svg`,
  DELETE_ICON_DISABLED : `${window.STORAGE_ACCOUNT}/delete-icon-disabled.svg`,
  DELETE_MODAL         : `${window.STORAGE_ACCOUNT}/delete-modal.svg`,
  EXPORT_BILLING       : `${window.STORAGE_ACCOUNT}/export-billing.svg`,
  EDIT_ICON            : `${window.STORAGE_ACCOUNT}/edit-icon.svg`,
  EDIT_ICON_HOVER      : `${window.STORAGE_ACCOUNT}/edit-icon-hover.svg`,
  EDIT_ICON_DISABLED   : `${window.STORAGE_ACCOUNT}/edit-icon-disabled.svg`,
  ERROR_ICON           : `${window.STORAGE_ACCOUNT}/x-icon.svg`,
  ERROR403             : `${window.STORAGE_ACCOUNT}/not-authorised.svg`,
  ERROR404             : `${window.STORAGE_ACCOUNT}/not-found.svg`,
  ERROR500             : `${window.STORAGE_ACCOUNT}/internal-server-error.svg`,
  ERROR503             : `${window.STORAGE_ACCOUNT}/under-maintenance.svg`,
  FORBIDDEN            : `${window.STORAGE_ACCOUNT}/forbidden.svg`,
  NO_PERMISSION_SVG    : `${window.STORAGE_ACCOUNT}/no-permission.svg`,
  NOTHING_TO_DISPLAY   : `${window.STORAGE_ACCOUNT}/nothing-to-display.svg`,
  POLYGON              : `${window.STORAGE_ACCOUNT}/login-polygon.svg`,
  RETRY                : `${window.STORAGE_ACCOUNT}/retry.svg`,
  SESAMSEC_LOGO        : `${window.STORAGE_ACCOUNT}/sesamsec-logo.png`,
  SESSION_TIMEOUT      : `${window.STORAGE_ACCOUNT}/session-timeout.svg`,
  STATUS_MODAL         : `${window.STORAGE_ACCOUNT}/tenant-status-modal.svg`,
  TENANTS_ICON         : `${window.STORAGE_ACCOUNT}/tenants-icon.svg`,
  UNSAVED_CHANGES_SVG  : `${window.STORAGE_ACCOUNT}/unsaved-changes.svg`,
  USERS_ICON           : `${window.STORAGE_ACCOUNT}/users-icon.svg`,
  USERS_ICON_HOVER     : `${window.STORAGE_ACCOUNT}/users-icon-hover.svg`,
  VISIBILITY_ON        : `${window.STORAGE_ACCOUNT}/visibility-on.svg`,
  VISIBILITY_OFF       : `${window.STORAGE_ACCOUNT}/visibility-off.svg`,
  CHECK_MARK           : `${window.STORAGE_ACCOUNT}/check-mark.svg`,
  CHECK_MARK_WHITE     : `${window.STORAGE_ACCOUNT}/check-mark-white.svg`,
  PERSON_WITH_QUESTION_MARK    : `${window.STORAGE_ACCOUNT}/person-with-question-mark.svg`
}

export default images;