import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

const drawerWidth            = 310;
const drawerWidthCompactView = 600;

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    // NOTE:
    //  don't remove,
    //  this is used to override the css of expansion panel.
    '& .MuiPaper-elevation1':{
      boxShadow: 'none'
    },
    '& .MuiAccordion-root.Mui-expanded, & .MuiAccordionSummary-content.Mui-expanded':{
      margin       : 'auto',
      paddingBottom: 0,
      transition: 'none'
    },
    '& .MuiAccordionSummary-root.Mui-expanded,':{
      paddingBottom: 0,
      paddingTop   : 0,
      position     : 'initial',
      minHeight    : 0,
    },
    '& .MuiAccordion-root': {
      position: 'static',
    },
    '& .MuiAccordion-root:before':{
      transition: 'none',
    },
    '& MuiButtonBase-root.MuiAccordionSummary-root':{
      transition: 'none',
    }
  },
  drawer: {
    display         : 'flex',
    height          : `calc(100% - 60px)`,
    justifyContent  : 'space-between',
    overflow        : 'visible',
    top             : '60px',
    transition      : theme.transitions.create('width', {
      duration  : theme.transitions.duration.enteringScreen,
      easing    : theme.transitions.easing.sharp,
    }),
    whiteSpace      : 'nowrap',
    zIndex          : '19',
    '& .MuiListItemText-root': {
      visibility: 'visible',
      transition: "all .15s",
    },
  },
  drawerPaper: {
    position        : 'relative',
    width           : drawerWidth,
  },
  drawerPaperHide: {
    position        : 'relative',
    width           : '50px',
    '& .MuiListItemText-root': {
      visibility: 'hidden',
      transition: "all .15s",
    },
  },
  drawerPaperCompactView: {
    position        : 'absolute',
    width           : drawerWidthCompactView,
  },
  drawerPaperHideCompactView: {
    position        : 'absolute',
    width           : '50px',
    '& .MuiListItemText-root': {
      visibility: 'hidden',
      transition: "all .15s",
    },
  },
  partition: {
    alignItems      : 'center',
    backgroundColor : colors.SECONDARY_GRAY_LIGHT,
    color           : colors.SECONDARY,
    display         : 'flex',
    fontWeight      : "bold",
    height          : theme.spacing(3)
  },
  list: {
    paddingTop: theme.spacing(1.875),
    '& .MuiListItem-root': {
      height: '60px',
      padding: theme.spacing(2),
    },
    '& .MuiListItemText-root': {
      margin: theme.spacing(0)
    },
    '& .MuiListItemIcon-root': {
      alignItems : 'center',
      color      : theme.palette.primary.dark,
      minWidth   : theme.spacing(5)
    },
    '& .Mui-selected': {
      backgroundColor : theme.palette.primary.light,
      color           : theme.palette.primary.dark,
      '& .MuiListItemIcon-root': {
        color : theme.palette.primary.dark,
      },
      '& .MuiTypography-root': {
        fontWeight : 'bolder',
      },
    },
    '& .Mui-selected:hover, & .MuiListItem-button:hover': {
      backgroundColor : theme.palette.primary.dark,
      color           : theme.palette.common.white,
      '& .MuiListItemIcon-root': {
        color : theme.palette.common.white,
      }
    },
  },
  menuIconHovered: {
    filter: 'grayscale(100%) brightness(500%)'
  },
  listItem: {
    '& .Mui-selected': {
      zIndex : '-1',
    },
  },
  chevronIcon: {
    position: 'absolute',
    right   : '-26px',
    top     : '2px',
    zIndex  : '1',
    '& .MuiIconButton-label': {
      background   : colors.SECONDARY_GRAY,
      border       : '2px solid ' + colors.PRIMARY,
      borderRadius : '50%',
      color        : colors.PRIMARY,
      fontSize     : theme.spacing(1.375),
      mixBlendMode : 'difference',
      padding      : theme.spacing(0.75),
      paddingTop   : theme.spacing(0.77375),
      paddingBottom: theme.spacing(0.77325),
      width        : theme.spacing(3.42),
    },
    '&:hover': {
      '& .MuiIconButton-label': {
        background: colors.PRIMARY,
        color     : colors.SECONDARY_GRAY,
      }
    },
  },
  chevronIconRight: {
    '& .MuiIconButton-label': {
      paddingLeft : theme.spacing(0.925)
    },
  },
  chevronIconLeft: {
    '& .MuiIconButton-label': {
      paddingRight : theme.spacing(0.925)
    },
  },
  content: {
    height    : '100vh',
    overflow  : 'auto',
    width     : '100%',
  },
  contentCompactView: {
    height    : '100vh',
    overflow  : 'auto',
    position  : 'absolute',
    left      : '50px',
    width     : `calc(100% - 50px)`
  },
  appBarSpacer: theme.mixins.toolbar,
  toolbar: {
    alignItems      : 'center',
    justifyContent  : 'space-between',
    marginLeft      : theme.spacing(-1.5),
  }
}));