import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  boxBorder : {
    border        : '1px solid #707070',
    borderRadius  : theme.spacing(0.5),
  },
  error : {
    margin        : theme.spacing(1),
    marginTop     : 0,
    paddingBottom : theme.spacing(1.5),
    paddingLeft   : theme.spacing(2),
  },
  timeline : {
    alignItems    : 'center',
    display       : 'flex',
    height        : 'auto',
    opacity       : 1,
    paddingBottom : theme.spacing(3),
    paddingTop    : theme.spacing(4),
    width         : '100%',
    zIndex        : 2,
    '& .MuiPaper-root': {
      backgroundColor: colors.TRANSPARENT,
    },
    '& .MuiTimelineDot-root': {
      marginTop: 0
    }
  },
  deploymentPaper: {
    zIndex        : '-1',
    width         : '100%',
    padding       : theme.spacing(3)
  },
  textSuccessStyle : {
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      color     : colors.ACTIVE,
      fontWeight: 'bold',
      marginTop : `${theme.spacing(2.75)}px !important`,
    }
  },
  textInProgressStyle : {
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      color     : colors.PRIMARY_DARK,
      fontWeight: 'bold',
      marginTop : `${theme.spacing(2.75)}px !important`,
    }
  },
  textPendingStyle : {
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      color     : colors.GRAY,
      fontWeight: 'bold',
      marginTop : `${theme.spacing(3)}px !important`
    }
  },
  textErrorStyle : {
    color     : colors.ERROR,
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      color     : colors.ERROR,
      fontWeight: 'bold',
      marginTop : `${theme.spacing(2.75)}px !important`
    },
    '& .MuiLink-root' : {
      cursor: 'pointer'
    }
  },
  iconSuccessStyle : {
    backgroundColor : `${colors.ACTIVE} !important`,
    height          : '30px',
    margin          : '-2.5px',
    width           : '30px',
    zIndex          : 2,
    position        : 'relative',
    '& .MuiSvgIcon-root': {
      height  : '0.475em',
      fontSize: '2.85em',
      width   : '0.475em'
    }
  },
  iconSuccessSVG : {
    height  : '13px',
    position: 'absolute',
    left    : '25%',
    top     : '25%'
  }, 
  iconInProgressStyle : {
    backgroundColor : `${colors.PRIMARY_DARK} !important`,
    margin          : `-1px`,
    zIndex          : 2
  },
  iconInProgressHoverStyle: {
    backgroundColor : `${colors.PRIMARY_LIGHT} !important`,
    margin          : `-1px`,
    zIndex          : 2
  },
  iconPendingStyle : {
    backgroundColor : `${colors.WHITE} !important`,
    border          : `3px solid ${colors.GRAY} !important`,
    borderRadius    : '100%',
    height          : '18px',
    margin          : '5px !important',
    padding         : 2,
    width           : '18px',
    zIndex          : 2,
  },
  iconErrorStyle : {
    backgroundColor : `${colors.ERROR} !important`,
    height          : '30px',
    margin          : '-2.5px',
    width           : '30px',
    zIndex          : 2,
    position        : 'relative',
    "&:hover"       : {
      cursor : 'pointer',
    },
    '& .MuiSvgIcon-root': {
      height  : '0.475em',
      fontSize: '2.785em',
      width   : '0.475em'
    }
  },
  iconErrorSVG : {
    height  : '15px',
    position: 'absolute',
    left    : '19%',
    top     : '21%'
  },
  visuallyHidden: {
    visibility: 'hidden'
  },
  stepper: {
    padding: 0,
    '& .MuiStepConnector-lineHorizontal': {
      borderColor   : colors.SECONDARY_LIGHT,
      borderTopWidth: '4px'
    },
  },
  stepperContainer: {
    width: '60%',
  },
  connector: {
    top   : 13,
    left  : '-50%',
    right : '50%',
    zIndex: 0
  },
  inProgressIcon: {
    color: colors.WHITE
  }
}));