import { Box, Button, Dialog, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { Trans } from 'react-i18next';
import images from '../../../utility/images';
import useStyles from './styles';

const ExportModal = (props) => {
  const { onClose, open, handleExport } = props;
  const classes     = useStyles();

  return (
    <>
      <Dialog
        id="exportModal"
        modal="false"
        open={open}
        onClose={onClose}
        className={classes.dialog}
      >
        {
          <>
            <Paper id="exportModalBodyContainer" className={classes.exportModal}>
              <Box className={classes.dialogImage}>
                <img src={images.EXPORT_BILLING} alt='download-report-img' className={classes.image}/>
              </Box>
              <Box className={classes.dialogTitle}>
                <Typography component={'span'} className={classes.title}>
                  <b>
                    <Trans i18nKey={'export-modal.downloadData'}/>
                  </b>
                </Typography>
              </Box>
              <Box className={classes.dialogContent}>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Typography component={'span'}>
                      <Trans i18nKey={'export-modal.areYouSureToDownload'}/>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Box id="exportModalFooterContainer" className={classes.dialogActions}>
              <Button id="exportModalConfirmButton" color="primary" variant="outlined" className={classes.dialogCancelButton} onClick={onClose}>
                  <Trans i18nKey={'export-modal.noDontDownload'}/>
              </Button>
              <Button id="exportModalCancelButton" color="primary" variant="contained" className={classes.dialogSubmitButton} onClick={handleExport}>
                  <Trans i18nKey={'export-modal.yesDownload'}/>
              </Button>
            </Box>
          </>
        }
      </Dialog>
    </>
  )
}

export default ExportModal;