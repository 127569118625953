import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    paddingTop    : theme.spacing(2),
    paddingBottom : theme.spacing(2),
  },
  paper: {
    '& .MuiTextField-root': {
      marginTop: theme.spacing(0)
    },
    '& input#myAccountFirstName.Mui-disabled':{
      color: "black"
    },
    '& input#myAccountLastName.Mui-disabled':{
      color: "black"
    },
    '& input#myAccountEmail.Mui-disabled':{
      color: "black"
    },
  },
  form: {
    padding       : theme.spacing(1),
    paddingLeft   : theme.spacing(2),
    paddingRight  : theme.spacing(2),
    textAlign     : 'right'
  },
  avatar: {
    height    : '100px',
    width     : '100px',
    fontSize  : '50px',
    marginTop : theme.spacing(5)
  },
  formTitle: {
    marginTop       : theme.spacing(3),
    marginBottom    : theme.spacing(3),
    backgroundColor : theme.palette.grey[100],
    width           : '100%',
    paddingLeft     : theme.spacing(2),
    paddingTop      : theme.spacing(1),
    paddingBottom   : theme.spacing(1),
  },
  version: {
    marginTop: theme.spacing(2)
  },
  avatarContainer: {
    alignContent  : 'center',
    alignItems    : 'center',
    display       : 'flex',
    flexDirection : 'column',
    flexWrap      : 'wrap'
  },
}));