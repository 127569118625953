import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  statusModal: {
    padding    : theme.spacing(2),
    paddingTop : theme.spacing(4),
    width      : '100%'
  },
  dialogImage: {
    display        : 'flex',
    justifyContent : 'center',
    paddingBottom  : theme.spacing(1)
  },
  image: {
    width: '160px'
  },
  dialogTitle: {
    fontWeight: 'bold',
    textAlign : 'center',
  },
  title: {
    color     : colors.SECONDARY,
    fontSize  : '20px'
  },
  dialogContent: {
    paddingTop  : theme.spacing(2),
    paddingLeft : theme.spacing(6),
    paddingRight: theme.spacing(6),
    textAlign   : 'left',
    '& p': {
      paddingBottom : theme.spacing(2)
    }
  },
  dialogActions: {
    alignItems     : 'center',
    backgroundColor: '#F1F1F1',
    display        : 'flex',
    width          : '100%',
    justifyContent : 'space-around',
  },
  dialogSubmitButton: {
    display    : 'flex',
    float      : 'left',
    margin     : theme.spacing(2),
    marginRight: 0,
    padding    : theme.spacing(2),
    width      : '50%'
  },
  dialogCancelButton: {
    display : 'flex',
    float   : 'right',
    margin  : theme.spacing(2),
    padding : theme.spacing(2),
    width   : '50%'
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '550px'
    }
  }
}));