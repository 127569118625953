import React from "react";
import { Container, Box, Modal, Backdrop, Fade } from "@material-ui/core";
import useStyles from "./styles";
import images from "../../utility/images";

const Loading = (props) => {
  const classes    = useStyles();
  const { isOpen } = props;
  
  return (
    <Box data-testid='loading'>
      <Modal
        open={isOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className={classes.modalLoading}
      >
        <Fade in={isOpen}>
          <Container className={classes.modalContent}>
            <Box className={classes.chameleonContainer}>
              <img src={images.CHAMELEON} alt="chameleon-gif" className={classes.chameleon}/>
            </Box>
          </Container>
        </Fade>
      </Modal>
    </Box>
  );
}

export default Loading;