import { Avatar, Box, Container, Grid, Paper, TextField, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Title from '../../components/title';
import Version from '../../components/version';
import { AuthContext } from '../../context/authContext';
import useStyles from './styles';

const Content = (props) => {
  const { user } = props;
  const classes = useStyles();
  const { t }   = useTranslation();

  return (
    <Paper className={classes.paper} elevation={3}>
      <Grid container alignItems="center" direction="row">
        <Grid
          item
          xs={12} sm={12} md={12} lg={12}
          className={classes.avatarContainer}
        >
          <Avatar className={classes.avatar}>{user?.firstName?.slice(0, 1).toUpperCase()}</Avatar>
          <Box className={classes.version}>
            <Version />
          </Box>
        </Grid>
      </Grid>
      <Grid className={classes.formTitle} item xs={12}>
        <Typography className={'bold'} color="secondary">{t('my-account-page.personalInformation')}</Typography>
      </Grid>
      <Grid container spacing={2} className={classes.form}>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <TextField
            id="myAccountFirstName"
            label={t('my-account-page.firstName')}
            name="firstName"
            autoCapitalize="words"
            value={user?.firstName}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <TextField
            id="myAccountLastName"
            label={t('my-account-page.lastName')}
            name="lastName"
            autoCapitalize="words"
            value={user?.lastName}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <TextField
            id="myAccountEmail"
            label={t('my-account-page.emailAddress')}
            name="email"
            value={user?.email}
            fullWidth
            disabled
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

const MyAccount = (props) => {
  const classes = useStyles();

  const { state } = useContext(AuthContext);
  const { user }  = state;
  const { t }     = useTranslation();

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Title title={t('my-account-page.myAccount')} />
      <Grid container>
        <Grid item xs={10} sm={10} md={10} lg={12}>
          <Content user={user} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default MyAccount