import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    paddingBottom : theme.spacing(4),
    paddingLeft   : theme.spacing(10),
    paddingRight  : theme.spacing(10),
    paddingTop    : theme.spacing(15)
  },
  form: {
    padding       : theme.spacing(1),
    paddingLeft   : theme.spacing(2),
    paddingRight  : theme.spacing(2)
  },
  list: {
    paddingLeft: theme.spacing(2),
    '& .MuiList-padding': {
      paddingTop: 0
    }
  },
  paragraph: {
    paddingLeft   : theme.spacing(2)
  },
  paper: {
    marginBottom: theme.spacing(2),
    marginTop   : theme.spacing(4),
    padding     : theme.spacing(9),
    width       : '100%'
  },
  title: {
    color        : colors.SECONDARY_TEXT,
    fontWeight   : 'bold',
    paddingBottom: theme.spacing(8),
    textAlign    : 'center',
    '& .MuiTypography-root': {
      lineHeight: -1
    }
  },
  subtitle: {
    color      : colors.SECONDARY_TEXT,
    fontWeight : 'bold',
    paddingTop : theme.spacing(1)
  },
  header: {
    color      : colors.SECONDARY_TEXT,
    fontSize   : '20px',
    fontWeight : 'bold',
    paddingTop : '5px'
  },
  paragTitle: {
    color      : colors.SECONDARY_TEXT,
    fontWeight : 'bold',
    '& .MuiGrid-root.MuiGrid-item': {
      marginTop : theme.spacing(2),
      padding   : '0'
    }
  },
  flexForm: {
    display: 'flex'
  },
  space: {
    paddingTop: theme.spacing(5)
  },
  appBar: {
    background  : theme.palette.common.white,
    transition  : theme.transitions.create(['width', 'margin'], {
      duration  : theme.transitions.duration.leavingScreen,
      easing    : theme.transitions.easing.sharp
    })
  },
  link: {
    color: colors.PRIMARY
  },
  logo: {
    padding : theme.spacing(0),
    width   : theme.spacing(26)
  },
  bulletSize: {
    height: theme.spacing(2),
    color : colors.PRIMARY
  },
  marginBullet: {
    marginTop: theme.spacing(1)
  },
  flexStart: {
    display   : 'flex',
    alignItems: 'flex-start'
  }
}));