import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/authContext';
import { UPDATE_USER } from '../../reducer/authReducer';
import { getUserRoles } from "../../service/user-api";
import { API_REQUEST_ERROR_MESSAGE, DEFAULT_PATHS, MENU_ITEMS, PAGE_TYPE, TOASTER_SEVERITY } from '../../utility/constants';
import images from "../../utility/images";
import { handleMenuList } from '../../utility/permissions';
import SessionHandler from '../session-handler';
import AppBar from './app-bar';
import useStyles from './styles';


export const MENUS = [
  // {
  //   name: MENU_ITEMS.DASHBOARD,
  //   path: `/dashboard`,
  //   icon: <FontAwesomeIcon icon={faThLarge} size="lg" />
  // },
  {
    name: MENU_ITEMS.USERS,
    path: `/users`,
    icon: images.USERS_ICON
  },
  {
    name: MENU_ITEMS.TENANTS,
    path: `/tenants`,
    icon: images.TENANTS_ICON
  },
  // {
  //   name: MENU_ITEMS.SETTINGS,
  //   path: `/settings`,
  //   icon: <FontAwesomeIcon icon={faCog} size="lg" />
  // },
];

const MenuPartition = (props) => {
  const classes = useStyles();

  const { name } = props;
  
  if (name !== MENU_ITEMS.DASHBOARD) {
    return null;
  }

  return <Box className={classes.partition} />;
}

const MainLayout = (props) => {
  const { children, showLoading, showToaster } = props;
  const classes = useStyles();
  const history = useHistory();
  const { t }   = useTranslation();

  const [closeSidebar, setCloseSidebar]       = useState(false);
  const [isCompactView, setIsCompactView]     = useState(false);
  const [showTooltip, setShowTooltip]         = useState(false);
  const [tooltipTitle, setTooltipTitle]       = useState('');
  const [isIconRight, setIsIconRight]         = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const { state, signOut, dispatch } = useContext(AuthContext);

  const { user }    = state;

  const menuList = handleMenuList(user?.roles, MENUS);
  
  const checkPathname = (page) => {
    return history.location.pathname.includes(page);
  }

  const isListPage = !checkPathname(PAGE_TYPE.VIEW) && !checkPathname(PAGE_TYPE.UPDATE) && !checkPathname(PAGE_TYPE.CREATE);

  const [windowDimension, setWindowDimension] = useState({
    windowWidth : window.innerWidth
  });

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimension);

    const compactView = windowDimension.windowWidth < 1280;
    
    setIsCompactView(compactView);

    return() => {
      window.removeEventListener('resize', updateWindowDimension);
    }

  }, [windowDimension]);

  const updateWindowDimension = () => {
    setWindowDimension({
      windowWidth  : window.innerWidth
    })
  }

  const isErrorPage = useCallback((path) => {
    return history.location.pathname.includes(path);
  }, [history]);

  const updateUser = useCallback(async () => {
    const isInternalServerError   = isErrorPage(DEFAULT_PATHS.INTERNAL_SERVER_ERROR);
    const isUnderMaintenanceError = isErrorPage(DEFAULT_PATHS.UNDER_MAINTENANCE);
    
    const isError = isInternalServerError || isUnderMaintenanceError;

    if (isError) {
      return;
    }

    showLoading(true);

    try {
      const roleResponse = await getUserRoles(user.userId);
      const payload = {
        user: {
          email    : user.email,
          firstName: user.firstName,
          lastName : user.lastName,
          roles    : { 
            name       : roleResponse.name,
            permissions: user.roles.permissions
          },
          userId   : user.userId
        }
      }
      dispatch({ type: UPDATE_USER, payload: payload });
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), TOASTER_SEVERITY.ERROR);
    }
    
    showLoading(false);
  }, [isErrorPage, showLoading, dispatch, showToaster, t, user]);

  const handleSidebar = () => {
    setIsIconRight(prev => !prev);
    setCloseSidebar(!isIconRight);
    setShowTooltip(false);
    setTooltipTitle('');
  }

  const getPathIndex = useCallback(() => {
    return menuList.findIndex(menu => history.location.pathname.includes(menu.path));
  }, [history, menuList]);

  const [selectedIndex, setSelectedIndex] = useState(getPathIndex());
  
  useEffect(() => {
    setSelectedIndex(getPathIndex());
  }, [selectedIndex, history.location.pathname, getPathIndex]);

  const handleListItemClick = (index) => {
    const pathname = menuList[index].path;
    
    const locationState = {
      prevLocation: window.location.href
    }

    const location = {
      pathname  : pathname,
      state     : locationState
    }

    history.push(location);
    setSelectedIndex(index);
  };

  const onMouseEnterSidebar = () => {
    setCloseSidebar(false);
  }

  const onMouseLeaveSidebar = () => {
    if (isIconRight) {
      setCloseSidebar(true);
    }
  }

  const onMouseOverTooltip = () => {
    setShowTooltip(true);
    const title = closeSidebar ? t('main-layout.showMenu') : t('main-layout.hideMenu');
    setTooltipTitle(title);
  }

  const onMouseLeaveTooltip = () => {
    setShowTooltip(false);
  }

  const handleListItemHover = (index) => {
    setHoveredIndex(index);
  };

  const handleListItemLeave = () => {
    setHoveredIndex(null);
  };

  const myAccount = () => {
    history.push('/my-account');
  }

  useEffect(() => {
    if (!user || user?.accessToken) {
      return;
    }

    if (!user.roles.name) {
      updateUser();
    }
  }, [updateUser, user]);
  
  return (
    <div className={classes.root}>
      <SessionHandler signOut={signOut}>
        <AppBar signOut={signOut} myAccount={myAccount}/>
        <Drawer
          id="mainLayoutMainMenu"
          variant="permanent"
          onMouseLeave={onMouseLeaveSidebar}
          classes={{
            paper: clsx({
              [classes.drawerPaperHide]            : closeSidebar  && !isCompactView,
              [classes.drawerPaper]                : !closeSidebar && !isCompactView,
              [classes.drawerPaperHideCompactView] : closeSidebar  && isCompactView,
              [classes.drawerPaperCompactView]     : !closeSidebar && isCompactView,
            }, 'MuiPaper-elevation4', classes.drawer),
          }}
          >
          <Tooltip
            open={showTooltip}
            title={tooltipTitle}
            arrow
            placement="bottom-end"
            enterNextDelay={2000}
            disableHoverListener
            disableFocusListener
            elevation={1}
          >
            <IconButton 
              id="chevronIconButton"
              disableRipple
              aria-label="chevronIcon"
              className={clsx(classes.chevronIcon, isIconRight ? classes.chevronIconRight : classes.chevronIconLeft)}
              onClick={handleSidebar}
              disableFocusRipple
            >
              <FontAwesomeIcon 
                onMouseOver={onMouseOverTooltip}
                onMouseLeave={onMouseLeaveTooltip}
                icon={isIconRight ? faChevronRight : faChevronLeft}
              />
            </IconButton>
          </Tooltip>
          <List className={clsx(classes.list, isListPage && classes.listItem)}>
            {
              menuList.map((menu, index) => {
                return (
                  <Box
                    key={'box-panel-' + index}
                    onMouseEnter={onMouseEnterSidebar}
                  >
                    <Tooltip title={closeSidebar ? t(menu.name):''}>
                      <ListItem
                        id={menu.name}
                        key={index}
                        button
                        selected={selectedIndex === index}
                        onClick={() => handleListItemClick(index)}
                        onMouseEnter={() => handleListItemHover(index)}
                        onMouseLeave={handleListItemLeave}
                      >
                        <ListItemIcon>
                          <img className={clsx(hoveredIndex === index && classes.menuIconHovered)} src={menu.icon} alt="menuicon" />
                        </ListItemIcon>
                        <ListItemText primary={t(menu.name)}/>
                      </ListItem>
                    </Tooltip>
                    <MenuPartition name={menu.name} isHidden={closeSidebar} />
                  </Box>
                )
              })
            }
          </List>
        </Drawer>
        <main className={clsx(isCompactView ? classes.contentCompactView : classes.content)}>
          <div className={classes.appBarSpacer} />
          {
            children
          }
        </main>
      </SessionHandler>
    </div>
  );
};

export default MainLayout;