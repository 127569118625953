import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  iconTooltip: {
    marginLeft: theme.spacing(2.625),
    cursor    : 'pointer'
  },
  iconTooltipDisabled: {
    marginLeft: theme.spacing(2.625)
  },
}));