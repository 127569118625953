/////////////////////////////

import { ENVIRONMENT, SUPPORTED_LANGUAGES } from "./constants";

// isTokenExpired()
export const isTokenExpired = (token) => {
  if (!token) {
    return;
  }

  const base64Url   = token.split(".")[1];
  const base64      = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  const data           = JSON.parse(jsonPayload);
  const expirationDate = data.exp;
  const currentDate    = new Date().getTime() / 1000;
  return currentDate >= expirationDate;
}

/////////////////////////////
// setLocalStorageItem()
/////////////////////////////
export const setLocalStorageItem = (storageKey, state) => {
  localStorage.setItem(storageKey, JSON.stringify(state));
}

/////////////////////////////
// getLocalStorageItem()
/////////////////////////////
export const getLocalStorageItem = (storageKey) => {
  const savedState = localStorage.getItem(storageKey);
  try {
    if (!savedState) {
      return undefined;
    }
    return JSON.parse(savedState ?? '{}');
  } catch (e) {
    return undefined;
  }
}
  
  //////Delete Modal - List of Associated
export const formatAssociated = (arr, t) => {
  if (arr.length > 1) {
    let lastItem = arr.pop();
    return `${arr} ${t('and')} ${lastItem}`;
  } else {
    return `${arr}`
  }
}
  
export const isArrayEqual = (array1, array2) => {
  // If length is not equal
  if (array1.length !== array2.length) {
    return false;
  } else {
    // Comparing each element of array
    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }
    return true;
  }
}

export const parseIntegerParam = (param, initialValue) => {
  let value = parseInt(param);

  if (isNaN(value) || value < 0) {
    value = initialValue;
  }

  return value;
}

/**
 * The function filters out certain languages from a list of supported languages based on the
 * environment.
 * @param supportedLanguages - an array of language codes or objects containing language codes and
 * names that are supported by the application.
 * @returns The function `getSupportedLanguages` returns an array of supported languages with some
 * languages filtered out based on certain conditions. If the `isProductionEnv` variable is false
 * (meaning the environment is either local or dev), then the original `supportedLanguages` array is
 * returned. Otherwise, the function filters out any testing languages
 */
const isProductionEnv = !(window.ENVIRONMENT === ENVIRONMENT.LOCAL || window.ENVIRONMENT === ENVIRONMENT.DEV);

export  const getSupportedLanguages = (supportedLanguages) => {
  if (!isProductionEnv) {
    return supportedLanguages;
  }

  return supportedLanguages.filter(lang => {
    let locale = lang;

    if (typeof lang === 'object') {
      locale = lang.code;
    }
    
    return locale !== SUPPORTED_LANGUAGES.FILIPINO && locale !== SUPPORTED_LANGUAGES.i18N
  });
}

export const getLanguageResources = (resources) => {
  if (isProductionEnv) {
    delete resources.fil;
    delete resources.i18n
  }

  return resources;
}

export const createBillingPackage = (planId, plan, counts, contents) => {
  return {
    planId,
    plan,
    counts,
    contents,
  };
}