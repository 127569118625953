import React from 'react';
import { Typography } from '@material-ui/core';
import { version, buildNumber } from '../../../package.json';
import useStyles from './styles';
import { FOOTER } from '../../utility/constants';

const Version = (props) => {
  const { type } = props;
	const classes = useStyles();

	return (
    <Typography variant="body2" className={type === FOOTER ? classes.footer : classes.version}>
      {version}.{buildNumber}
    </Typography>
	)
}

export default Version;