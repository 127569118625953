import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    paddingTop   : theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  visuallyHidden: {
    display: 'none'
  },
  tabView : {
    marginTop: theme.spacing(4),
  },
  gridIndex : {
    zIndex : '100',
  },
  billingPaper : {
    backgroundColor: 'white',
  },
  billing: {
    padding: theme.spacing(3.75)
  },
  action: {
    backgroundColor: theme.palette.grey[100],
    padding        : theme.spacing(2),
    textAlign      : 'right',

    '& .MuiButton-root:first-of-type': {
      marginRight: theme.spacing(2)
    }
  },
}));