import { Box, Checkbox, CircularProgress, ClickAwayListener, FormControl, IconButton, InputAdornment, NoSsr, Paper, Popper, TextField, Typography } from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon, ArrowDropUp as ArrowDropUpIcon, CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, SearchOutlined as SearchOutlinedIcon } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TENANT_FIELD } from '../../utility/constants';
import Chip from '../chip';
import useStyles from './styles';

const Chips = (props) => {
  const classes = useStyles();
  const { t }   = useTranslation();
  const {single, selectedItem, handleDelete, id, chipColor } = props

  const chipName = selectedItem[0].translationKey ? t(selectedItem[0].translationKey) : '';

  return (
    single ?
      <Box id={id} className={classes.singleOption}>
        <Typography variant="body2">{chipName}</Typography>
        <Typography variant="caption">{selectedItem[0].description}</Typography>
      </Box>
    :
      <Box id={id} className={classes.inputChips}>
        {
          selectedItem?.map((option, index) => (
            <Box className={classes.chips} key={'Enhanced-Selected-Chip-Box-' + index} >
              <Chip
                id={id}
                recordId={option.id}
                key={'Enhanced-Chip-Tag-' + index}
                title={option.name}
                description={option.description}
                onDelete={() => handleDelete(selectedItem.filter(entry => entry !== option))}
                color={chipColor}
              />
            </Box>
          ))
        }
      </Box>
  )
}

const EnhancedInput = (props) => {
  const classes = useStyles();
  const { selectedItem, label, handleDelete, handleShowOptions, showOptions, callbackRef, single, disabled, isValid, helperText, required, id, chipColor } = props;
  const textFieldHoverClass = !disabled ? classes.textFieldHover : '';

  return (
    <FormControl ref={callbackRef} className={classes.displayFlex}>
      <TextField 
        className={textFieldHoverClass}
        error={isValid}
        focused={showOptions}
        helperText={helperText}
        label={required && !disabled ? `${label}*` : label}
        onClick={handleShowOptions}
        variant="outlined"
        InputProps={{
          "data-testid": id,
          disabled: disabled,
          readOnly: true,
          startAdornment:
            selectedItem?.length > 0 ?
              <Chips
                id={id}
                chipColor={chipColor}
                single={single}
                selectedItem={selectedItem}
                handleDelete={handleDelete}
              />
            :
              null
          ,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton id={`${id}SelectButton`} disabled={disabled} edge="end" size="small">
                <ArrowDropDownIcon className={clsx({ [classes.visuallyHidden]: showOptions || disabled })} />
                <ArrowDropUpIcon   className={clsx({ [classes.visuallyHidden]: !showOptions || disabled })} />
              </IconButton>
            </InputAdornment>
          ),
          max: 100,
          min: 0,
          step: 2,
          onKeyDown: (event) => {
            event.preventDefault();
          },
        }}
      />
    </FormControl>
  )
}

const EnhancedSelection = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { query, items, handleSearch, lastItemElement, handleChange, isLoading, error, selectedItem, showOptions, setShowOptions, anchor, disabled, id, isSearchEnabled, withCheckbox } = props;

  const handleClickAway = () => {
    if (showOptions) setShowOptions(false);
  }

  return (
    <FormControl disabled={disabled}>
      <Popper
        open={showOptions}
        anchorEl={anchor}
        placement="bottom"
        disablePortal={disabled}
        className={classes.popper}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper className={classes.paper}>
            <FormControl
              className={clsx({
                [classes.selectForm]: true,
                [classes.visuallyHidden] : !showOptions,
              })}
            >
            {
              isSearchEnabled ?
                <Box className={classes.loadField}>
                  <TextField
                    autoFocus={true}
                    className={classes.searchBox}
                    id={`${id}SearchBar`}
                    onChange={handleSearch}
                    placeholder={t('select-component.search')}
                    size="small"
                    value={query}
                    variant="outlined"
                    InputProps={{
                      autoComplete: 'off',
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlinedIcon
                            color="secondary"
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                  <Box>{isLoading && <CircularProgress size={24} className={classes.loading} />}</Box>
                </Box>
              :
                <></>
            }
            <NoSsr>
              <Box>{error && 'ERROR'}</Box>
              <Box className={classes.options}>
              {
                items.map((item, index) => {
                  let elementRecordId = item.id;
                  
                  if (items.length === index + 1) {
                    return (
                      <Box id={`${id}${elementRecordId}`} ref={lastItemElement} key={`Select-Last-Item-` + index } className={classes.optionBoxSingle} onClick={() => handleChange(item)}>
                        <Checkbox
                          id={`${id}${elementRecordId}Checkbox`}
                          checked={selectedItem.some(value => value.id === item.id)}
                          checkedIcon={<CheckBoxIcon />}
                          className={!withCheckbox && classes.invisible}
                          color="primary"
                          icon={<CheckBoxOutlineBlankIcon />}
                          onChange={() => handleChange(item)}
                          value={item}
                        />
                        <Box onClick={() => handleChange(item)}>
                          <Box className={clsx({ [classes.optionTitle]: item.description })}>
                            <span id={`${id}${elementRecordId}Name`}>{item.translationKey ? t(item.translationKey) : item.name}</span>
                          </Box>
                          <Box className={classes.optionSub}>
                            <span id={`${id}${elementRecordId}Description`}>{item.description}</span>
                          </Box>
                        </Box>
                      </Box>
                    )
                  } else {
                    return (
                      <Box id={`${id}${elementRecordId}`} key={`Select-Item-` + index} className={classes.optionBoxSingle} onClick={() => handleChange(item)}>
                        <Checkbox
                          id={`${id}${elementRecordId}Checkbox`}
                          checked={selectedItem.some(value => value.id === item.id)}
                          checkedIcon={<CheckBoxIcon />}
                          className={!withCheckbox && classes.invisible}
                          color="primary"
                          icon={<CheckBoxOutlineBlankIcon />}
                          onChange={() => handleChange(item)}
                          value={item}
                        />
                        <Box onClick={() => handleChange(item)}>
                          <Box className={clsx({ [classes.optionTitle]: item.description })}>
                            <span id={`${id}${elementRecordId}Name`}>{item.translationKey ? t(item.translationKey) : item.name}</span>
                          </Box>
                          <Box className={classes.optionSub}>
                            <span id={`${id}${elementRecordId}Description`}>{item.description}</span>
                          </Box>
                        </Box>
                      </Box>
                    )
                  }
                })
              }
              </Box>
            </NoSsr>
          </FormControl>
        </Paper>
      </ClickAwayListener>
    </Popper>
  </FormControl>
  )
}

const Select = (props) => {
  const { search, selectFrom, label, onChange, defaultValue, single = false, disabled, isValid, helperText, value, required = false, id, isSearchEnabled, withCheckbox = false, name, chipColor, isLoadingSelect, items, error, hasMore, query, pageNumber } = props;
  const { t }                             = useTranslation();
  const [selectedItem, setSelectedItem]   = useState(defaultValue ? defaultValue : []);
  const [showOptions, setShowOptions]     = useState(false);
  const [anchor, setAnchor]               = useState(null);

  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    setSelectedItem(defaultValue);
  }, [defaultValue]);

  const callbackRef = useCallback(domNode => {
    if (domNode) setAnchor(domNode)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOptions]);

  const observer = useRef()
  const lastItemElement = useCallback(node => {
    if (isLoadingSelect) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        search(query, pageNumber + 1);
      }
    })
    if (node) observer.current.observe(node)
  }, [isLoadingSelect, hasMore, pageNumber, query, search] )

  const handleSearch = (e) => {
    let query = e.target.value;
    search(query, 0);
  }

  const handleChange = (item) => {
    if (single) {
      if (selectedItem.some(value => value.id === item.id)) {
        onChange([]);
      } else {
        onChange([...new Set([ item ])]);
      }
    } else {
      const index = selectedItem.indexOf(item)

      if (selectedItem.some(value => value.id === item.id)) {
        onChange(selected => (selected.filter(value => value.id !== item.id)));
      } else {
        if (index > -1) {
          handleDelete(selected => selected.filter(value => value !== item));
        } else {
          onChange([...new Set([ ...selectedItem, item ])]);
        }
      }
    }
    if (single) {
      handleShowOptions();
    }
  };

  const handleShowOptions = () => {
    if (!disabled){
      setShowOptions(!showOptions);
    }
  }

  const handleDelete = (newValue) => {
    onChange(newValue);
  }
  
  const getFilteredItems = useCallback(() => {
    if (name === TENANT_FIELD.ACCOUNT_MANAGER) {
      const newItems = items.filter(item => !selectedItem?.map(value => value.id).includes(item.id));
      setFilteredItems(newItems);
    } else {
      setFilteredItems(items);
    }
  }, [name, items, selectedItem]);

  useEffect(() => {
    getFilteredItems();
  }, [selectedItem, items, getFilteredItems]);

  return (
    <>
      <EnhancedInput
        id={id}
        handleDelete={handleDelete}
        handleShowOptions={handleShowOptions}
        label={`${t(label)}`}
        selectedItem={selectedItem}
        showOptions={showOptions}
        callbackRef={callbackRef}
        single={single}
        disabled= {disabled}
        isValid={isValid}
        helperText={helperText}
        value={value}
        required={required}
        chipColor={chipColor}
      />
      <EnhancedSelection
        id={id}
        anchor={anchor}
        disabled={disabled}
        error={error}
        handleChange={handleChange}
        handleSearch={handleSearch}
        isLoading={isLoadingSelect}
        items={filteredItems}
        lastItemElement={lastItemElement}
        query={query}
        selectedItem={selectedItem}
        selectFrom={selectFrom}
        setShowOptions={setShowOptions}
        showOptions={showOptions}
        single={single}
        isSearchEnabled={isSearchEnabled}
        withCheckbox={withCheckbox}
      />
    </>
  )
}


export default Select;