import { Grid, Paper, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react'
import { TEXT_FIELD_SKELETON, DESCRIPTION_SKELETON, ACTION_VIEW } from '../../../utility/constants';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

const DeploymentSkeleton = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={3}>
      <Skeleton height={161.75} />
    </Paper>
  )
}

const AuditLogSkeleton = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={3}>
      <Skeleton height={229.75} />
    </Paper>
  )
}

const TenantSkeleton = (props) => {
  const { path } = props;
  const classes  = useStyles();
  const { t }    = useTranslation();

  return (
    <Paper className={classes.viewPaper} elevation={3}>
      <Grid container spacing={2} className={classes.form}>
        <Grid item xs={4} className={classes.skeletonPadding}>
          <Skeleton height={TEXT_FIELD_SKELETON} />
          <Skeleton height={TEXT_FIELD_SKELETON} />
        </Grid>
        <Grid item xs={8} className={classes.skeletonPadding}>
          <Skeleton height={DESCRIPTION_SKELETON}/>
        </Grid>
      </Grid>
      <Box className={classes.mainContainer}>
        <Box className={classes.header}>
        <Box className={classes.headerBorderBefore}/>
            <Box className={classes.headerTitle}>
            {`${t('tenant-page.initialSystemAdministrator')}:`}
            </Box>
        <Box className={classes.headerBorderAfter}/>
        </Box>
        <Box className={classes.childrenContainer}>
        <Grid container spacing={2}>
            <Grid item xs={4}>
              <Skeleton height={TEXT_FIELD_SKELETON} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton height={TEXT_FIELD_SKELETON} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton height={TEXT_FIELD_SKELETON} />
            </Grid>
        </Grid>
        </Box>
      </Box>
      <Grid container spacing={2} className={classes.form}>
        <Grid item xs={path.includes(ACTION_VIEW) ? 12 : 4}>
          <Skeleton height={TEXT_FIELD_SKELETON} />
        </Grid>
      </Grid>
    </Paper>
  );
}

export { TenantSkeleton, AuditLogSkeleton, DeploymentSkeleton };