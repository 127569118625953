
import { GET, POST } from '../utility/constants';
import api from "./api";
import { request } from "./requests";

export const getDeploymentProgress = async (tenantId) => {
  const progress = await request({
    url    : `${api.TENANTS}/${tenantId}/status`,
    method : GET,
  });
  
  const { data } = progress;
  return data;
};

export const triggerDeployment  = async (id) => {
  const progress = await request({
    url    : `${api.TENANTS}/${id}/trigger-deployment`,
    method : POST
  });

  const { data } = progress;
  return data;
}
