//CDN CLOUD STORAGE
export const CDN = 'https://pacaascloudstorage.blob.core.windows.net/public-images'

// AXIOS CONFIGURATION
export const BEARER           = 'Bearer ';
export const CSRF_TOKEN       = 'nocheck';
export const TIMEOUT_LIMIT    = 100;
export const XML_HTTP_REQUEST = 'XMLHttpRequest';

// ENVIRONMENT
export const ENVIRONMENT = Object.freeze({
  LOCAL     : 'LOCAL',
  DEV       : 'DEV',
  STAGING   : 'STAGING',
  PRODUCTION: 'PRODUCTION'
});

// HTTP METHOD
export const GET    = 'GET';
export const POST   = 'POST';
export const PATCH  = 'PATCH';
export const PUT    = 'PUT';
export const DELETE = 'DELETE';

//Actions
export const ACTION_CREATE           = 'create';
export const ACTION_UPDATE           = 'update';
export const ACTION_VIEW             = 'view';
export const ACTION_DELETE           = 'delete';
export const ACTION_EXIT             = 'exit';

//EVENT TYPES
export const ALL  = 'All'
export const INV  = 'INV'
export const NEW  = 'NEW'

//ACTION
export const PROMPT_ACTION = Object.freeze({
  PUSH    : 'PUSH',
  POP     : 'POP',
  REPLACE : 'REPLACE'
});

//DEPLOYMENT PROGRESS
export const DEPLOYMENT_PROGRESS = Object.freeze({
  TENANT_CREATED         : 'tenant-page.tenantCreated',
  DATABASE_CREATED       : 'tenant-page.databaseCreated',
  INFRA_DEPLOYED         : 'tenant-page.infrastructureDeployed',
  DEPLOYMENT_FINISHED    : 'tenant-page.adminInvited'
});

//DEPLOYMENT PROGRESS
export const DEPLOYMENT_STATUS = Object.freeze({
  TENANT_CREATED         : 'TENANT_CREATED',
  DATABASE_CREATING      : 'DATABASE_CREATING',
  INFRASTRUCTURE_CREATING: 'INFRASTRUCTURE_CREATING',
  DATA_PROVISIONING      : 'DATA_PROVISIONING',
  DEPLOYMENT_FINISHED    : 'DEPLOYMENT_FINISHED',
});

//DEPLOYMENT ERROR
export const DEPLOYMENT_ERROR_CODE = Object.freeze({
  DATABASE_EXISTS         : 'DATABASE_EXISTS',
  INFRASTRUCTURE_EXISTS   : 'INFRASTRUCTURE_EXISTS',
  DATABASE_ERROR          : 'DATABASE_ERROR',
  INFRASTRUCTURE_ERROR    : 'INFRASTRUCTURE_ERROR',
  ADMIN_INVITED_ERROR     : 'ADMIN_INVITED_ERROR',
});

//DEPLOYMENT PROGRESS
export const DEPLOYMENT_ERROR = Object.freeze({
  CREATE_ERROR         : 'createError',
  DATABASE_ERROR       : 'databaseError',
  INFRASTRUCTURE_ERROR : 'infrastructureError',
  ADMIN_INVITED_ERROR  : 'adminInvitedError'
});

export const STATUS = Object.freeze({
  ACTIVE      : 'ACT',
  DEACTIVATED : 'DEA',
});

export const TENANT_ACTION = Object.freeze({
  ACTIVATE  : 'activate',
  DEACTIVATE: 'deactivate',
});

export const USER_ACTION = Object.freeze({
  ENABLE  : 'enable',
  DISABLE: 'disable',
});

export const ACTIVE       = 'Active'
export const DEACTIVATED  = 'Deactivated'

//DELETE
export const SINGLE   = 'single'
export const MULTIPLE = 'multiple'

//MENU ITEMS
export const MENU_ITEMS = Object.freeze({
  DASHBOARD: 'dashboard',
  USERS    : 'users',
  TENANTS  : 'tenants',
  SETTINGS : 'settings'
});

export const USER           = 'user';
export const USERS          = 'users';
export const USERS_MODULE   = 'Users';
export const TENANT         = 'tenant';
export const TENANTS        = 'tenants';
export const TENANT_VIEW    = 'viewTenant';
export const TENANT_LOGS    = 'tenantAuditLogs'
export const TENANTS_MODULE = 'Tenants';

//
export const SYSTEM_ADMINISTRATOR     = 'system-role.systemAdministrator';
export const ACCOUNT_MANAGER          = 'system-role.accountManager';
export const EXTERNAL_ACCOUNT_MANAGER = 'system-role.externalAccountManager';

//API REQUEST FAILED MESSAGE
export const API_REQUEST_ERROR_MESSAGE           = 'toaster-component.apiRequestFailed';
export const API_REQUEST_ERROR_MESSAGE_DATA_USED = 'isCurrentlyAssociatedWithOtherRecords';

// SKELETON HEIGHT
export const TEXT_FIELD_SKELETON  = 55.972;
export const DESCRIPTION_SKELETON = 120.6;

//SCHEMA
export const REQUIRED_FIELD = 'fieldIsRequired';
export const INVALID_FORMAT = 'invalidDateFormat';

// SORT ORDER
export const ASCENDING  = 'asc';
export const DESCENDING = 'desc';

// Projections
export const PROJECTION = Object.freeze({
  USERS     : 'usersProjection',
  TENANTS   : 'tenantsProjection',
  AUDIT_LOGS: 'auditLogProjection'
});

// Toaster severity
export const TOASTER_SEVERITY = Object.freeze({
  SUCCESS : 'success',
  ERROR   : 'error',
  INFO    : 'info',
});

export const TOKENS = Object.freeze({
  ACCESS_TOKEN : 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  KEYCLOAK_IDENTITY_LEGACY: 'KEYCLOAK_IDENTITY_LEGACY',
});

export const SYSTEM_ROLES = Object.freeze({
  SYSTEM_ADMIN            : 'System Administrator',
  ACCOUNT_MANAGER         : 'Account Manager',
  EXTERNAL_ACCOUNT_MANAGER: 'External Account Manager'
});

export const SCOPES = Object.freeze({
  READ  : 'read',
  VIEW  : 'view',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete'
});

//PAGES
export const PAGES = Object.freeze({
  DASHBOARD: 'dashboard',
  USERS    : 'users',
  TENANTS  : 'tenants',
  SETTINGS : 'settings'
});

//TABLE HEAD
export const TABLE_HEAD = Object.freeze({
  STATUS: 'status'
});

//PAGE TYPE
export const PAGE_TYPE = Object.freeze({
  VIEW  : 'view',
  UPDATE: 'update',
  CREATE: 'create'
});

//DATE AND TIME FORMAT
export const DATE_FORMAT = 'MMMM DD, YYYY';
export const TIME_FORMAT = 'h:mmA';

export const AUDIT_LOG_OPERATION = Object.freeze({
  CREATED: 'CREATED',
  UPDATED: 'UPDATED',
  ADDED  : 'ADDED',
  REMOVED: 'REMOVED',
  DOWNLOADED: 'DOWNLOADED'
});

export const USER_FIELD = Object.freeze({
  USER_ROLE: 'userRole'
});

export const TENANT_FIELD = Object.freeze({
  TENANT_NAME                 : 'name',
  DESCRIPTION                 : 'description',
  HOST                        : 'host',
  STATUS                      : 'status',
  FIRST_NAME                  : 'firstname',
  LAST_NAME                   : 'lastname',
  EMAIL                       : 'email',
  ACCOUNT_MANAGER             : 'Account Manager',
  PLAN                        : 'plan'
});

export const DEFAULT_PATHS = Object.freeze({
  DASHBOARD_PATH       : '/dashboard',
  DEFAULT_PATH         : '/',
  INTERNAL_SERVER_ERROR: '/internal-server-error',
  UNDER_MAINTENANCE    : '/under-maintenance',
  MY_ACCOUNT           : '/my-account',
});

export const SESSION_ITEM = Object.freeze({
  SESSION_TIME_KEY    : 'sessionTimeKey',   //local storage key
  SESSION_TIMEOUT     : 600,                 // 10 minutes in seconds
  SESSION_MAX         : 600000,              // 10 minutes in milliseconds
  SESSION_WARNING_TIME: 30,                 // time when the modal will pop up
  SESSION             : 'session',
  EXPIRED             : 'expired'
});

export const SESSION_EVENTS = Object.freeze({
  CLICK      : 'click',
  LOAD       : 'load',
  KEYDOWN    : 'keydown',
  MOUSE_MOVER: 'mousemove',
});

export const TENANT_ERROR_KEY = Object.freeze({
  NAME     : 'name',
  HOST     : 'host',
  NAMESPACE: 'namespace'
});

export const SUPPORTED_LANGUAGES = Object.freeze({
  ENGLISH : 'en',
  DEUTSCH : 'de',
  FILIPINO: 'fil',
  i18N    : 'i18n'
});

export const KEYCLOAK_HEADERS = Object.freeze({
  GRANT_TYPE                         : 'urn:ietf:params:oauth:grant-type:uma-ticket',
  ACCOUNT_MANAGEMENT_SERVER_AUDIDENCE: 'account-management-server',
  RESPONSE_MODE                      : 'permissions',
});

export const OIDC_CONSTANT = Object.freeze({
  SIGNOUT_SILENT : 'signoutSilent',
  SIGNIN_REDIRECT: 'signinRedirect',
})

export const KEYCLOAK_LOCALE = 'KEYCLOAK_LOCALE';

export const FOOTER = 'footer';