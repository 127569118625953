import * as Yup from 'yup';
import { REQUIRED_FIELD } from '../utility/constants';

export const userSchema = Yup.object({
  firstName   : Yup.string()
                .trim()
                .max(256, 'user-page.textfieldMaxRequirements')
                .required(`user-page.${REQUIRED_FIELD}`),
  lastName    : Yup.string()
                .trim()
                .max(256, 'user-page.textfieldMaxRequirements')
                .required(`user-page.${REQUIRED_FIELD}`),
  email       : Yup.string()
                .trim()
                .max(256, 'user-page.textfieldMaxRequirements')
                .email('user-page.invalidEmailError')
                .required(`user-page.${REQUIRED_FIELD}`),
  userRole    : Yup.object()
                .required(`user-page.${REQUIRED_FIELD}`)
                .nullable()
});

export const withCompanySchema = Yup.object({
  firstName   : Yup.string()
                .trim()
                .max(256, 'user-page.textfieldMaxRequirements')
                .required(`user-page.${REQUIRED_FIELD}`),
  lastName    : Yup.string()
                .trim()
                .max(256, 'user-page.textfieldMaxRequirements')
                .required(`user-page.${REQUIRED_FIELD}`),
  company     : Yup.string()
                .trim()
                .max(256, 'user-page.textfieldMaxRequirements')
                .required(`user-page.${REQUIRED_FIELD}`),
  email       : Yup.string()
                .trim()
                .max(256, 'user-page.textfieldMaxRequirements')
                .email('user-page.invalidEmailError')
                .required(`user-page.${REQUIRED_FIELD}`),
  userRole    : Yup.object()
                .required(`user-page.${REQUIRED_FIELD}`)
                .nullable()
});

export const signInSchema = Yup.object({
  email    : Yup.string()
                    .email('login-page.invalidEmailError')
                    .required(`login-page.${REQUIRED_FIELD}`),
  password : Yup.string()
                    .required(`login-page.${REQUIRED_FIELD}`),
});

export const forgotPasswordSchema = Yup.object({
  email : Yup.string()
            .email('forgot-password-page.invalidEmailError')
            .required(`forgot-password-page.${REQUIRED_FIELD}`),
});

export const createPasswordSchema = Yup.object({
  newPassword: Yup.string()
                  .required(`create-password-page.${REQUIRED_FIELD}`)
                  .min(8, 'create-password-page.passwordCharacters')
                  .matches(/\d/, 'create-password-page.passwordRuleSchema1')
                  .matches(/[a-z]/, 'create-password-page.passwordRuleSchema2')
                  .matches(/[A-Z]/, 'create-password-page.passwordRuleSchema3')
                  .matches(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, 'create-password-page.passwordRuleSchema4'),
  confirmPassword: Yup.string()
                  .required(`create-password-page.${REQUIRED_FIELD}`)
                  .when('newPassword', {
                    is: (val) => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                      [Yup.ref('newPassword')],
                      "create-password-page.passwordNotMatched"
                    ),
                  }),
});

export const newPasswordSchema = Yup.object({
  newPassword: Yup.string()
                  .required(`new-password-page.${REQUIRED_FIELD}`)
                  .min(8, 'new-password-page.passwordCharacters')
                  .matches(/\d/, 'new-password-page.passwordRuleSchema1')
                  .matches(/[a-z]/, 'new-password-page.passwordRuleSchema2')
                  .matches(/[A-Z]/, 'new-password-page.passwordRuleSchema3')
                  .matches(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, 'new-password-page.passwordRuleSchema4'),
  confirmPassword: Yup.string()
                  .required(`new-password-page.${REQUIRED_FIELD}`)
                  .when('newPassword', {
                    is: (val) => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                      [Yup.ref('newPassword')],
                      "new-password-page.passwordNotMatched"
                    ),
                  }),
});


export const tenantSchema = Yup.object({
    name            : Yup.string()
                         .max(256, 'tenant-page.textfieldMaxRequirements')
                         .required(`tenant-page.${REQUIRED_FIELD}`),
    description     : Yup.string()
                         .max(256, 'tenant-page.textfieldMaxRequirements')
                         .required(`tenant-page.${REQUIRED_FIELD}`),
    host            : Yup.string()
                         .matches(
                           /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9]){2,}$/i,
                           'tenant-page.invalidHost'
                         )
                         .max(256, 'tenant-page.textfieldMaxRequirements')
                         .required(`tenant-page.${REQUIRED_FIELD}`),
    firstName       : Yup.string()
                         .required(`tenant-page.${REQUIRED_FIELD}`)
                         .max(256, 'tenant-page.textfieldMaxRequirements'),
    lastName        : Yup.string()
                         .max(256, 'tenant-page.textfieldMaxRequirements')
                         .required(`tenant-page.${REQUIRED_FIELD}`),
    email           : Yup.string()
                         .max(256, 'tenant-page.textfieldMaxRequirements')
                         .email('tenant-page.invalidEmailError')
                         .required(`tenant-page.${REQUIRED_FIELD}`),
    accountManagers : Yup.array()
                         .of(Yup.object().shape({}))
                         .min(1, `tenant-page.${REQUIRED_FIELD}`)
});