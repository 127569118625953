import { Box, Button, Divider, Grid, Paper, Switch, TextField, Typography } from "@material-ui/core";
import clsx from 'clsx';
import { Form, Formik } from "formik";
import React from "react";
import { Trans, useTranslation } from 'react-i18next';
import SelectItems from "../../../components/select-items";
import { ACTION_CREATE, ACTION_UPDATE, ACTION_VIEW, DEPLOYMENT_ERROR_CODE, DEPLOYMENT_STATUS, STATUS, SYSTEM_ROLES, TENANT_FIELD } from "../../../utility/constants";
import { tenantSchema } from "../../../validation/schema";
import BillingDataComponent from "../biling-data-component";
import TabComponent from "../tab-component";
import useStyles from './styles';

const TenantContent = (props) => {
  const { 
    id, 
    handleSubmit, 
    handleSelectChange, 
    role, 
    showToaster, 
    initialValues, 
    disabled, 
    showLoading, 
    handleCancel, 
    setTenantName, 
    setDescription, 
    setHost, 
    setFirstName, 
    setLastName, 
    setEmail, 
    handleSwitchStatus, 
    path, 
    hasUpdatePermission, 
    deploymentStatus, 
    isShowChameleon, 
    formikReference, 
    setPlan,
    getPlan,
    setIsShowChameleon,
    required,
    previousBillingPlan
   } = props;
  
  const classes = useStyles();
  const { t }   = useTranslation();

  const isRequired = disabled ? '' : '*';

  const isDuplicateDatabaseError = initialValues?.deploymentError === DEPLOYMENT_ERROR_CODE.DATABASE_EXISTS;
  
  const isSystemAdmin            = role === SYSTEM_ROLES.SYSTEM_ADMIN;
  const isExternalAccountManager = role === SYSTEM_ROLES.EXTERNAL_ACCOUNT_MANAGER;

  const getButtonLabel = () => {
    return id ? <Trans i18nKey={'tenant-page.update'}/> : <Trans i18nKey={'tenant-page.create'}/>;
  }

  const handleChange = (setter, formik) => {
    return (setter, formik);
  }

  const hasUpdateFieldPermission = (field) => {
    if (path.includes(ACTION_CREATE)) {
      return true;
    } else if (path.includes(ACTION_VIEW)) {
      return false;
    }

    if (field === TENANT_FIELD.HOST) {
      return isSystemAdmin;
    } else if (field === TENANT_FIELD.ACCOUNT_MANAGER) {
      return !isExternalAccountManager;
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={tenantSchema}
      onSubmit={(values, formik) => handleSubmit(values, formik)}
      innerRef={formikReference}
    >
      {
        formik => (
          <Form>
            {showLoading(formik.isSubmitting || isShowChameleon)}
            <Paper className={clsx(path.includes(ACTION_VIEW) ? classes.viewPaper : classes.createUpdatePaper)} elevation={3}>
              {
                path.includes(ACTION_VIEW) ? 
                  <Box className={clsx(hasUpdatePermission ? classes.switchBox : 'hidden')}>
                    <Switch
                      key={'row-switch-' + id}
                      data-testid='tenantSwitch'
                      checked={Boolean(initialValues.status === STATUS.ACTIVE)}
                      onClick={(event) => handleSwitchStatus(event)}
                      className={deploymentStatus !== DEPLOYMENT_STATUS.DEPLOYMENT_FINISHED ? classes.disabledSwitch : classes.switch}
                      disabled={Boolean(deploymentStatus !== DEPLOYMENT_STATUS.DEPLOYMENT_FINISHED)}
                    />
                    <Typography className={deploymentStatus !== DEPLOYMENT_STATUS.DEPLOYMENT_FINISHED ? classes.disabledSwitchText : (initialValues.status === STATUS.ACTIVE ? classes.activeSwitch : classes.deactivatedSwitch)}>
                      {
                        initialValues.status === STATUS.ACTIVE ? t('tenant-page.active') : t('tenant-page.deactivated')
                      }
                    </Typography>
                  </Box>
                : <></>
              }
              <Grid container spacing={2} className={path.includes(ACTION_VIEW) ? classes.mainForm : classes.createUpdateForm}>
                <Grid item xs={4}>
                  <TextField
                    inputProps={{
                      readOnly: disabled,
                      "data-testid": "tenantName"
                    }}
                    autoFocus={isDuplicateDatabaseError}
                    onFocus={(e) =>
                      e.currentTarget.setSelectionRange(
                      e.currentTarget.value.length,
                      e.currentTarget.value.length
                    )}
                    disabled={disabled}
                    id="tenantName"
                    label={`${t('tenant-page.tenantName')}${isRequired}`}
                    name="name"
                    variant="outlined"
                    fullWidth
                    multiline
                    value={formik.values.name}
                    onChange={e => handleChange(setTenantName(e.target.value), formik.handleChange(e))}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && t(formik.errors.name)}
                  />
                  <TextField
                    inputProps={{
                      readOnly: disabled,
                      "data-testid": "tenantHost"
                    }}
                    disabled={disabled || !hasUpdateFieldPermission(TENANT_FIELD.HOST)}
                    id="tenantHost"
                    label={`${t('tenant-page.host')}${isRequired}`}
                    name="host"
                    variant="outlined"
                    fullWidth
                    value={formik.values.host}
                    onChange={e => handleChange(setHost(e.target.value), formik.handleChange(e))}
                    error={formik.touched.host && Boolean(formik.errors.host)}
                    helperText={formik.touched.host && t(formik.errors.host)}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    inputProps={{
                      readOnly: disabled,
                      "data-testid": "tenantDescription"
                    }}
                    disabled={disabled}
                    id="tenantDescription"
                    label={`${t('tenant-page.description')}${isRequired}`}
                    name="description"
                    fullWidth
                    value={formik.values.description}
                    onChange={e => handleChange(setDescription(e.target.value), formik.handleChange(e))}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && t(formik.errors.description)}
                    multiline
                    minRows={formik.touched.description && Boolean(formik.errors.description) ? 5.5 : 4.4}
                    maxRows={formik.touched.description && Boolean(formik.errors.description) ? 5.5 : 4.4}
                  />
                </Grid>
              </Grid>
              <Box className={path.includes(ACTION_CREATE) ? classes.createMainContainer : classes.mainContainer}>
                <Box className={classes.header}>
                  <Box className={classes.headerBorderBefore}/>
                    <Box className={classes.headerTitle}>
                      <Trans i18nKey={'tenant-page.initialSystemAdministrator'}/>
                    </Box>
                  <Box className={classes.headerBorderAfter}/>
                </Box>
                <Box className={classes.viewChildrenContainer}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        inputProps={{
                          readOnly: !path.includes(ACTION_CREATE),
                          "data-testid": "tenantInitialSystemAdminFirstName"
                        }}
                        disabled={!path.includes(ACTION_CREATE)}
                        id="tenantInitialSystemAdminFirstName"
                        label={`${t('tenant-page.firstName')}${isRequired}`}
                        name="firstName"
                        variant="outlined"
                        fullWidth
                        value={formik.values.firstName}
                        onChange={e => handleChange(setFirstName(e.target.value), formik.handleChange(e))}
                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                        helperText={formik.touched.firstName && t(formik.errors.firstName)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        inputProps={{
                          readOnly: !path.includes(ACTION_CREATE),
                          "data-testid": "tenantInitialSystemAdminLastName"
                        }}
                        disabled={!path.includes(ACTION_CREATE)}
                        id="tenantInitialSystemAdminLastName"
                        label={`${t('tenant-page.lastName')}${isRequired}`}
                        name="lastName"
                        variant="outlined"
                        fullWidth
                        value={formik.values.lastName}
                        onChange={e => handleChange(setLastName(e.target.value), formik.handleChange(e))}
                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                        helperText={formik.touched.lastName && t(formik.errors.lastName)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        inputProps={{
                          readOnly: !path.includes(ACTION_CREATE),
                          "data-testid": "tenantInitialSystemAdminEmail"
                        }}
                        disabled={!path.includes(ACTION_CREATE)}
                        id="tenantInitialSystemAdminEmail"
                        label={`${t('tenant-page.emailAddress')}${isRequired}`}
                        name="email"
                        variant="outlined"
                        fullWidth
                        value={formik.values.email}
                        onChange={e => handleChange(setEmail(e.target.value), formik.handleChange(e))}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && t(formik.errors.email)}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box className={path.includes(ACTION_CREATE) ? 'hidden' : path.includes(ACTION_VIEW) ? classes.viewChildrenContainer : classes.createUpdateChildrenContainer}>
                <Grid container spacing={2}>
                  <Grid item xs={4} className={!hasUpdateFieldPermission(TENANT_FIELD.ACCOUNT_MANAGER) && classes.visuallyHidden}>
                    <SelectItems
                      disabled={disabled || !hasUpdateFieldPermission(TENANT_FIELD.ACCOUNT_MANAGER)}
                      id="tenantAccountManagersSelect"
                      name={TENANT_FIELD.ACCOUNT_MANAGER}
                      onChange={event => handleSelectChange(event)}
                      selectedItems={formik.values.accountManagers}
                      showToaster={showToaster}
                      helperText={formik.touched.accountManagers && t(formik.errors.accountManagers)}
                      isValid={formik.touched.accountManagers && Boolean(formik.errors.accountManagers)}
                      required={true}
                      chipColor='neutral'
                    />
                  </Grid>
                  <Grid item xs={12} className={hasUpdateFieldPermission(TENANT_FIELD.ACCOUNT_MANAGER) && classes.visuallyHidden}>
                    <TextField
                      inputProps={{
                        readOnly: !path.includes(ACTION_CREATE),
                        "data-testid": "tenantAccountManagers"
                      }}
                      disabled={!path.includes(ACTION_CREATE)}
                      id="tenantAccountManagers"
                      label={t('tenant-page.accountManager')}
                      name="tenantAccountManagers"
                      variant="outlined"
                      fullWidth
                      multiline
                      value={formik.values.accountManagers?.map(user => user.name).join(', ')}
                    />
                  </Grid>
                </Grid>
              </Box>
              {
                path.includes(ACTION_UPDATE) ?
                <>
                  <Box className={classes.billingPaper}>
                    <Divider className={classes.divider} />
                    <Grid container className={classes.tabView}>
                      <Grid item xs={12}>
                        <TabComponent
                          tabValue={2}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <BillingDataComponent 
                          id={id} 
                          plan={getPlan(initialValues.plan)} 
                          path={path} 
                          setPlan={setPlan}
                          showToaster={showToaster}
                          setIsShowChameleon={setIsShowChameleon}
                          previousBillingPlan={previousBillingPlan}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </> : <></>
              }
              {
                path.includes(ACTION_CREATE) ?
                <>
                  <Typography className={path.includes(ACTION_CREATE) ? classes.title : 'hidden'}>{t('billing-page.titlePlan')}</Typography>
                  <Typography className={required ? classes.warningText : 'hidden'}>{t('billing-page.pleaseSelectPackage')}*</Typography>
                  <BillingDataComponent
                    path={path}
                    setPlan={setPlan}
                    required={required}
                  />
                </> : <></>
              }
              <Grid className={clsx(path.includes(ACTION_VIEW) ? 'hidden' : classes.action)}>
                <Grid item xs={12}>
                  <Button
                    className={classes.button}
                    onClick={handleCancel}
                    variant="outlined"
                    color="primary"
                    aria-label='cancelButton'
                    data-testid='cancelButton'
                  >
                    <Trans i18nKey={'tenant-page.cancel'}/>
                  </Button>
                  <Button
                    className={classes.button}
                    type="submit"
                    variant="contained"
                    color="primary"
                    aria-label='submitButton'
                    data-testid='submitButton'
                  >
                    {getButtonLabel()}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        )
      }
    </Formik>
  )
}

export default TenantContent;