import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

const useStyles = makeStyles(theme => ({
  snackbar: {
    top   : theme.spacing(10),
  },
  alert: {
    minWidth: 250,
    width   : 350,
    '& .MuiAlert-icon': {
      alignItems  : 'center',
      fontSize    : theme.spacing(3.5),
      marginRight : theme.spacing(1.25),
    }	,
    '& .MuiTypography-caption': {
      lineHeight: '0px'
    }
  },
  closeButton: {
    position    : 'absolute',
    right       : '-5px',
    stroke      : 'white',
    strokeWidth : 2,
    top         : '-6px',
    '&:hover': {
      background: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      color   : colors.WHITE,
      fontSize: theme.spacing(2.25),
    }
  },
  toasterTitle: {
    fontSize  : theme.spacing(2),
    fontWeight: 'bold'
  },
  toasterDesc: {
    fontSize: theme.spacing(2)
  }
}))

export default useStyles;