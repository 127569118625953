import { AppBar, Box, Container, Grid, IconButton, List, ListItem, ListItemText, Paper, Toolbar, Typography } from '@material-ui/core';
import { FiberManualRecordRounded } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer';
import images from "../../utility/images";
import useStyles from './styles';

const PrivacyPolicy = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t }   = useTranslation();

  const sesamsec = {
    email      : "info@sesamsec.com",
    faxNumber  : "+49 8233 79445-20",
    phoneNumber: "+49 8233 79445-0"
  }

  const dataProtectionOfficer = {
    email      : "datenschutz@hjp.de",
    faxNumber  : "06841 9816-29",
    phoneNumber: "06841 9816-0"
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <AppBar position="absolute" className={clsx(classes.appBar)}>
        <Toolbar className={classes.toolbar}>
          <IconButton size="small" onClick={() => history.push("/")}>
            <img src={images.SESAMSEC_LOGO} className={classes.logo} alt="elatec-logo"></img>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Paper className={`${classes.paper} ${classes.form}`}>
        <Grid container spacing={2} className={classes.form}>
          <Grid item md={12} className={classes.title}>
            <Typography variant="h2" className={`${'bold'} ${classes.title}`}>{t('privacy-policy-page.title')}</Typography>
          </Grid>
        </Grid>
        <br/>
        <Grid container spacing={2} className={classes.form}>
          <Grid item md={12}>
            <Typography variant="h4" className={`${'bold'} ${classes.paragTitle}`}>{t('privacy-policy-page.dataPrivacyA')}</Typography>
          </Grid>
        </Grid><br/>
        <Grid className={classes.form}>
          <Typography>
            {t('privacy-policy-page.paragraphA1')}
          </Typography>
        </Grid>
        <Grid className={classes.form}>
          <Typography>
            {t('privacy-policy-page.paragraphA2')}
          </Typography><br/>
        </Grid>
        <Grid container spacing={2} className={classes.form}>
          <Grid item md={12}>
            <Typography variant="h4" className={`${'bold'} ${classes.paragTitle}`}>{t('privacy-policy-page.dataPrivacyB')}</Typography>
          </Grid>
        </Grid>
        <Grid className={classes.form}>
          <Typography variant="h5" className={classes.subtitle}>
            {t('privacy-policy-page.subTitleB1')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphB1')}
          </Typography>
        </Grid>
        <Grid className={classes.form}>
          <Typography variant="h5" className={classes.subtitle}>
            {t('privacy-policy-page.subTitleB2')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphB2')}
          </Typography>
        </Grid>
        <Grid className={classes.form}>
          <Typography variant="h5" className={classes.subtitle}>
            {t('privacy-policy-page.subTitleB3')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphB3')}
          </Typography>
        </Grid>
        <Grid className={classes.form}>
          <Typography variant="h5" className={classes.subtitle}>
            {t('privacy-policy-page.subTitleB4')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphB4')}
          </Typography>
        </Grid>
        <Grid className={classes.form}>
          <Typography variant="h5" className={classes.subtitle}>
            {t('privacy-policy-page.subTitleB5')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphB5')}
          </Typography>
        </Grid>
        <Grid className={classes.form}>
          <Typography variant="h5" className={classes.subtitle}>
            {t('privacy-policy-page.subTitleB6')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphB6')}
          </Typography>
        </Grid>
        <Grid className={classes.form}>
          <Typography variant="h5" className={classes.subtitle}>
            {t('privacy-policy-page.subTitleB7')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphB7')}
          </Typography>
        </Grid>
        <Grid className={classes.form}>
          <Typography variant="h5" className={classes.subtitle}>
            {t('privacy-policy-page.subTitleB8')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphB8')}
          </Typography>
        </Grid>
        <Grid className={classes.form}>
          <Typography variant="h5" className={classes.subtitle}>
            {t('privacy-policy-page.subTitleB9')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphB9')}
          </Typography><br/>
        </Grid>
        <Grid container spacing={2} className={classes.form}>
          <Grid item md={12}>
            <Typography variant="h4" className={`${'bold'} ${classes.paragTitle}`}>{t('privacy-policy-page.dataPrivacyC')}</Typography>
          </Grid>
        </Grid>
        <Grid className={classes.form}>
          <Typography variant="h5" className={classes.subtitle}>
            {t('privacy-policy-page.subTitleC1')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphC1')}
          </Typography><br/>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.sesamsec')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.address1')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.address2')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            <Trans i18nKey={'privacy-policy-page.sesamsecTelephoneNumber'} values={{ phoneNumber: sesamsec.phoneNumber }}/>
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            <Trans i18nKey={'privacy-policy-page.sesamsecFaxNumber'} values={{ faxNumber: sesamsec.faxNumber }}/>
          </Typography>
        </Grid>
        <Box className={classes.flexForm}>
          <Typography className={classes.paragraph} noWrap>
            {t('privacy-policy-page.email')}&nbsp;  
            <a href={`mailto:${sesamsec.email}`} className={classes.link}>{sesamsec.email}</a>
          </Typography>
        </Box><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.hereafter')}
          </Typography>
        </Grid>
        <Grid className={classes.form}>
          <Typography variant="h5" className={classes.subtitle}>
            {t('privacy-policy-page.subTitleC2')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphC2')}
          </Typography><br/>
        </Grid>
        <Grid className={`${classes.paragraph} ${classes.email}`}>
          <Typography>
            <a href={`mailto:${dataProtectionOfficer.email}`} className={classes.link}>{dataProtectionOfficer.email}</a>
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            <Trans i18nKey={'privacy-policy-page.dataProtectionOfficerPhone'} values={{ phoneNumber: dataProtectionOfficer.phoneNumber }}/>
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            <Trans i18nKey={'privacy-policy-page.dataProtectionOfficerFax'} values={{ faxNumber: dataProtectionOfficer.faxNumber }}/>
          </Typography>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphC3')}
          </Typography>
        </Grid>
        <Grid className={classes.form}>
          <Typography variant="h5" className={classes.subtitle}>
            {t('privacy-policy-page.subTitleC3')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphC4')}
          </Typography>
        </Grid>
        <Grid className={classes.form}>
          <Typography variant="h6" className={classes.header}>
            {t('privacy-policy-page.subTitleC3.1')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphC5')}
          </Typography>
        </Grid>
        <Grid className={classes.list}>
          <List>
            <ListItem className={classes.flexStart}>
              <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
              <ListItemText className={classes.bulletText}>
                <Typography className={classes.paragraph}>
                  {t('privacy-policy-page.browserAndServerDataListItem1')} 
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.flexStart}>
              <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
              <ListItemText className={classes.bulletText}>
                <Typography className={classes.paragraph}>
                  {t('privacy-policy-page.browserAndServerDataListItem2')}
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.flexStart}>
              <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
              <ListItemText className={classes.bulletText}>
                <Typography className={classes.paragraph}>
                  {t('privacy-policy-page.browserAndServerDataListItem3')} 
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.flexStart}>
              <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
              <ListItemText className={classes.bulletText}>
                <Typography className={classes.paragraph}>
                  {t('privacy-policy-page.browserAndServerDataListItem4')} 
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.flexStart}>
              <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
              <ListItemText className={classes.bulletText}>
                <Typography className={classes.paragraph}>
                  {t('privacy-policy-page.browserAndServerDataListItem5')} 
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.flexStart}>
              <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
              <ListItemText className={classes.bulletText}>
                <Typography className={classes.paragraph}>
                  {t('privacy-policy-page.browserAndServerDataListItem6')} 
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphC6')}
          </Typography>
        </Grid>
        <Grid className={classes.form}>
          <Typography className={classes.header}>
            {t('privacy-policy-page.subTitleC3.2')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphC7')}
          </Typography>
        </Grid>
        <Grid className={classes.list}>
          <List>
            <ListItem className={classes.flexStart}>
              <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
              <ListItemText className={classes.bulletText}>
                <Typography className={classes.paragraph}>
                  {t('privacy-policy-page.cookiesListItem1')} 
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphC8')}
          </Typography>
        </Grid>
        <Grid className={classes.list}>
          <List>
            <ListItem className={classes.flexStart}>
              <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
              <ListItemText className={classes.bulletText}>
                <Typography className={classes.paragraph}>
                  {t('privacy-policy-page.cookiesListItem2')} 
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.flexStart}>
              <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
              <ListItemText className={classes.bulletText}>
                <Typography className={classes.paragraph}>
                  {t('privacy-policy-page.cookiesListItem3')} 
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphC9')}
          </Typography>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphC10')}
          </Typography>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphC11')}
          </Typography>
        </Grid>
        <Grid className={classes.form}>
          <Typography className={classes.header}>
            {t('privacy-policy-page.subTitleC3.3')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphC12')}
          </Typography>
        </Grid>
        <Grid className={classes.form}>
          <Typography variant="h5" className={classes.subtitle}>
            {t('privacy-policy-page.subTitleC4')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphC13')}
          </Typography>
        </Grid>
        <Grid className={classes.form}>
          <Typography variant="h5" className={classes.subtitle}>
            {t('privacy-policy-page.subTitleC5')}
          </Typography>
        </Grid>
        <Grid className={classes.list}>
          <List>
            <ListItem className={classes.flexStart}>
              <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
              <ListItemText className={classes.bulletText}>
                <Typography className={classes.paragraph}>
                  {t('privacy-policy-page.purposeofProcessingListItem1')} 
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.flexStart}>
              <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
              <ListItemText className={classes.bulletText}>
                <Typography className={classes.paragraph}>
                  {t('privacy-policy-page.purposeofProcessingListItem2')} 
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.flexStart}>
              <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
              <ListItemText className={classes.bulletText}>
                <Typography className={classes.paragraph}>
                  {t('privacy-policy-page.purposeofProcessingListItem3')} 
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.flexStart}>
              <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
              <ListItemText className={classes.bulletText}>
                <Typography className={classes.paragraph}>
                  {t('privacy-policy-page.purposeofProcessingListItem4')} 
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Grid className={classes.form}>
          <Typography variant="h5" className={classes.subtitle}>
            {t('privacy-policy-page.subTitleC6')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphC14')}
          </Typography>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphC15')}
          </Typography>
        </Grid>
        <Grid className={classes.form}>
          <Typography variant="h5" className={classes.subtitle}>
            {t('privacy-policy-page.subTitleC7')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphC16')}
          </Typography>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphC17')}
          </Typography>
        </Grid>
        <Grid className={classes.form}>
          <Typography variant="h5" className={classes.subtitle}>
            {t('privacy-policy-page.subTitleC8')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphC18')}
          </Typography>
        </Grid><br/>
        <Grid container spacing={2} className={classes.form}>
          <Grid item md={12}>
            <Typography variant="h4" className={`${'bold'} ${classes.paragTitle}`}>{t('privacy-policy-page.dataPrivacyD')}</Typography>
          </Grid>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphD1')}
          </Typography>
        </Grid>
        <Grid className={classes.form}>
          <Typography variant="h5" className={classes.subtitle}>
            {t('privacy-policy-page.subTitleD1')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphD2')}
          </Typography>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphD3')}
          </Typography>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphD4')}
          </Typography>
        </Grid>
        <Grid className={classes.form}>
          <Typography variant="h5" className={classes.subtitle}>
            {t('privacy-policy-page.subTitleD2')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphD5')}
          </Typography>
        </Grid><br/>
        <Grid container spacing={2} className={classes.form}>
          <Grid item md={12}>
            <Typography variant="h4" className={`${'bold'} ${classes.paragTitle}`}>{t('privacy-policy-page.dataPrivacyE')}</Typography>
          </Grid>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphE1')}
          </Typography>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphE2')}
          </Typography>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphE3')}
          </Typography>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphE4')}
          </Typography>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphE5')}
          </Typography>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphE6')}
          </Typography>
        </Grid><br/>
        <Grid container spacing={2} className={classes.form}>
          <Grid item md={12}>
            <Typography variant="h4" className={`${'bold'} ${classes.paragTitle}`}>{t('privacy-policy-page.dataPrivacyF')}</Typography>
          </Grid>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphF1')}
          </Typography>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            <Trans i18nKey={'privacy-policy-page.paragraphF2'}/>
          </Typography>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            <Trans i18nKey={'privacy-policy-page.paragraphF3'}/>
          </Typography>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            <Trans i18nKey={'privacy-policy-page.paragraphF4'}/>
          </Typography>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            <Trans i18nKey={'privacy-policy-page.paragraphF5'}/>
          </Typography>
        </Grid>
        <Grid className={classes.list}>
          <List>
            <ListItem className={classes.flexStart}>
              <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
              <ListItemText className={classes.bulletText}>
                <Typography className={classes.paragraph}>
                  {t('privacy-policy-page.rightToDeletionListItem1')} 
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.flexStart}>
              <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
              <ListItemText className={classes.bulletText}>
                <Typography className={classes.paragraph}>
                  {t('privacy-policy-page.rightToDeletionListItem2')} 
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.flexStart}>
              <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
              <ListItemText className={classes.bulletText}>
                <Typography className={classes.paragraph}>
                  {t('privacy-policy-page.rightToDeletionListItem3')} 
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            <Trans i18nKey={'privacy-policy-page.paragraphF6'}/>
          </Typography>
        </Grid>
        <Grid className={classes.list}>
          <List>
            <ListItem className={classes.flexStart}>
              <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
              <ListItemText className={classes.bulletText}>
                <Typography className={classes.paragraph}>
                  {t('privacy-policy-page.rightToRestrictionListItem1')} 
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.flexStart}>
              <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
              <ListItemText className={classes.bulletText}>
                <Typography className={classes.paragraph}>
                  {t('privacy-policy-page.rightToRestrictionListItem2')} 
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.flexStart}>
              <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
              <ListItemText className={classes.bulletText}>
                <Typography className={classes.paragraph}>
                  {t('privacy-policy-page.rightToRestrictionListItem3')} 
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.flexStart}>
              <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
              <ListItemText className={classes.bulletText}>
                <Typography className={classes.paragraph}>
                  {t('privacy-policy-page.rightToRestrictionListItem4')} 
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphF7')}
          </Typography>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            <Trans i18nKey={'privacy-policy-page.paragraphF8'}/>
          </Typography>
        </Grid><br/>
        <Grid className={`${classes.paragraph} ${classes.email}`}>
          <Typography>
            <a href={`mailto:${dataProtectionOfficer.email}`} className={classes.link}>{dataProtectionOfficer.email}</a>
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            <Trans i18nKey={'privacy-policy-page.dataProtectionOfficerPhone'} values={{ phoneNumber: dataProtectionOfficer.phoneNumber }}/>
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            <Trans i18nKey={'privacy-policy-page.dataProtectionOfficerFax'} values={{ faxNumber: dataProtectionOfficer.faxNumber }}/>
          </Typography>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('privacy-policy-page.paragraphF9')}
            <Link to={{ pathname: "https://www.bfdi.bund.de/" }} target="_blank"  className={classes.link}>www.bfdi.bund.de</Link>.
          </Typography>
        </Grid><br/>
        <Grid className={classes.paragraph}>
          <Typography>
            <Trans i18nKey={'privacy-policy-page.paragraphF10'}/>
          </Typography>
        </Grid><br/>
      </Paper>
      <Footer position="fixed"/>
    </Container>
  );
}

export default PrivacyPolicy;