import { Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import useStyles from './styles';

const IconWithHover = (props) => {
  const { hoverIcon, icon, altText, disabled, id, handleOnClick } = props;
  const [isHovered, setisHovered] = useState(false);

  const classes = useStyles();

  const handleMouseEnter = () => {
    setisHovered(true);
  };

  const handleMouseLeave = () => {
    setisHovered(false);
  };
  
  return (
    <Tooltip title={disabled ? '' : <Trans i18nKey={altText}/>} className={disabled ? classes.iconTooltipDisabled : classes.iconTooltip}>
      <img
        data-testid={id}
        onClick={handleOnClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        src={isHovered && !disabled ? hoverIcon : icon} alt={<Trans i18nKey={altText}/>}
      />
    </Tooltip>
  );
}

export default IconWithHover;