import React from "react";
import {Breadcrumbs, Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { withRouter } from 'react-router';
import useStyles from './styles';
import { Trans, useTranslation } from 'react-i18next';
import { ALL } from "../../utility/constants";

const State = (prop) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { index, isLast, name, routeTo, mainPage, subtitle, history, pathname, title } = prop;
  const isCreate = name === 'create';
  const crumb = name.charAt(0) + name.slice(1);
  
  const handleRoute = () => {
    const location = {
      pathname: routeTo,
      state   : {
        ...history.location.state,
        prevLocation: pathname
      }
    }

    history.push(location);
  }
  
  if (mainPage) {
    return null;
  } else if (isLast) {
      if (isCreate) {
        return <Typography variant="body2" component={'span'} color="textPrimary" className={classes.cursorDefault}> <span id="breadcrumbCurrent">{title}</span> </Typography>
      } else if (index === 0) {
        return <Typography variant="body2" component={'span'} color="textPrimary" className={classes.cursorDefault}> <span id="breadcrumbCurrent">{t(crumb)}</span> </Typography>
      } else {
        return <Typography variant="body2" component={'span'} color="textPrimary" className={classes.cursorDefault}> <span id="breadcrumbCurrent">{subtitle}</span> </Typography>
      }
  } else {
    return <Link id="breadCrumbPrevious" variant="body2" color="primary" className={classes.cursorPointer} onClick={handleRoute}> <Trans i18nKey={`title-component.${crumb}`}/> </Link>
  }
}

const Title = (props) => {
  const classes = useStyles();
  const { title, subtitle, history, location: { pathname }, listType } = props;
  const pathnames = listType ? [title] : pathname.split('/').filter(x => x);
  const subTitle = listType ? title : subtitle;
  
  const removeItemFromPathname = (item) => {
    const index = pathnames.indexOf(item);

    if (index > -1 ) {
      pathnames.splice(index, 1);
      return item;
    } else {
      return null;
    }
  }

  return (
    <>
      <Typography variant="h6" component="h6" className={classes.title}>
        {title}
      </Typography>
      <Typography component={'span'} variant="body2">
        <Breadcrumbs separator={listType === ALL.toLowerCase() ? '' :   <NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {
            pathnames.map((name, index) => {
              const routeTo    = `/${pathnames.slice(0, index + 1).join("/")}`;
              const isLast     = index === pathnames.length - 1;
              const mainPage   = pathnames.length === 1 || listType === ALL.toLowerCase();
              removeItemFromPathname('view');
              removeItemFromPathname('update');

              return (
                <State
                  key={index}
                  index={index}
                  mainPage={mainPage}
                  isLast={isLast}
                  name={name}
                  routeTo={routeTo}
                  subtitle={subTitle}
                  history={history}
                  pathname={pathname}
                  title={title}
                />
              )
            })
          }
        </Breadcrumbs>
      </Typography>
      <br/>
    </>
  )
}

export default withRouter(Title);