import { makeStyles, lighten } from '@material-ui/core/styles';
import colors from '../../theme/colors';
import images from '../../utility/images';

export default makeStyles((theme) => ({
  iconButton: {
    backgroundColor: theme.palette.primary.light,
    borderRadius   : theme.spacing(0.5),
    display        : 'inline',
    stroke         : colors.PRIMARY,
    strokeWidth    : 1,
    '&:hover': {
      backgroundColor: colors.LIGHT_BLUE
    }
  },
  iconButton2: {
    backgroundColor: colors.WHITE,
    borderRadius   : theme.spacing(0.5),
    color          : colors.PRIMARY,
    display        : 'inline',
    '& .Mui-disabled':{
      color: colors.SECONDARY
    }
  },
  iconButton3: {
    backgroundColor: colors.WHITE,
    borderRadius   : theme.spacing(0.5),
    color          : colors.PRIMARY,
    display        : 'inline',
  },
  blockIcon: {
    height   : theme.spacing(2.5),
  },
  iconButtonContainer: {
    marginLeft    : theme.spacing(0),
    justifyContent: 'flex-end'
  },
  deleteIcon: {
    height    : '22px',
    width     : '21px',
    background: `url(${images.DELETE_ICON}) no-repeat`,
    marginLeft: '5px',
    '&:hover' : {
      background: `url(${images.DELETE_ICON_HOVER}) no-repeat`
    }
  },
  editIcon: {
    height    : '22px',
    width     : '21px',
    background: `url(${images.EDIT_ICON}) no-repeat`,
    '&:hover' : {
      background: `url(${images.EDIT_ICON_HOVER}) no-repeat`
    }
  },
  label: {
    backgroundColor: 'black',
  },
  paper: {
    marginBottom: theme.spacing(5),
    width       : '100%',
  },
  paper2: {
    marginBottom: theme.spacing(5),
    width       : '100%',
    border      : `1px solid ${colors.GRAY}`
  },
  table: {
    '& .Mui-selected, .Mui-selected:hover, .MuiTableRow-hover:hover': {
      backgroundColor: colors.SECONDARY_GRAY_LIGHT,
      color          : theme.palette.secondary.main,
    },
    '& .MuiTableCell-body': {
      maxWidth: theme.spacing(32)
    }
  },
  cellContent: {
    color     : theme.palette.text.primary,
    wordBreak : 'break-word'
  },
  cellContentDisabled: {
    color     : theme.palette.text.secondary,
    wordBreak : 'break-word'
  },
  cellContentLink: {
    color     : theme.palette.text.primary,
    wordBreak : 'break-word',
    '&:hover' : {
      color          : theme.palette.primary.light,
      textDecoration : 'none'
    }
  },
  cellContentLinkDisabled: {
    color     : theme.palette.text.secondary,
    wordBreak : 'break-word',
    '&:hover' : {
      color          : theme.palette.primary.light,
      textDecoration : 'none'
    }
  },
  select: {
    marginLeft    : theme.spacing(-2),
    '& .MuiFilledInput-input': {
      padding     : theme.spacing(1),
      paddingRight: theme.spacing(2.5),
      fontSize    : '14px',
      width       : '100px',
    },
    '& .MuiSelect-iconFilled': {
      right: 0,
      color: 'inherit',
    },
    '& .MuiMenuItem-root': {
      fontSize: '14px',
    },
  },
  light: {
    backgroundColor: theme.palette.primary.light,
    color          : theme.palette.primary.main,
    '&:hover, &:focus, &:visited, &:active': {
      backgroundColor: theme.palette.primary.light,
    }
  },
  info: {
    backgroundColor: '#34ACBB19',
    color          : '#35ACBB',
    '&:hover, &:focus, &:visited, &:active': {
      backgroundColor: '#34ACBB19',
    }
  },
  success: {
    backgroundColor: '#2FB56A19',
    color          : theme.palette.success.main,
    '&:hover, &:focus, &:visited, &:active': {
      backgroundColor: '#2FB56A19',
    }
  },
  tableCard: {
    '& .MuiPaper-root, & .MuiPaper-rounded, & .MuiPaper-elevation1': {
      backgroundColor: 'transparent',
      borderRadius   : 0,
      boxShadow      : 'none',
    },
  },
  card: {
    alignContent  : 'center',
    alignItems    : 'center',
    display       : 'flex',
    flexWrap      : 'nowrap',
    justifyContent: 'flex-start',
  },
  cardMedia: {
    width : theme.spacing(10),
    height: theme.spacing(10),
    '& .MuiCardMedia-img': {
      objectFit: 'fill'
    }
  },
  online: {
    alignContent  : 'center',
    alignItems    : 'center',
    color         : theme.palette.success.main,
    cursor        : 'pointer',
    display       : 'flex',
    justifyContent: 'flex-start',
  },
  offline: {
    alignContent  : 'center',
    alignItems    : 'center',
    color         : theme.palette.text.secondary,
    cursor        : 'pointer',
    display       : 'flex',
    justifyContent: 'flex-start',
  },
  invisible: {
    display      : 'none',
  },
  visible: {
    display      : 'block',
  },
  markAllOffline: {
    alignContent  : 'center',
    alignItems    : 'center',
    color         : theme.palette.text.secondary,
    display       : 'flex',
    justifyContent: 'flex-start',
  },
  hoverLabel: {
    fontSize      : theme.spacing(2),
    color         : colors.SECONDARY_TEXT,
    '&:hover':{
      cursor      : 'pointer',
      color       : colors.PRIMARY
    }
  },
  pagination: {
    marginTop: theme.spacing(-8)
  },
  tableSize: {
    minHeight: '40vh',
    width    : '100%',
  },
  actionButton: {
    padding: 0,
  },
  visuallyHidden: {
    border  : 0,
    clip    : 'rect(0 0 0 0)',
    height  : 1,
    margin  : -1,
    overflow: 'hidden',
    padding : 0,
    position: 'absolute',
    top     : 20,
    width   : 1,
  },
  toolView: {
    display       : 'flex',
    justifyContent: 'space-between',
    margin        : '0 auto',
    position      : 'relative',
    top           : theme.spacing(-8),
    width         : '100%',
  },
  searchField: {
    background: colors.WHITE,
    display   : 'block',
    float     : 'left',
    left      : theme.spacing(-0.5),
    margin    : 0,
    position  : 'relative',
    top       : theme.spacing(-0.5),
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.SECONDARY
      },
      '& input': {
        "&::placeholder": {
          color: colors.SECONDARY,
          opacity: 1
        }
      }
    }
  },
  selectionToolbar: {
    display       : 'flex',
    fontWeight    : 600,
    justifyContent: 'flex-start',
    padding       : theme.spacing(1),
  },
  cutBar: {
    marginTop: theme.spacing(1.5),
    position : 'relative',
    top      : theme.spacing(6),
  },
  actionTools: {
    alignItems    : 'center',
    display       : 'flex',
    justifyContent: 'flex-end',
    marginTop     : theme.spacing(1),
  },
  markAsRead: {
    alignItems    : 'center',
    display       : 'flex',
    justifyContent: 'space-between',
    marginTop     : theme.spacing(1),
    marginRight   : theme.spacing(1),
    width         : '155px',
  },
  markAsReadLabel: {
    fontSize      : theme.spacing(2),
    color         : colors.SECONDARY_TEXT
  },
  chipsLayout: {
    fontWeight : 'normal',
    margin     : theme.spacing(0.15)
  },
  chipset: {
    alignItems: 'center',
    display   : 'flex',
    flexWrap  : 'nowrap',
    width     : 'fitContent',
  },
  tableIcon: {
    marginRight: theme.spacing(1),
  },
  nowrapbox: {
    display   : 'table-cell',
    whiteSpace: 'nowrap',
  },
  actionHeader: {
    textAlign : 'center'
  },
  actionsRow: {
    position : "sticky",
    textAlign: "center",
    right    : 0,
    width    : '5%',
  },
  newActions: {
    right      : 0,
    paddingLeft: theme.spacing(4)
  },
  rowChip: {
    padding: theme.spacing(0.25),
  },
  cellStyle: {
    textAlign: 'left',
  },

  tag: {
    borderRadius: theme.spacing(0.2),
    margin      : theme.spacing(0.2),
  },
  tagBody: {
    display      : 'flex',
    flexDirection: 'row',
    padding      : theme.spacing(0.2),
    position     : 'relative',
    width        : 'fit-content',
  },
  tagIcon: {
    alignItems    : 'center',
    display       : 'flex',
    justifyContent: 'center',
    left          : theme.spacing(-0.25),
    margin        : '0 auto',
    position      : 'relative',
  },
  tagTitle: {
    marginTop : theme.spacing(0.25),
    fontWeight: 800
  },
  tagContent: {
    position: 'relative',
    top     : theme.spacing(-0.2),
  },
  root: {
    paddingLeft : theme.spacing(0.4),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    backgroundColor: lighten(theme.palette.primary.main, 0.85),
    color          : theme.palette.secondary.main,
  },
  switch: {
    width  : 52,
    height : 22,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding        : 0,
      margin         : 1.29,
      '&.Mui-checked': {
        color     : theme.palette.common.white,
        marginLeft: theme.spacing(-0.34),
        transform : 'translateX(34px)',
        '& + .MuiSwitch-track': {
          backgroundColor: colors.ACTIVE,
          border         : 0,
          opacity        : 1,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width    : 19,
      height   : 19,
    },
    '& .MuiSwitch-track': {
      borderRadius   : 26 / 2,
      backgroundColor: theme.palette.grey[500],
      opacity        : 1,
      transition     : theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  },
  columnHeader: {
    alignContent  : 'center',
    alignItems    : 'center',
    display       : 'contents',
    justifyContent: 'center',
  },
  columnItem: {
    color     : theme.palette.secondary.main,
    fontWeight: '600',
    flexWrap  : 'nowrap',
  },
  activeIcon: {
    color     : theme.palette.primary.dark,
    flexWrap  : 'nowrap',
    fontSize  : theme.spacing(2),
    fontWeight: '800',
    marginTop : theme.spacing(-0.2),
  },
  inactiveIcon: {
    color     : theme.palette.primary.dark,
    flexWrap  : 'nowrap',
    fontSize  : theme.spacing(2),
    fontWeight: '800',
    marginTop : theme.spacing(0.2),
  },
  eventAction: {
    cursor    : 'pointer',
    marginLeft: theme.spacing(2)
  },
  approveButton: {
    marginBottom      : theme.spacing(1),
    backgroundColor   : theme.palette.success.main,
    color             : theme.palette.common.white,
    marginRight       : theme.spacing(2),
    '&:hover, &:focus' : {
      backgroundColor : theme.palette.success.dark,
    },
  },
  button: {
    marginBottom    : theme.spacing(1),
    marginRight     : theme.spacing(1),
    backgroundColor : theme.palette.error.light,
    '&:hover, &:focus' : {
      backgroundColor : theme.palette.error.dark,
    },
  },
  textNewController: {
    fontWeight: 'bold'
  },
  searchFieldContainer: {
    alignItems        : 'center',
    display           : 'flex',
    width             : '100%'
  },
  unfilteredLink: {
    fontSize          : theme.spacing(2),
    marginBottom      : theme.spacing(0.5),
    marginLeft        : theme.spacing(2)
  },
  moveCellHead: {
    textAlign: 'right'
  },
  activeSwitch: {
    marginLeft : theme.spacing(2),
    color      : colors.ACTIVE,
  },
  deactivatedSwitch: {
    marginLeft : theme.spacing(2),
    color      : colors.SECONDARY
  },
  withoutChipLabel: {
    marginLeft : theme.spacing(1),
    wordBreak  : 'break-word'
  },
  disabledSwitch : {
    width  : 52,
    height : 22,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding        : 0,
      margin         : 1.29,
      '&.Mui-checked': {
        color     : theme.palette.grey,
        marginLeft: theme.spacing(-0.34),
        transform : 'translateX(34px)',
        '& + .MuiSwitch-track': {
          backgroundColor: colors.SECONDARY_LIGHT,
          border         : 0,
          opacity        : 1,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width    : 19,
      height   : 19,
    },
    '& .MuiSwitch-track': {
      borderRadius   : 26 / 2,
      backgroundColor: theme.palette.grey[500],
      opacity        : 1,
      transition     : theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  },
  disabledSwitchText : {
    marginLeft   : theme.spacing(2),
    color        : colors.SECONDARY,
  },
  nodata: {
    alignContent   : 'center',
    alignItems     : 'center',
    display        : 'flex',
    flexDirection  : 'column',
    justifyContent : 'center',
    minHeight      : '60vh',
  },
}));