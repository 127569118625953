import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  dialogModal: {
    alignItems    : 'center',
    display       : 'flex',
    flexDirection : 'column', 
    width         : '100%'
  },
  dialogImage: {
    paddingTop   : theme.spacing(4)
  },
  image: {
    width: theme.spacing(7.5)
  },
  dialogTitle: {
    marginTop: theme.spacing(2.5),
    textAlign: 'center'
  },
  title: {
    color     : colors.SECONDARY,
    fontSize  : theme.spacing(2.5),
    fontWeight: 'bold'
  },
  dialogContent: {
    paddingBottom: theme.spacing(5),
    paddingTop   : theme.spacing(3),
    textAlign    : 'center',
    width        : theme.spacing(56.25)
  },
  dialogActions: {
    alignItems     : 'center',
    backgroundColor: '#F1F1F1',
    display        : 'flex',
    width          : '100%',
    justifyContent : 'space-around'
  },
  dialogSubmitButton: {
    display    : 'flex',
    float      : 'left',
    margin     : theme.spacing(2),
    marginRight: 0,
    padding    : theme.spacing(2),
    width      : '50%'
  },
  dialogCancelButton: {
    display    : 'flex',
    float      : 'left',
    margin     : theme.spacing(2),
    padding    : theme.spacing(2),
    width      : '50%'
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      width   : theme.spacing(68.75),
      maxWidth: '100%'
    }
  }
}));