import { KEYCLOAK_PATH } from "../OidcConfig";
import { ENVIRONMENT } from "../utility/constants";

const AUTH_BASE_PATH = window.ENVIRONMENT === ENVIRONMENT.LOCAL ? window.AUTH_BASE_PATH : window.API_BASE_PATH;

const api = {
  LOGIN                                                 : `${AUTH_BASE_PATH}/public/login`,
  USERS_BY_TOKEN                                        : `${AUTH_BASE_PATH}/auth/search/findByToken`,
  USERS                                                 : `${AUTH_BASE_PATH}/accountManagementUsers`,
  USERS_SEARCH                                          : `${AUTH_BASE_PATH}/accountManagementUsers/search/findByFirstNameContainingOrLastNameContaining`,
  TENANTS                                               : `${AUTH_BASE_PATH}/tenants`,
  TENANT_USER_ASSIGN                                    : `${AUTH_BASE_PATH}/tenants/assign-user`,
  TENANTS_SEARCH                                        : `${AUTH_BASE_PATH}/tenants/search/findByNameContaining`,
  TENANTS_BY_USER_ID                                    : `${AUTH_BASE_PATH}/tenants/search/findByUserId`,
  AUDIT_LOGS_BY_TENANT_ID                               : `${AUTH_BASE_PATH}/audit-logs/find-by-tenant-id`,
  USER_TENANTS_DELETE                                   : `${AUTH_BASE_PATH}/user-tenants/delete-by-user-id`,
  USER_ROLES                                            : `${AUTH_BASE_PATH}/systemRoles`,
  USER_ROLES_SEARCH                                     : `${AUTH_BASE_PATH}/systemRoles/search/findByNameContaining`,
  REFRESH_TOKEN                                         : `${AUTH_BASE_PATH}/public/refreshToken`,
  FORGOT_PASSWORD                                       : `${AUTH_BASE_PATH}/public/forgot-password`,
  NEW_PASSWORD                                          : `${AUTH_BASE_PATH}/public/new-password`,    
  KEYCLOAK_TOKEN                                        : `${KEYCLOAK_PATH}/protocol/openid-connect/token`,                                                       
};

export default api;