import React from 'react';
import { Box, Button } from '@material-ui/core';
import { Trans } from 'react-i18next';
import useStyles from './styles';

const ActionButtons = (props) => {
  const { type, onClose, singleDelete, multipleDelete, initialValues } = props;
  const classes  = useStyles();

  const handlePositiveResponse = () => {
    if (type.includes('multiple')) {
      multipleDelete(initialValues);
    } else {
      singleDelete(initialValues);
    }
    onClose();
  }

  return (
    <Box id="deleteModalFooterContainer" className={classes.dialogActions}>
      <Button
        data-testid="deleteButton"
        aria-label="delete-modal.removeButton"
        id="deleteModalRemoveYesButton"
        color="primary"
        variant="outlined"
        className={classes.dialogSubmitButton}
        onClick={handlePositiveResponse}
      >
        <Trans i18nKey={'delete-modal.removeButton'} />
      </Button>
      <Button
        data-testid="cancelButton"
        aria-label="delete-modal.cancelButton"
        id="deleteModalRemoveNoButton"
        color="primary" variant="contained"
        className={classes.dialogCancelButton}
        onClick={onClose}
      >
        <Trans i18nKey={'delete-modal.cancelButton'} />
      </Button>
    </Box>
  )
}

export default ActionButtons;