import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  tab: {
    '& .MuiTab-root'  : {
      backgroundColor     : theme.palette.grey[100],
      borderTopLeftRadius : theme.spacing(0.5),
      borderTopRightRadius: theme.spacing(0.5),
      color               : theme.palette.common.black,
      marginRight         : theme.spacing(0.5),
    },
    '& .Mui-selected' : {
      backgroundColor : theme.palette.primary.main,
      color           : theme.palette.common.white,
      cursor          : 'default',
    },
  },
  tabLabel: {
    fontSize        : theme.spacing(2),
    textTransform   : 'capitalize',
  }
}));