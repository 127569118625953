import React from 'react'
import useStyles from './styles';
import { Trans } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';

const Error500 = (props) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item lg={8} xs={8} className={classes.content}>
        <Typography variant="h1" className={classes.errorCode}>
          <Trans i18nKey={'error-500-page.errorCode'} />
        </Typography>
        <Typography variant="h4" className={classes.error}>
          <Trans i18nKey={'error-500-page.error'} />
        </Typography>
        <Typography variant="h5" className={classes.subText}>
          <Trans i18nKey={'error-500-page.subText'} />
        </Typography>
        <Typography variant="h5">
          <Trans i18nKey={'error-500-page.supportText'} values={{ supportEmail: 'support@sesamsec.com' }}/>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Error500