import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, FormControl, Grid, MenuItem, Paper, Select, Typography } from "@material-ui/core";
import cookies from 'js-cookie';
import moment from 'moment';
import 'moment/min/locales';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import Empty from "../../../components/empty";
import { getAuditLogsByTenantId } from "../../../service/audit-log-api";
import { ASCENDING, AUDIT_LOG_OPERATION, DATE_FORMAT, DESCENDING, SYSTEM_ROLES, TENANT_FIELD, TENANT_LOGS, TIME_FORMAT, TOASTER_SEVERITY } from "../../../utility/constants";
import { AuditLogSkeleton } from "../tenant-skeleton";
import useStyles from './styles';

const AuditLogFilter = (props) => {
  const { handleFilter } = props;
  const classes = useStyles();

  return (
    <FormControl>
      <Select
        defaultValue={DESCENDING}
        className={classes.filter}
        disableUnderline
        MenuProps={{
          anchorOrigin: {
            vertical  : "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical  : "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        onChange={handleFilter}
      >
        <MenuItem 
          value={DESCENDING}
          classes={{
            selected: classes.selectedItem,
          }}
        >
          <Trans i18nKey={'tenant-page.newestActivity'}/>
        </MenuItem>
        <MenuItem 
          value={ASCENDING}
          classes={{
            selected: classes.selectedItem,
          }}
        >
        <Trans i18nKey={'tenant-page.oldestActivity'}/>
        </MenuItem>
      </Select>
    </FormControl>
  )
}

const AuditLogItem = (props) => {
  const classes = useStyles();
  const { auditLogDetails } = props;

  const getText = (operation) => {
    if (operation === AUDIT_LOG_OPERATION.ADDED) {
      return 'tenant-page.auditLogAddedAdmin';
    } else if (operation === AUDIT_LOG_OPERATION.REMOVED) {
      return 'tenant-page.auditLogRemovedAdmin';
    } else if (operation === AUDIT_LOG_OPERATION.CREATED) {
      return 'tenant-page.auditLogCreatedTenancy';
    } else if (operation === AUDIT_LOG_OPERATION.DOWNLOADED) {
      return 'tenant-page.auditLogDownloadedInfo';
    } else {
      return 'tenant-page.auditLogUpdatedInfo';
    }
  }

  return (
    <Grid>
      {
        auditLogDetails.map((log, index) => {
          const isUpdated = log.operation === AUDIT_LOG_OPERATION.UPDATED;
          return ( 
            <div key={`audit-log-${index}`}>
              <Typography component={'span'} className={isUpdated ? classes.logUpdateText : classes.logText}>
                <Trans
                  i18nKey={getText(log.operation)}
                  values={{
                    user: log.user,
                    admin: log.newValue,
                    field: log.field,
                    date: log.date,
                    time: log.time
                  }}
                />
              </Typography>
              {
                isUpdated ?
                  <Typography component={'span'} className={classes.logValuesText}>
                    <b>{log.prevValue} &nbsp;</b>
                    <FontAwesomeIcon icon={faArrowRight} size="xs"/>
                    <b>&nbsp; {log.newValue}</b>
                  </Typography>
                :
                  <></>
              }
            </div>
          );
        })
      }
    </Grid>
  )
}

const Content = (props) => {
  const { order, hasMore, auditLogDetails, getAuditLogs, handleFilter } = props;
  const classes = useStyles();

  const ref = useRef(null);
  const scrollToTop = () => {
    ref.current.scroll({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    scrollToTop();
  }, [order]);

  return (
    <Paper elevation={4} className={classes.paper}>
      <Box id="auditLogsContainer" className={classes.form} ref={ref}>
        {
          auditLogDetails.length ?
            <Grid container className={classes.auditLogs}>
              <Grid item xs wrap="nowrap">
                <InfiniteScroll
                  dataLength={auditLogDetails.length}
                  next={getAuditLogs}
                  hasMore={hasMore}
                  scrollableTarget={"auditLogsContainer"}
                  scrollThreshold={0.85}
                  className={classes.logs}
                >
                  <AuditLogItem
                    auditLogDetails={auditLogDetails}
                  />
                </InfiniteScroll>
              </Grid>
              <Grid item className={classes.selected}>
                <AuditLogFilter
                  handleFilter={handleFilter}
                />
              </Grid>
            </Grid>
          :
            <Box id="auditLogEmptyContainer" className={classes.emptyContainer}>
              <Empty module={TENANT_LOGS} />
            </Box>
        }
      </Box>
    </Paper>
  )
}

const AuditLogComponent = (props) => {
  const { id, showToaster, setIsCreated } = props;

  const currentLanguageCode = cookies.get('i18next') || 'en';
  moment.locale(currentLanguageCode);
  
  const { t } = useTranslation();

  const [ order, setOrder ]                     = useState(DESCENDING);
  const [ hasMore, setHasMore ]                 = useState(false);
  const [ pageNumber, setPageNumber ]           = useState(0);
  const [ auditLogDetails, setAuditLogDetails ] = useState([]);
  const [isLoading, setIsLoading]               = useState(true);

  const page = useRef(0);

  const handleFilter = (event) => {
    setOrder(event.target.value);
    setPageNumber(0);
    page.current = 0;
  };

  const handleValue = useCallback((value, field) => {
    if (field === TENANT_FIELD.STATUS) {
      return value === 'true' ? t('tenant-page.active') : t('tenant-page.deactivated');
    } else {
      return value;
    }
  }, [t]);

  const translateField = useCallback((field) => {
    switch (field) {
      case TENANT_FIELD.TENANT_NAME:
        return t('tenant-page.tenantName');
      case TENANT_FIELD.DESCRIPTION:
        return t('tenant-page.description');
      case TENANT_FIELD.HOST:
        return t('tenant-page.host');
      case TENANT_FIELD.STATUS:
        return t('status');
      case TENANT_FIELD.FIRST_NAME:
        return t('tenant-page.firstName');
      case TENANT_FIELD.LAST_NAME:
        return t('tenant-page.lastName');
      case TENANT_FIELD.EMAIL:
        return t('tenant-page.emailAddress');
      case TENANT_FIELD.ACCOUNT_MANAGER:
        return t('tenant-page.accountManager');
      case TENANT_FIELD.PLAN:
        return t('tenant-page.billingPlan');
      default:
        break;
    }
  }, [t]);

  const processedUserId = useCallback((userId) => {
    return userId.indexOf(' ') > 0 ? userId : `${t('tenant-page.removedUser', { id: userId })}`;
  }, [t]);
  
  const getFormattedAuditLogs = useCallback(async (item) => {
    const userId = processedUserId(item.userId);
    let newValue = item.newValue;

    if (item.field === SYSTEM_ROLES.ACCOUNT_MANAGER) {
      newValue = processedUserId(item.newValue);
    }
    
    return {
      user      : userId,
      prevValue : handleValue(item.previousValue, item.field),
      newValue  : handleValue(newValue, item.field),
      operation : item.operation,
      field     : translateField(item.field),
      date      : moment(item.timeStamp).format(DATE_FORMAT),
      time      : moment(item.timeStamp).format(TIME_FORMAT)
    }
  }, [handleValue, translateField, processedUserId]);
  
  const formattedAuditLogs = useCallback(async (items) => {
    return await Promise.all(
      items.map(async (item) => {
        return await getFormattedAuditLogs(item);
      })
    );
  }, [getFormattedAuditLogs]);

  const getAuditLogs = useCallback(async () => {
    try {
      const response = await getAuditLogsByTenantId(id, order, page.current);
      
      const { totalElements, totalPages } = response.data.page;
      const { auditLogs }                 = response.data._embedded;

      const newLogs = await formattedAuditLogs(auditLogs);

      setAuditLogDetails(prevLogs => page.current === 0 ? newLogs : prevLogs.concat(newLogs));
      setHasMore(totalElements > 0 && page.current < totalPages - 1);
      setPageNumber(prevPage => prevPage + 1);
    } catch {
      showToaster(<Trans i18nKey={'tenant-page.error'}/>, <Trans i18nKey={'tenant-page.apiRequestFailed'}/>, TOASTER_SEVERITY.ERROR);
    } finally {
      setIsLoading(false);
    }
  }, [id, order, showToaster, formattedAuditLogs]);  

  useEffect(() => {
    setIsCreated(false);
  }, [setIsCreated]);

  useEffect(() => {
    getAuditLogs();
  }, [order, getAuditLogs]);

  useEffect(() => {
    page.current = pageNumber;
  }, [pageNumber]);

  return (
    <>
      {
        isLoading ? 
          <AuditLogSkeleton /> 
        :
          <Content
            order={order}
            hasMore={hasMore}
            auditLogDetails={auditLogDetails}
            getAuditLogs={getAuditLogs}
            handleFilter={handleFilter}
          />
      }
    </>
  )
}

export default AuditLogComponent;