import React from 'react';
import { Box, Button, Paper, Dialog, Typography } from '@material-ui/core';
import images from "../../utility/images";
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const UnsavedModal = (props) => {
  const { handleModalSubmit, handleModalCancel, withRedirect } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
    <Dialog
      id="unsavedModal"
      modal="false"
      {...props}
      aria-labelledby="row-unsaved-dialog"
      aria-describedby="row-unsaved-dialog-table"
      className={classes.dialog}
    >
      <Paper id="unsavedModalContainer" className={classes.dialogModal}>
        <Box className={classes.dialogImage}>
          <img src={images.UNSAVED_CHANGES_SVG} alt='unsave-dialog-img' className={classes.image}/>
        </Box>
        <Box className={classes.dialogTitle}>
          <Typography className={classes.title}>{t(`unsaved-modal-component.title`)}</Typography>
        </Box>
        <Box className={classes.dialogContent}>
          {
            withRedirect ?
            <Typography>{t(`unsaved-modal-component.description`)}</Typography> :
            <Typography>{t(`unsaved-modal-component.youWantToProceed`)}</Typography>
          }
        </Box>
      </Paper>
      <Box id="unsavedModalButtonsContainer" className={classes.dialogActions}>
        <Button id="unsavedModalSubmitButton" color="primary" variant="outlined" className={classes.dialogSubmitButton} onClick={handleModalSubmit} >
          {
            withRedirect ? t(`unsaved-modal-component.yesLeaveButton`) : t(`unsaved-modal-component.yesProceed`)
          }
        </Button>
        <Button id="unsavedModalCancelButton" color="primary" variant="contained" className={classes.dialogCancelButton} onClick={handleModalCancel} >
          {
            withRedirect ? t(`unsaved-modal-component.cancelButton`) : t(`unsaved-modal-component.noContinueWorking`)
          }
        </Button>
      </Box>
    </Dialog>
    </>
  );
}

UnsavedModal.defaultProps = {
  withRedirect: true
}

export default UnsavedModal;