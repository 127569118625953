import { Container } from "@material-ui/core";
import cookies from "js-cookie";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Table, { createColumn } from "../../components/table";
import Title from "../../components/title";
import { AuthContext } from "../../context/authContext";
import api from "../../service/api";
import { request } from "../../service/requests";
import { getUserById } from "../../service/user-api";
import { ALL, API_REQUEST_ERROR_MESSAGE, ASCENDING, GET, PATCH, STATUS, SYSTEM_ROLES, TENANTS, TENANT_ACTION, TOASTER_SEVERITY } from "../../utility/constants";
import { parseIntegerParam } from "../../utility/helper";
import StatusModal from "../tenant/tenant-status-modal";
import useStyles from "./styles";

const columns = [
  createColumn("name", "name", true, "string", false, true),
  createColumn("id", "ID", false, "numeric", true),
  createColumn("description", "description", false, "string", false, true),
  createColumn("status", "status", false, "component"),
  createColumn("accountManager", "accountManager", false, "component"),
  createColumn("deploymentStatus", "deploymentStatus", false, "string", true)
];

const Tenants = (props) => {
  const classes              = useStyles();
  const history              = useHistory();
  const { showToaster }      = props;
  const { search, pathname } = history.location;

  const { state } = useContext(AuthContext);
  const { user }  = state;
  const { roles } = user;

  const { t } = useTranslation();

  const params      = useMemo(() => { return new URLSearchParams(search) }, [search]);
  const query       = params.get('keyword') ? params.get('keyword') : '';
  const pageSize    = params.get('size') ? parseIntegerParam(params.get('size'), 10) : 10;
  const pageNumber  = params.get('page') ? parseIntegerParam(params.get('page'), 1) : 1;
  const pageType    = params.get('tenants') ? params.get('tenants') : ALL.toLowerCase();
  const totalSize   = pageSize > 100 ? 100 : pageSize;

  const language = cookies.get('i18next');

  const [isLoading, setIsLoading]             = useState(false);
  const [keyword, setKeyword]                 = useState(query);
  const [listType, setListType]               = useState(pageType);
  const [rowsPerPage, setRowsPerPage]         = useState(totalSize);
  const [page, setPage]                       = useState(pageNumber);
  const [totalTenants, setTotalTenants]       = useState(0);
  const [order, setOrder]                     = useState(ASCENDING);
  const [orderBy, setOrderBy]                 = useState(columns[0].name);
  const [tenants, setTenants]                 = useState([]);
  const [showModal, setShowModal]             = useState(false);
  const [tenantName, setTenantName]           = useState('');
  const [status, setStatus]                   = useState('');
  const [tenantId, setTenantId]               = useState(0);

  const isExternalAccountManageer = roles.name === SYSTEM_ROLES.EXTERNAL_ACCOUNT_MANAGER;

  const getFormattedTenants = useCallback(async (item) => {
    const { name, description, status, userIds, deploymentStatus, _links } = item;
    const isStatusActive = status === true ? STATUS.ACTIVE : STATUS.DEACTIVATED;
    const id = _links.self.href.replace(`${api.TENANTS}/`, '')

    const accountManagementUser = await Promise.all(
      userIds.map(async (userId) => {
        const user = await getUserById(userId);

        if (user === null) {
          return {
            name : ""
          };
        }

        return {
          name : `${user.firstName} ${user.lastName}`
        }
      })
    );
    
    return {
      name             : name,
      id               : id,
      description      : description,
      status           : isStatusActive,
      accountManager   : accountManagementUser,
      deploymentStatus : deploymentStatus
    }
  }, []);
  
  const formattedTenants = useCallback(async (items) => {
    return await Promise.all(
      items.map(async (item) => {
        return await getFormattedTenants(item);
      })
    );
  }, [getFormattedTenants]);

  const getUrl = useCallback(() => {
    if (isExternalAccountManageer) {
      return api.TENANTS_BY_USER_ID;
    }

    return keyword === '' ? api.TENANTS : api.TENANTS_SEARCH;
  }, [keyword, isExternalAccountManageer]);

  const getParams = useCallback(() => {
    if (isExternalAccountManageer) {
      return {
        userId     : user.userId,
        name       : keyword,
        size       : rowsPerPage,
        page       : page - 1,
        sort       : `name,${order}`
      }
    }

    return {
      name       : keyword,
      size       : rowsPerPage,
      page       : page - 1,
      sort       : `name,${order}`
    }
  }, [user, keyword, order, page, rowsPerPage, isExternalAccountManageer]);
  
  const setTenantsResponse = useCallback(async (response) => {
    const { data } = response;
    const { number, size, totalElements, totalPages } = data.page;

    const totalNumber = number > totalPages ? totalPages : number;

    const tenants = await formattedTenants(data._embedded.tenants);

    if (tenants.length || number === 0) {
      setPage(totalNumber + 1);
      setTenants(tenants);
    } else {
      setPage(totalNumber);
    }

    setRowsPerPage(size);
    setTotalTenants(totalElements);
  }, [formattedTenants]);

  const getTenants = useCallback(async () => {
    setIsLoading(true);

    const title       = t('tenant-page.error');
    const description = t(API_REQUEST_ERROR_MESSAGE);
    const severity    = TOASTER_SEVERITY.ERROR;

    try {
      const tenantsResponse = await request({
        url    : getUrl(),
        method : GET,
        params : getParams()
      });

      await setTenantsResponse(tenantsResponse);
    } catch (error) {
      showToaster(title, description, severity);
    } finally {
      setIsLoading(false);
    }
  }, [getParams, getUrl, setTenantsResponse, showToaster, t]);

  const getData = useCallback(() => {
    if (user.roles.name && listType === ALL.toLowerCase()){
      getTenants();
    } else {
      setListType(ALL.toLowerCase());
    }
  }, [getTenants, listType, user]);

  useEffect(() => {
    let delayDebounce;

    params.set('tenants', listType);
    params.set('page', page);
    params.set('size', rowsPerPage);

    if (keyword) {
      params.set('keyword', keyword);
      
      delayDebounce = setTimeout(() => {
        getData();
      }, 1000);
    } else {
      params.delete('keyword');
      getData();
    }

    const location = {
      pathname: pathname,
      search  : params.toString()
    };

    history.replace(location);

    return () => {
      delayDebounce && clearTimeout(delayDebounce);
    }

  }, [page, rowsPerPage, order, orderBy, keyword, listType, getData, history, params, pathname]);
  
  const handleClearSearch = () => {
    setPage(1);
    setKeyword('');
  }

  const handleChangePage = (newPage) => {
    if (isLoading) {
      return;
    }

    setPage(newPage + 1);
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setPage(1);
    setRowsPerPage(newRowsPerPage);
  }

  const handleSearch = (value) => {
    setPage(1);
    setKeyword(value);
  }

  const handleSort = (newOrderBy, newOrder) => {
    setPage(1);
    setOrderBy(newOrderBy);
    setOrder(newOrder);
  }

  const handleCreate = () => {
    history.push("/tenants/create");
  }

  const handleView = (row) => {
    history.push(`/tenants/view/${row.id}`);
  }

  const handleOpenStatusModal = (tenantName, status, id) => {
    setTenantName(tenantName);
    setStatus(status);
    setTenantId(id);
    setShowModal(true);
  }

  const handleCloseStatusModal = () => {
    setShowModal(false);
  }

  const handleSubmitResponse = async () => {
    const newStatus     = status === STATUS.ACTIVE ? STATUS.DEACTIVATED : STATUS.ACTIVE;
    const booleanStatus = status !== STATUS.ACTIVE;

    const action = booleanStatus ? TENANT_ACTION.ACTIVATE : TENANT_ACTION.DEACTIVATE;
    
    setShowModal(false);
    setIsLoading(true);
    
    let title       = t('tenant-page.error');
    let description = t(API_REQUEST_ERROR_MESSAGE);
    let severity    = TOASTER_SEVERITY.ERROR;

    try {
      const newTenant = await request({
        url    : `${api.TENANTS}/${tenantId}`,
        method : PATCH,
        data   : {
          status : booleanStatus
        },
        headers : {
          "Content-Language" : language,
        }
      });
      
      const { data } = newTenant;
      
      const tenantToBeUpdated = tenants.find(tenant => tenant.id === data.tenantId);

      const updatedTenants = tenants.map(tenant => {
        if (tenant.id === tenantToBeUpdated.id) {
          return {
            ...tenant,
            status : newStatus
          }
        }
        
        return {
          ...tenant
        }
      });

      title       = t('tenant-page.success');
      description = getStatusMessage(newStatus, tenantName);
      severity    = TOASTER_SEVERITY.SUCCESS;
      setTenants(updatedTenants);

      showToaster(title, description, severity);
    } catch (error) {
      if (error.response.status === 404) {
        description = t('tenant-page.actionError', { action });
      }

      showToaster(title, description, severity);
    } finally {
      setIsLoading(false);
    }
  }

  const getStatusMessage = (newStatus, tenantName) => {
    const toasterMessage = newStatus === STATUS.ACTIVE ? 'status-modal.activatedMessage' : 'status-modal.deactivatedMessage';
    return t(toasterMessage, { item : tenantName });
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Title title={<Trans i18nKey={'title-component.tenants'}/>}/>
      <StatusModal
        onClose={handleCloseStatusModal}
        open={showModal}
        tenantName={tenantName}
        handleResponse={handleSubmitResponse}
        status={status}
      />
      <Table
        columns={columns}
        data={tenants}
        isLoading={isLoading}
        keyword={keyword}
        label={"tenant"}
        module={TENANTS}
        onChangePage={handleChangePage}
        onClearSearch={handleClearSearch}
        onRowsPerPageChange={handleRowsPerPageChange}
        onSearch={handleSearch}
        onCreate={handleCreate}
        onSort={handleSort}
        onView={handleView}
        onSwitch={handleOpenStatusModal}
        orderBy={orderBy}
        order={order}
        page={page}
        rowsPerPage={rowsPerPage}
        totalItems={totalTenants}
        viewKey={"name"}
        listType={listType}
      />
    </Container>
  )
}

export default Tenants;