import React, { memo } from "react";
import { Typography } from "@material-ui/core";
import useStyles from "./styles";
import images from "../../utility/images";
import { useTranslation } from 'react-i18next';
import { TENANTS, TENANT_LOGS, USERS } from "../../utility/constants";

const Empty = (props) => {
  const classes = useStyles();
  const { t }   = useTranslation();
  const { keyword, module } = props;

  const getEmptyLabel = (module) => {
    if (keyword) {
      return t('empty-component.noMatchFound');
    }

    switch (module) {
      case USERS: 
        return t('empty-component.noUsersAdded');
      case TENANTS:
        return t('empty-component.noTenantsAdded');
      case TENANT_LOGS:
        return t('empty-component.noLogsToDisplay');
      default:
        return t('empty-component.currentlyEmpty');
    }
  }

  return (
    <>
      <img src={images.NOTHING_TO_DISPLAY} className={classes.emptyDataImg} alt='empty-chip-container'/>
      <Typography className={classes.emptyTitle}>{getEmptyLabel(module)}</Typography>
    </>
  )    
}

export default memo(Empty);